import { memo, useCallback } from 'react';

import { IUseCase } from '../../../interfaces/models/IUseCase';
import RemoveModalContent from './RemoveModalContent';
import { useDeleteUseCaseMutation } from '../../../api/useCase';

const RemoveUseCase = ({ useCase }: { useCase: IUseCase }) => {
  const [deleteUseCase, { isLoading: isDeleting }] = useDeleteUseCaseMutation();

  const handleRemove = useCallback(() => deleteUseCase(useCase).unwrap(), [useCase?.gbgUseCaseID]);

  return (
    <RemoveModalContent name={useCase?.gbgUseCaseText ?? ''} handleRemove={handleRemove} isDeleting={isDeleting} />
  );
};

export default memo(RemoveUseCase);
