import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ModalContentType } from '../../features/modal/config';
import { useAppDispatch } from '../../app/hooks';
import { closeModal, openModal } from '../../features/modal/modalSlice';
import { useGetDataSourceQuery } from '../../api/dataSource';
import Breadcrumb from '../../components/layout/Breadcrumb';
import DataSourceForm from './DataSourceForm';

export const DataSourceDetailPage = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { data, isFetching, isError, error } = useGetDataSourceQuery(id);

  useEffect(() => {
    if (!isError) dispatch(closeModal());
    else dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
  }, [isError, error]);

  return (
    <>
      {!isFetching && data && <Breadcrumb params={{ [id]: data.gbgName }} />}
      <h1>{data?.gbgName}</h1>
      {isFetching ? (
        <div className="text-center p-5">
          <GBG.Spinner data-testid="spinner" />
        </div>
      ) : (
        <DataSourceForm dataSource={data} isCreateMode={false} />
      )}
    </>
  );
};
export default memo(DataSourceDetailPage);
