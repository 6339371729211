import { memo, useMemo } from 'react';
import GBG from '@gbg/gbgcomponentlibrary_react';
import { useParams } from 'react-router-dom';

import { useGetOrganisationQuery } from '../../../api/organisations';
import { useGetDepartmentsQuery } from '../../../api/departments';
import Breadcrumb from '../../../components/layout/Breadcrumb';
import UserForm from './UserForm';

const NewUser: React.FC = () => {
  const { orgId } = useParams<{ orgId: string }>();
  const { data: organisation, isFetching } = useGetOrganisationQuery(orgId);
  const { data: departments, isFetching: isDeptFetching } = useGetDepartmentsQuery(orgId);

  const departmentOptions = useMemo<ISelectOption[]>(
    () => departments?.map(item => ({ label: item.gbgName, value: item.gbgDeptId })) ?? [],
    [departments],
  );

  return (
    <>
      {!isFetching && organisation && <Breadcrumb params={{ [orgId]: organisation.gbgName }} />}
      {isDeptFetching ? (
        <div className="text-center p-5">
          <GBG.Spinner data-testid="spinner" />
        </div>
      ) : (
        <div className="m-m-t-2">
          <UserForm orgId={orgId} isNew={true} departmentOptions={departmentOptions} />
        </div>
      )}
    </>
  );
};

export default memo(NewUser);
