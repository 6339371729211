import React from 'react';
import GBG from '@gbg/gbgcomponentlibrary_react';
import { useHistory, useLocation } from 'react-router';
import { FormattedMessage } from 'react-intl';

const ErrorPage: React.FC = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const errorCode = pathname.substr(pathname.lastIndexOf('/') + 1);

  return (
    <React.Fragment>
      <FormattedMessage id="page.not.found.title" tagName="h1" />
      <FormattedMessage id={`page.not.found.${errorCode}.description`} tagName="h3" />
      <GBG.Label inline={true}>
        <FormattedMessage id="page.not.found.errorCode" />
        {errorCode}
      </GBG.Label>
      <GBG.Button
        kind={GBG.ButtonKind.Primary}
        className="m-m-t-2"
        onClick={() => {
          history.push('/');
        }}
      >
        <FormattedMessage id="label.return" />
      </GBG.Button>
      <div className="m-m-t-3">
        <FormattedMessage id="label.get" />
        <a href="#">
          <FormattedMessage id="label.help" />
        </a>
      </div>
    </React.Fragment>
  );
};

export default ErrorPage;
