import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { ModalContentType, ModalState } from './config';

const initialState: ModalState = {
  isOpen: false,
  currentContentType: ModalContentType.EMPTY,
  data: undefined,
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (
      state,
      action: PayloadAction<{
        type: ModalContentType;
        data?: any;
      }>,
    ) => {
      state.currentContentType = action.payload.type;
      state.data = action.payload.data;
      state.isOpen = true;
    },
    closeModal: state => {
      state.isOpen = false;
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export const selectModal = (state: RootState): ModalState => state.modal;

export const selectModalIsOpen = (state: RootState): boolean => selectModal(state).isOpen;
export const selectModalCurrentContentType = (state: RootState): ModalContentType =>
  selectModal(state).currentContentType;
export const selectModalData = (state: RootState): any => selectModal(state).data;

export default modalSlice.reducer;
