import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetDepartmentQuery } from '../../../../api/departments';
import { useGetOrganisationQuery } from '../../../../api/organisations';
import { useGetRoleQuery } from '../../../../api/roles';
import { useAppDispatch } from '../../../../app/hooks';
import Breadcrumb from '../../../../components/layout/Breadcrumb';
import { ModalContentType } from '../../../../features/modal/config';
import { closeModal, openModal } from '../../../../features/modal/modalSlice';
import RolesFeatures from './RolesFeatures';
import RolesUseCases from './RolesUseCases';

export const RoleDetailsPage = () => {
  const dispatch = useAppDispatch();
  const { orgId, departId, roleId } = useParams<{ orgId: string; departId: string; roleId: string }>();
  const baseUrl = `/organisations/${orgId}/departments/${departId}/roles/${roleId}`;
  const {
    data: organisation,
    isError: isErrorOrg,
    error: errorOrg,
    isFetching: isFetchingOrg,
  } = useGetOrganisationQuery(orgId);

  const {
    data: department,
    isError: isErrorDep,
    error: errorDep,
    isFetching: isFetchingDep,
  } = useGetDepartmentQuery({ orgId, departId });

  const {
    data: departmentRole,
    isError: isErrorDepRole,
    error: errorDepRole,
    isFetching: isFetchingDepRole,
  } = useGetRoleQuery({ orgId, roleId });

  useEffect(() => {
    if (!isErrorOrg && !isErrorDep && !isErrorDepRole) dispatch(closeModal());
    else dispatch(openModal({ type: ModalContentType.API_ERROR, data: errorOrg || errorDep || errorDepRole }));
  }, [isErrorDep, isErrorOrg, isErrorDepRole, errorDep, errorOrg, errorDepRole, dispatch]);

  return isFetchingOrg || isFetchingDep || isFetchingDepRole || !organisation || !department || !departmentRole ? (
    <div className="text-center p-5">
      <GBG.Spinner data-testid="spinner" />
    </div>
  ) : (
    <>
      <Breadcrumb
        params={{ [orgId]: organisation.gbgName, [departId]: department.gbgName, [roleId]: departmentRole.gbgName }}
      />
      <h1>{departmentRole.gbgName}</h1>
      <div className="m-m-t-2">
        <RolesFeatures role={departmentRole} addUrl={`${baseUrl}/add-permission`} />
      </div>
      <div className="m-m-t-2">
        <RolesUseCases departId={departId} orgId={orgId} roleId={roleId} baseCreateEditPageURL={baseUrl} />
      </div>
    </>
  );
};
export default memo(RoleDetailsPage);
