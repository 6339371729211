import { memo } from 'react';

import Breadcrumb from '../../components/layout/Breadcrumb';
import ApplicationForm from './ApplicationForm';

const NewApplicationPage: React.FC = () => {
  return (
    <>
      <Breadcrumb />
      <ApplicationForm />
    </>
  );
};

export default memo(NewApplicationPage);
