import GBG from '@gbg/gbgcomponentlibrary_react';
import { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PillType } from '@gbg/gbgcomponentlibrary_react/build/Atoms/Pills/Pill.types';

export const useTableDataset = (headerFields: string[], isUninitialized: boolean) => {
  const intl = useIntl();

  const pillType = useCallback((type: string): PillType => {
    switch (type?.toLowerCase()) {
      case 'suspended':
        return GBG.PillType.Error;
      case 'expired':
        return GBG.PillType.Warn;
      case 'trial':
        return GBG.PillType.Light;
      case 'internal':
        return GBG.PillType.Dark;
      default:
        return GBG.PillType.Success;
    }
  }, []);

  const cellContent = useCallback((key: string, item: any): JSX.Element => {
    if (key.endsWith('Status')) {
      return (
        <GBG.Pill outlined={true} type={pillType(item[key])} shape={GBG.PillShape.Square}>
          <FormattedMessage id={`status.${item[key]?.toLowerCase() ?? 'active'}`} />
        </GBG.Pill>
      );
    }

    if (key === 'enabled') {
      return (
        <GBG.Pill
          outlined={true}
          type={item[key] ? GBG.PillType.Success : GBG.PillType.Warn}
          shape={GBG.PillShape.Square}
        >
          <FormattedMessage id={item[key] ? 'status.active' : 'status.inactive'} />
        </GBG.Pill>
      );
    }

    if (key === 'fullName') {
      const user: IUser = item;
      return (
        <>
          {`${user.userGivenName ?? ''} ${user.userFamilyName ?? ''}`}
          <br />
          {user.username ?? ''}
        </>
      );
    }
    if (key === 'lastSignOn.at') {
      const user: IUser = item;
      return <>{user.lastSignOn?.at ? new Date(user.lastSignOn.at).toLocaleDateString() : ''}</>;
    }

    return item[key];
  }, []);

  const emptyStateContent: JSX.Element = useMemo(
    () => (
      <p className="text-center p-5">
        <FormattedMessage id={isUninitialized ? 'table.first-search' : 'table.no-rows'} />
        <br />
      </p>
    ),
    [isUninitialized],
  );

  const headerContent = useMemo(
    () =>
      new Map<string, string>(headerFields.map(field => [field, intl.formatMessage({ id: `table.header.${field}` })])),
    [],
  );

  return { emptyStateContent, headerContent, cellContent };
};
