import { memo, useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { ModalContentType } from '../../../features/modal/config';
import { useAppDispatch } from '../../../app/hooks';
import { useGetApplicationQuery } from '../../../api/applications';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import Breadcrumb from '../../../components/layout/Breadcrumb';
import SwitchInput from '../../../components/forms/fields/SwitchInput';
import FunctionForm from './FunctionForm';
import FunctionImportForm from './FunctionImportForm';
import PermissionGate from '../../../auth/PermissionGate';
import { userRoleTypes, SCOPES } from '../../../auth/permissions';

const NewFunctionPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { appId } = useParams<{ appId: string }>();
  const { data, isFetching, isError, error } = useGetApplicationQuery(appId);

  const { register, setValue, control } = useForm();
  const gbgImportExistingFunction: boolean = useWatch({ control, name: 'gbgImportExistingFunction' });
  const permissions = { [userRoleTypes.Application_Admin]: SCOPES.FullAccess };

  useEffect(() => {
    if (!isError) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
    }
  }, [isError, error, closeModal, openModal]);

  return (
    <>
      {!isFetching && data && <Breadcrumb params={{ [appId]: data.gbgName }} />}
      <PermissionGate allowedPermissions={permissions}>
        <SwitchInput id="function.existing-import" name="gbgImportExistingFunction" {...{ register, setValue }} />
      </PermissionGate>
      {!gbgImportExistingFunction ? (
        <FunctionForm appId={appId} permissions={permissions} />
      ) : (
        <FunctionImportForm appId={appId} />
      )}
    </>
  );
};

export default memo(NewFunctionPage);
