import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import { FUNCTION_FORM_FIELDS, TOAST_SUCCESS } from '../../../constants';
import { ModalContentType } from '../../../features/modal/config';
import { useAppDispatch } from '../../../app/hooks';
import { openModal } from '../../../features/modal/modalSlice';
import { useAddFunctionMutation, useUpdateFunctionMutation } from '../../../api/functions';
import Form from '../../../components/forms/Form';

interface IFunctionForm {
  appId: string;
  funcData?: IFunction;
  isNew?: boolean;
  permissions: { [x: string]: string };
}

const FunctionForm: React.FC<IFunctionForm> = ({ appId, funcData, isNew = true, permissions }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [addFunction, { isLoading: isAdding }] = useAddFunctionMutation();
  const [updateFunction, { isLoading: isUpdating }] = useUpdateFunctionMutation();

  const fields = useMemo<IFormField[]>(() => {
    if (isNew) return FUNCTION_FORM_FIELDS;
    const formFields = _.cloneDeep(FUNCTION_FORM_FIELDS);
    formFields[0].disabled = true;
    return formFields;
  }, [isNew]);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = useCallback(
    handleSubmit(async (data: Partial<IFunction>) => {
      try {
        const editFunction = isNew ? addFunction : updateFunction;
        const res = await editFunction({
          ...funcData,
          ...data,
          gbgApplicationId: isNew ? [appId] : funcData?.gbgApplicationId,
        } as IFunction).unwrap();
        GBG.toast(TOAST_SUCCESS);
        history.push(`/applications/${appId}/functions/${res.gbgFunctionID}`);
      } catch (err) {
        dispatch(openModal({ type: ModalContentType.API_ERROR, data: err }));
      }
    }),
    [handleSubmit, appId, funcData, funcData?.gbgApplicationId, addFunction, updateFunction, openModal],
  );

  return (
    <>
      {isNew && <FormattedMessage id="function.new-title" tagName="h1" />}

      <Form
        {...{
          onSubmit,
          fields,
          data: funcData,
          register,
          errors,
          setValue,
          isBusy: isAdding || isUpdating,
          isNew,
          permissions,
        }}
      />
    </>
  );
};

export default memo(FunctionForm);
