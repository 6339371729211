import { adminApi } from '../app/api';
import { IDatasource } from '../interfaces/models/IDatasource';
import { IImportUseCase, IUseCase } from '../interfaces/models/IUseCase';

const endpoint = '/api/v1/UseCase';
const orgEndpoint = '/api/v1/organisations';
const apiUseCasesListEndpoint = (organisationId: string) => `${orgEndpoint}/${organisationId}/use-cases`;
const apiUseCaseEndpoint = (organisationId: string, useCaseId: string) =>
  `${apiUseCasesListEndpoint(organisationId)}/${useCaseId}`;

const useCaseService = adminApi.injectEndpoints({
  endpoints: build => ({
    getUseCaseList: build.query<IPaginatedRes<IUseCase>, { paginatedRequest: IPaginatedReq; organisationId: string }>({
      query: (request: { paginatedRequest: IPaginatedReq; organisationId: string }) => ({
        url: `${apiUseCasesListEndpoint(request.organisationId)}?gbgUseCaseText=${
          request.paginatedRequest.search ?? ''
        }&cursor=${request.paginatedRequest.cursor ?? ''}&limit=${request.paginatedRequest.limit ?? ''}`,
        method: 'GET',
      }),
      providesTags: result =>
        result
          ? [
              ...result.embedded.entries.map(item => ({ type: 'UseCases', id: item.gbgUseCaseID } as const)),
              { type: 'UseCases', id: 'LIST' },
            ]
          : [{ type: 'UseCases', id: 'LIST' }],
    }),
    getUseCase: build.query<IUseCase, { id: string; organisationId: string }>({
      query: (request: { id: string; organisationId: string }) => ({
        url: apiUseCaseEndpoint(request.organisationId, request.id),
        method: 'GET',
      }),
      providesTags: result => (result ? [{ type: 'UseCases', id: result?.gbgUseCaseID }] : []),
    }),
    getUseCaseDataSources: build.query<IPaginatedRes<IDatasource>, { id: string; organisationId: string }>({
      query: (request: { id: string; organisationId: string }) => ({
        url: apiUseCaseEndpoint(request.organisationId, request.id) + '/data-sources',
        method: 'GET',
      }),
      providesTags: result =>
        result
          ? [
              ...result.embedded.entries.map(item => ({ type: 'DataSources', id: item.gbgDataSourceID[0] } as const)),
              { type: 'DataSources', id: 'LIST' },
            ]
          : [{ type: 'DataSources', id: 'LIST' }],
    }),
    getUseCaseText: build.query<ISelectOption[], void>({
      query: () => ({ url: `${endpoint}/texts`, method: 'GET' }),
      providesTags: result => (result ? [{ type: 'UseCasesText', id: 'LIST' }] : []),
    }),
    getAffectedItemsOnOrganisationUseCaseUpdate: build.query<
      IAffectedDetailDto,
      { orgId: string; useCaseText?: string; removedIDs: string }
    >({
      query: ({ orgId, useCaseText, removedIDs }) => ({
        url: `${orgEndpoint}/${orgId}/use-cases/${useCaseText}/check-role-use-cases?removedDataSourceIDs=${removedIDs}`,
        method: 'GET',
      }),
      providesTags: result => (result ? [{ type: 'UseCases', id: 'LIST' }] : []),
    }),
    getAffectedItemsOnDepartmentUseCaseUpdate: build.query<
      IAffectedDetailDto,
      { orgId: string; useCaseText?: string; removedIDs: string; departId: string }
    >({
      query: ({ orgId, useCaseText, removedIDs, departId }) => ({
        url: `${orgEndpoint}/${orgId}/departments/${departId}/use-cases/${useCaseText}/check-role-use-cases?removedDataSourceIDs=${removedIDs}`,
        method: 'GET',
      }),
      providesTags: result => (result ? [{ type: 'UseCases', id: 'LIST' }] : []),
    }),
    addUseCase: build.mutation<IUseCase, IUseCase>({
      query: data => ({ url: endpoint, method: 'POST', data }),
      invalidatesTags: result => (result ? [{ type: 'UseCases', id: 'LIST' }] : []),
    }),
    updateUseCase: build.mutation<IUseCase, IUseCase>({
      query: data => ({
        url: apiUseCaseEndpoint(data.gbgCompanyId as string, data.gbgUseCaseID as string),
        method: 'PUT',
        data,
      }),
      invalidatesTags: result =>
        result
          ? [
              { type: 'UseCases', id: result.gbgUseCaseID },
              { type: 'RoleUseCaseDataSources', id: 'LIST' },
              { type: 'DepartmentUseCaseDataSources', id: 'LIST' },
              { type: 'CompanyUseCaseDataSources', id: 'LIST' },
            ]
          : [],
    }),
    importUseCase: build.mutation<void, IImportUseCase>({
      query: data => ({ url: `${apiUseCasesListEndpoint(data.gbgOrganisationId)}/import`, method: 'POST', data }),
      invalidatesTags: () => [{ type: 'UseCases', id: 'LIST' }],
    }),
    deleteUseCase: build.mutation<string, IUseCase>({
      query: useCase => ({
        url: apiUseCaseEndpoint(useCase.gbgCompanyId as string, useCase.gbgUseCaseID as string),
        method: 'DELETE',
      }),
      invalidatesTags: () => [{ type: 'UseCases', id: 'LIST' }],
    }),
    getUseCasesForRole: build.query<
      IPaginatedRes<IUseCase>,
      { gbgCompanyId: string; gbgDepartmentId: string; gbgRoleId: string }
    >({
      query: ({ gbgCompanyId, gbgRoleId, gbgDepartmentId }) => ({
        url: `${orgEndpoint}/${gbgCompanyId}/departments/${gbgDepartmentId}/roles/${gbgRoleId}/use-cases`,
        method: 'GET',
      }),
      providesTags: (result: any) =>
        result
          ? [
              ...result.embedded.entries.map(
                (item: IUseCase) => ({ type: 'UseCases', id: item.gbgUseCaseID } as const),
              ),
              { type: 'UseCases', id: 'LIST' },
            ]
          : [{ type: 'UseCases', id: 'LIST' }],
    }),
    getAvailableUseCases: build.query<
      IPaginatedRes<IUseCase>,
      { gbgCompanyId: string; gbgDepartmentId: string; gbgRoleId: string }
    >({
      query: ({ gbgCompanyId, gbgRoleId, gbgDepartmentId }) => ({
        url: `${orgEndpoint}/${gbgCompanyId}/departments/${gbgDepartmentId}/roles/${gbgRoleId}/available-use-cases`,
        method: 'GET',
      }),
      providesTags: (result: any) =>
        result
          ? [
              ...result.embedded.entries.map(
                (item: IUseCase) => ({ type: 'UseCases', id: item.gbgUseCaseID } as const),
              ),
              { type: 'UseCases', id: 'LIST' },
            ]
          : [{ type: 'UseCases', id: 'LIST' }],
    }),
    getAvailableUseCasesForDepartment: build.query<
      IPaginatedRes<IUseCase>,
      { gbgCompanyId: string; gbgDepartmentId: string }
    >({
      query: ({ gbgCompanyId, gbgDepartmentId }) => ({
        url: `${orgEndpoint}/${gbgCompanyId}/departments/${gbgDepartmentId}/available-use-cases`,
        method: 'GET',
      }),
      providesTags: (result: any) =>
        result
          ? [
              ...result.embedded.entries.map(
                (item: IUseCase) => ({ type: 'UseCases', id: item.gbgUseCaseID } as const),
              ),
              { type: 'UseCases', id: 'LIST' },
            ]
          : [{ type: 'UseCases', id: 'LIST' }],
    }),
    getUseCasesForDepartment: build.query<
      IPaginatedRes<IUseCase>,
      { gbgCompanyId: string; gbgDepartmentId: string; request: IPaginatedReq }
    >({
      query: ({ gbgCompanyId, gbgDepartmentId, request }) => ({
        url: `${orgEndpoint}/${gbgCompanyId}/departments/${gbgDepartmentId}/use-cases?gbgUseCaseText=${
          request.search ?? ''
        }&cursor=${request.cursor ?? ''}&limit=${request.limit ?? ''}`,
        method: 'GET',
      }),
      providesTags: (result: any) =>
        result
          ? [
              ...result.embedded.entries.map(
                (item: IUseCase) => ({ type: 'UseCases', id: item.gbgUseCaseID } as const),
              ),
              { type: 'UseCases', id: 'LIST' },
            ]
          : [{ type: 'UseCases', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetUseCaseListQuery,
  useGetUseCaseTextQuery,
  useGetUseCaseQuery,
  useAddUseCaseMutation,
  useUpdateUseCaseMutation,
  useDeleteUseCaseMutation,
  useImportUseCaseMutation,
  useGetAvailableUseCasesQuery,
  useLazyGetAffectedItemsOnOrganisationUseCaseUpdateQuery,
  useLazyGetAffectedItemsOnDepartmentUseCaseUpdateQuery,
  useGetUseCasesForRoleQuery,
  useGetAvailableUseCasesForDepartmentQuery,
  useGetUseCasesForDepartmentQuery,
  useGetUseCaseDataSourcesQuery,
} = useCaseService;
