import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { AlertBarType } from '@gbg/gbgcomponentlibrary_react/build/Molecules/AlertBar/AlertBar.types';

import { ModalContentType } from '../../features/modal/config';
import { selectModalCurrentContentType, selectModalData, selectModalIsOpen } from '../../features/modal/modalSlice';
import { useAppSelector } from '../../app/hooks';
import RemoveOrganisation from './contents/RemoveOrganisation';
import RemoveApplication from './contents/RemoveApplication';
import RemoveFunction from './contents/RemoveFunction';
import RemoveDepartment from './contents/RemoveDepartment';
import RemoveLicence from './contents/RemoveLicence';
import RemoveUseCase from './contents/RemoveUseCase';
import RemoveDataSource from './contents/RemoveDataSource';
import RemoveUser from './contents/RemoveUser';
import RemoveDepartmentRole from './contents/RemoveDepartmentRole';
import RemoveRoleFeature from './contents/RemoveRoleFeature';
import ApiError from './contents/ApiError';
import RemoveUserRole from './contents/RemoveUserRole';
import RegenerateSecret from './contents/RegenerateSecret';
import PromptUpdateLicence from './contents/PromptUpdateLicence';

const Modal: React.FC = () => {
  const isOpen = useAppSelector(selectModalIsOpen);
  const currentContentType = useAppSelector(selectModalCurrentContentType);
  const data = useAppSelector(selectModalData, shallowEqual);

  const type = useMemo<AlertBarType>(() => {
    switch (currentContentType) {
      case ModalContentType.EMPTY:
      case ModalContentType.PROMPT_UPDATE_LICENCE:
        return GBG.AlertBarType.Info;
      case ModalContentType.REMOVE_ORGANISATION:
      case ModalContentType.REMOVE_APPLICATION:
      case ModalContentType.REMOVE_LICENCE:
      case ModalContentType.REMOVE_FUNCTION:
      case ModalContentType.REMOVE_DEPARTMENT:
      case ModalContentType.REMOVE_USER:
      case ModalContentType.REMOVE_USECASE:
      case ModalContentType.REMOVE_DATASOURCE:
      case ModalContentType.REMOVE_DEPARTMENTROLE:
      case ModalContentType.REMOVE_ROLEFEATURE:
      case ModalContentType.REMOVE_ROLEUSECASE:
      case ModalContentType.REMOVE_DEPARTMENTUSECASE:
      case ModalContentType.REMOVE_USERROLE:
      case ModalContentType.REGENERATE_SECRET:
        return GBG.AlertBarType.Warn;
      case ModalContentType.API_ERROR:
        return GBG.AlertBarType.Error;
    }
  }, [currentContentType]);

  const title = useMemo<string | undefined>(() => {
    switch (currentContentType) {
      case ModalContentType.EMPTY:
        return undefined;
      case ModalContentType.REMOVE_ORGANISATION:
        return `Are you sure you want to remove the "${data?.gbgName ?? ''}" organisation?`;
      case ModalContentType.REMOVE_APPLICATION:
        return `Are you sure you want to remove the "${data?.gbgName ?? ''}" application?`;
      case ModalContentType.REMOVE_LICENCE:
        return 'Are you sure you want to remove this licence?';
      case ModalContentType.REMOVE_FUNCTION:
        return `Are you sure you want to remove the "${data?.gbgName}" function?`;
      case ModalContentType.REMOVE_DATASOURCE:
        return `Are you sure you want to remove the "${data?.gbgName}" data source?`;
      case ModalContentType.REMOVE_DEPARTMENT:
        return `Are you sure you want to remove the "${data?.gbgName ?? ''}" department?`;
      case ModalContentType.REMOVE_USER:
        return `Are you sure you want to remove the "${data?.username ?? ''}" from your account?`;
      case ModalContentType.REMOVE_DEPARTMENTROLE:
        return `Are you sure you want to remove the "${data?.gbgName ?? ''}" role?`;
      case ModalContentType.REMOVE_ROLEFEATURE:
        return `Are you sure you want to remove the "${data?.functionName ?? ''}" function from this role?`;
      case ModalContentType.REMOVE_USECASE:
      case ModalContentType.REMOVE_DEPARTMENTUSECASE:
      case ModalContentType.REMOVE_ROLEUSECASE:
        return `Are you sure you want to remove the "${data.gbgUseCaseText}" use case?`;
      case ModalContentType.REMOVE_USERROLE:
        return `Are you sure you want to remove the "${data?.roleName ?? ''}" role?`;
      case ModalContentType.REGENERATE_SECRET:
        return 'Regenerate client secret';
      case ModalContentType.API_ERROR:
        return 'An error occurred';
      case ModalContentType.PROMPT_UPDATE_LICENCE:
        return 'Are you sure you want to update the licence?';
    }
  }, [currentContentType, data?.gbgName, data?.username, data?.functionName, data?.gbgUseCaseText, data?.roleName]);

  const headerText = useMemo<string | undefined>(() => {
    switch (currentContentType) {
      case ModalContentType.EMPTY:
        return undefined;
      case ModalContentType.REMOVE_ORGANISATION:
        return 'This organisation will not be able to access GBG services.';
      case ModalContentType.REMOVE_APPLICATION:
        return 'This application will not be able to access GBG services.';
      case ModalContentType.REMOVE_LICENCE:
        return 'This Licence will not be able to access GBG services.';
      case ModalContentType.REMOVE_FUNCTION:
        return 'This function will not be able to access GBG services.';
      case ModalContentType.REMOVE_DEPARTMENT:
        return 'This department will not be able to access GBG services.';
      case ModalContentType.REMOVE_USER:
        return 'This team member will not be able to access to your account';
      case ModalContentType.REMOVE_DATASOURCE:
        return 'Remove Data Source';
      case ModalContentType.REMOVE_DEPARTMENTROLE:
        return 'Remove Department Role';
      case ModalContentType.REMOVE_ROLEFEATURE:
        return 'Remove Role Feature Permission';
      case ModalContentType.REMOVE_ROLEUSECASE:
        return 'Remove Role Use Case';
      case ModalContentType.REMOVE_USECASE:
        return 'Remove Use Case';
      case ModalContentType.REMOVE_DEPARTMENTUSECASE:
        return 'Remove Department Use Case';
      case ModalContentType.REMOVE_USERROLE:
        return 'Remove User Role';
      case ModalContentType.REGENERATE_SECRET:
        return 'The previous secret will stop working immediately';

      case ModalContentType.API_ERROR:
        return 'There was a problem with the request.';
    }
  }, [currentContentType]);

  const content = useMemo<JSX.Element>(() => {
    switch (currentContentType) {
      case ModalContentType.EMPTY:
        return <div data-testid="empty"></div>;
      case ModalContentType.REMOVE_ORGANISATION:
        return <RemoveOrganisation organisation={data} />;
      case ModalContentType.REMOVE_APPLICATION:
        return <RemoveApplication application={data} />;
      case ModalContentType.REMOVE_LICENCE:
        return <RemoveLicence licence={data} />;
      case ModalContentType.REMOVE_FUNCTION:
        return (
          data?.appId &&
          data.gbgFunctionID &&
          data.gbgName && <RemoveFunction {...{ appId: data.appId, funcId: data.gbgFunctionID, name: data.gbgName }} />
        );
      case ModalContentType.REMOVE_DEPARTMENT:
        return <RemoveDepartment departId={data?.gbgDeptId} name={data?.gbgName} />;
      case ModalContentType.REMOVE_USER:
        return (
          data?.id &&
          data.username && <RemoveUser {...{ userId: data.id, orgId: data.gbgCompanyId, username: data.username }} />
        );
      case ModalContentType.REMOVE_DATASOURCE:
        return <RemoveDataSource dataSource={data} />;
      case ModalContentType.REMOVE_USECASE:
      case ModalContentType.REMOVE_ROLEUSECASE:
      case ModalContentType.REMOVE_DEPARTMENTUSECASE:
        return <RemoveUseCase useCase={data} />;
      case ModalContentType.REMOVE_DEPARTMENTROLE:
        return <RemoveDepartmentRole departmentRole={data} />;
      case ModalContentType.REMOVE_ROLEFEATURE:
        return <RemoveRoleFeature departmentRole={data?.departmentRole} functionName={data?.functionName ?? ''} />;
      case ModalContentType.REMOVE_USERROLE:
        return <RemoveUserRole user={data?.user} roleName={data?.roleName} />;
      case ModalContentType.REGENERATE_SECRET:
        return <RegenerateSecret client={data} />;
      case ModalContentType.API_ERROR:
        return <ApiError error={data} />;
      case ModalContentType.PROMPT_UPDATE_LICENCE:
        return <PromptUpdateLicence data={data} />;
    }
  }, [
    currentContentType,
    data,
    data?.id,
    data?.gbgDeptId,
    data?.appId,
    data?.gbgFunctionID,
    data?.gbgName,
    data?.username,
    data?.functionName,
    data?.departmentRole,
    data?.roleName,
    data?.user,
    data?.gbgCompanyId,
  ]);

  return (
    <GBG.Modal style={{ zIndex: 9 }} isVisible={isOpen}>
      <GBG.ModalHeader>
        <GBG.AlertBar data-testid="alertBar" icon={GBG.IconKeys.AlertTriangle} type={type}>
          <GBG.AlertBarText title={title} text={headerText} />
        </GBG.AlertBar>
      </GBG.ModalHeader>
      <GBG.ModalBody data-testid="modal-body">{content}</GBG.ModalBody>
    </GBG.Modal>
  );
};

export default memo(Modal);
