import { IAction } from '../../interfaces/IStore';
import {
  SET_FUNCTION_DN,
  SET_MODAL_DATA,
  SET_MODAL_TYPE,
  SET_MODAL_VISIBILITY,
  SET_SELECTED_ITEM_INDEX,
  SET_TOKEN,
  SET_USER_ID,
  SET_IS_FETCHING,
} from './actionTypes';

export const setModalData: (value: {
  index?: number;
  type: string;
  message?: any;
  title?: string;
  subtitle?: string;
  buttons?: string[];
}) => IAction<any> = payload => {
  return {
    type: SET_MODAL_DATA,
    payload,
  };
};

export const setModalVisibility: (value: boolean) => IAction<any> = payload => {
  return {
    type: SET_MODAL_VISIBILITY,
    payload,
  };
};

export const setIsFetching: (value: boolean) => IAction<any> = payload => {
  return {
    type: SET_IS_FETCHING,
    payload,
  };
};

export const setFunctionDn: (value: string) => IAction<any> = payload => {
  return {
    type: SET_FUNCTION_DN,
    payload,
  };
};

export const setToken: (value: string) => IAction<any> = payload => {
  return {
    type: SET_TOKEN,
    payload,
  };
};

export const setUserId: (value: string) => IAction<any> = payload => {
  return {
    type: SET_USER_ID,
    payload,
  };
};

export const setModalType: (value: string) => IAction<any> = payload => {
  return {
    type: SET_MODAL_TYPE,
    payload,
  };
};

export const setSelectedItemIndex: (value: number) => IAction<any> = payload => {
  return {
    type: SET_SELECTED_ITEM_INDEX,
    payload,
  };
};
