export const SCOPES = {
  None: '1',
  FullAccess: '2',
  CanCreate: '3',
  CanEdit: '4',
  CanView: '5',
};

export const userRoleTypes = {
  DataSource_Admin: 'GAU_DataSources',
  Application_Admin: 'GAU_Applications',
  Customer_Admin: 'GAU_CUST_ADMIN',
  Gbg_Admin: 'GAU_GBG_Admin',
};
