import GBG from '@gbg/gbgcomponentlibrary_react';
import { FC, memo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import Table from '../../../components/table/Table';
import { ModalContentType } from '../../../features/modal/config';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import { useGetUserRolesQuery } from '../../../api/roles';
import { getCustomerViewPermissionScopes } from '../../../auth/customerViewAccess';
import { SCOPES } from '../../../auth/permissions';

export const UserRoles: FC<{ orgId: string; user: IUser; departId?: string; baseCreateEditPageURL: string }> = ({
  orgId,
  user,
  departId,
  baseCreateEditPageURL,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const headers = ['gbgName'];
  const {
    data: userRoles,
    isFetching,
    isError,
    error,
  } = useGetUserRolesQuery(
    {
      orgId,
      departId: departId ?? user.gbgDepartmentId,
      userId: user.id ?? '',
    },
    { skip: !user },
  );

  const permissionScope = getCustomerViewPermissionScopes();
  const onItemClicked = useCallback(
    (item: IDepartmentRole) => {
      history.push(`${baseCreateEditPageURL}/${item.gbgRoleID}`);
    },
    [baseCreateEditPageURL],
  );

  useEffect(() => {
    if (!isError) dispatch(closeModal());
    else dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
  }, [isError, error]);

  const actions = useCallback(
    (item: IDepartmentRole) => [
      {
        title: 'Remove',
        danger: true,
        action: (dataItem: IDepartmentRole) => {
          const updatedUser = {
            ...user,
            userRoles: userRoles?.embedded.entries
              .filter(role => role.gbgRoleID !== (dataItem || item).gbgRoleID)
              .map(x => x.gbgRoleID),
          };
          dispatch(
            openModal({
              type: ModalContentType.REMOVE_USERROLE,
              data: { user: updatedUser, roleName: (item || dataItem).gbgName },
            }),
          );
        },
      },
    ],
    [user, userRoles?.embedded.entries],
  );

  return isFetching || !userRoles ? (
    <div className="text-center p-5">
      <GBG.Spinner data-testid="spinner" />
    </div>
  ) : (
    <Table
      addUrl={`${baseCreateEditPageURL}/add-role`}
      searchFields={headers}
      isPaginated={false}
      data={userRoles}
      onItemClicked={permissionScope.permissionScope === SCOPES.FullAccess ? onItemClicked : undefined}
      {...{ headers, isFetching, actions }}
    />
  );
};
export default memo(UserRoles);
