import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

import pingReducer from '../features/ping/pingSlice';
import modalReducer from '../features/modal/modalSlice';
import languageReducer from '../features/language/languageSlice';
import organisationReducer from '../store/reducers/organisationReducer';
import generalReducer from '../store/reducers/generalReducer';
import { adminApi } from './api';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['general', 'organisation', 'api', 'modal', 'language'],
};

export const store = configureStore({
  reducer: persistReducer(
    persistConfig,
    combineReducers({
      modal: modalReducer,
      ping: pingReducer,
      language: languageReducer,
      organisation: organisationReducer,
      general: generalReducer,
      [adminApi.reducerPath]: adminApi.reducer,
    }),
  ),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(adminApi.middleware),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
