import { memo, useEffect } from 'react';
import { useGetAppFunctionsQuery, useGetFunctionsByApplicationIdQuery } from '../../../api/functions';
import { useAppDispatch } from '../../../app/hooks';
import { getCustomerViewPermissionScopes } from '../../../auth/customerViewAccess';
import { SCOPES } from '../../../auth/permissions';
import Spinner from '../../../components/common/Spinner';
import { ModalContentType } from '../../../features/modal/config';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import MultiSelectFunctionList from './MultiSelectFunctionList';
import { LicenceItemSelectionStatus } from '../../../enums/LicenceSelectStatus';

const LicenceFunctionsList: React.FC<ILicenceFunctionsList> = ({
  licence,
  readOnlyMode = false,
  setFunctions,
  functions,
}) => {
  const dispatch = useAppDispatch();
  const permissionScope = getCustomerViewPermissionScopes();

  const {
    data: functionList,
    isFetching,
    isError,
    error,
  } = permissionScope.permissionScope === SCOPES.FullAccess && !licence.gbgDepartmentId
    ? useGetAppFunctionsQuery({ appId: licence.gbgApplicationId })
    : useGetFunctionsByApplicationIdQuery({ appId: licence.gbgApplicationId, orgId: licence.gbgCompanyId });
  useEffect(() => {
    if (functionList && functionList.length > 0) {
      const functionItems = functionList.map(x => {
        const status = licence?.gbgMandatoryFunctionId?.some(y => y === x.gbgFunctionID)
          ? LicenceItemSelectionStatus.Mandatory
          : licence.gbgFunctionId?.some(y => y === x.gbgFunctionID)
          ? LicenceItemSelectionStatus.Selected
          : LicenceItemSelectionStatus.Off;
        return { name: x.gbgName, id: x.gbgFunctionID, status: status };
      });
      setFunctions(functionItems);
    }
  }, [functionList, licence.gbgFunctionId, licence.gbgMandatoryFunctionId]);

  useEffect(() => {
    if (!isError) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
    }
  }, [isError, error]);

  return (
    <>
      {isFetching && <Spinner />}
      {functions && functions.length > 0 && (
        <MultiSelectFunctionList
          functions={functions}
          readonly={readOnlyMode}
          setFunctionList={setFunctions}
          hasMultiSelect={licence?.gbgDepartmentId ? false : true}
        />
      )}
    </>
  );
};

interface ILicenceFunctionsList {
  licence: ILicence;
  readOnlyMode: boolean;
  setFunctions: React.Dispatch<React.SetStateAction<ISelectableLicenceFunctionItem[]>>;
  functions: ISelectableLicenceFunctionItem[];
}

export default memo(LicenceFunctionsList);
