import { useParams } from 'react-router-dom';

import GBG from '@gbg/gbgcomponentlibrary_react';
import useTabBar from '../../../hooks/useTabBar';
import UserDetails from './UserDetails';
import UserRoles from './UserRoles';
import { useGetUserQuery } from '../../../api/users';
import { memo, useEffect, useMemo } from 'react';
import Breadcrumb from '../../../components/layout/Breadcrumb';
import { useGetOrganisationQuery } from '../../../api/organisations';
import { useIntl } from 'react-intl';
import { useAppDispatch } from '../../../app/hooks';
import { ModalContentType } from '../../../features/modal/config';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import { useGetClientQuery } from '../../../api/client';

const UserPage = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { orgId, userId, clientId } = useParams<{ orgId: string; userId: string; clientId?: string }>();
  const { data: user, isFetching: isUserFetching, isError, error } = useGetUserQuery({ userId, orgId });
  const { data: organisation } = useGetOrganisationQuery(orgId);
  const { data: client } = useGetClientQuery(clientId ?? '', { skip: !clientId });
  const displayName = user?.gbgClientId ? user.username : user?.email || '';

  const baseCreateEditPageURL = `/organisations/${orgId}${
    clientId ? `/clients/${clientId}` : ''
  }/users/${userId}/roles`;
  useEffect(() => {
    if (!isError) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
    }
  }, [isError, error]);

  const tabs = useMemo<string[]>(() => ['label.details', 'role.title'].map(id => intl.formatMessage({ id })), []);
  const { render: renderTabBar, tabIndex } = useTabBar(tabs);

  const switchContent = () => {
    switch (tabIndex) {
      case 0:
        return (
          <div className="m-m-t-2">
            {!isUserFetching && user ? <UserDetails {...{ user, clientId }} /> : <GBG.Spinner />}
          </div>
        );
      case 1:
        return (
          <div className="m-m-t-2">
            {!isUserFetching && user ? (
              <UserRoles baseCreateEditPageURL={baseCreateEditPageURL} orgId={orgId} user={user} />
            ) : (
              <GBG.Spinner />
            )}
          </div>
        );
    }
  };

  return (
    <>
      {user?.id && organisation && (!clientId || client) && (
        <Breadcrumb
          params={{
            [orgId]: organisation.gbgName,
            [clientId ?? '']: client?.gbgName ?? '',
            [user.id]: displayName,
          }}
        />
      )}
      <h1>{displayName}</h1>
      {renderTabBar()}
      {switchContent()}
    </>
  );
};

export default memo(UserPage);
