import { memo, useCallback, useEffect } from 'react';
import { useGetFunctionsByRoleIdQuery } from '../../../../api/functions';
import {} from '../../../../api/roles';
import { useAppDispatch } from '../../../../app/hooks';
import Table from '../../../../components/table/Table';
import { ModalContentType } from '../../../../features/modal/config';
import { closeModal, openModal } from '../../../../features/modal/modalSlice';

interface IRoleFeaturesData {
  role: IDepartmentRole;
  addUrl: string;
}

const RolesFeatures: React.FC<IRoleFeaturesData> = ({ role, addUrl }) => {
  const dispatch = useAppDispatch();
  const { data, isFetching, isError, error } = useGetFunctionsByRoleIdQuery({
    orgId: role.gbgCompanyId,
    roleId: role.gbgRoleID,
  });

  useEffect(() => {
    if (!isError) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
    }
  }, [isError, error, dispatch]);

  const actions = useCallback(
    (item: IFunction) => [
      {
        title: 'Remove',
        danger: true,
        action: (dataItem: IFunction) => {
          const removedItemData = {
            ...role,
            ...{
              gbgFunctionId: data?.filter(x => x.gbgFunctionID !== dataItem.gbgFunctionID).map(x => x.gbgFunctionID),
            },
          };
          dispatch(
            openModal({
              type: ModalContentType.REMOVE_ROLEFEATURE,
              data: { departmentRole: removedItemData, functionName: item.gbgName },
            }),
          );
        },
      },
    ],
    [data, role, role.gbgRoleID],
  );

  return (
    <>
      <Table
        title="role.feature"
        titleTag="h5"
        addUrl={addUrl}
        isPaginated={false}
        searchFields={['gbgName']}
        headers={['gbgName']}
        data={{ embedded: { entries: data ?? [] }, size: data?.length ?? 0 }}
        {...{ isFetching, actions }}
      />
    </>
  );
};
export default memo(RolesFeatures);
