import { FormattedMessage } from 'react-intl';
import GBG from '@gbg/gbgcomponentlibrary_react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { useHistory, useLocation, useParams } from 'react-router-dom';
import { OrganisationTabs } from '../../../constants/tabs';

const SecretClient: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { orgId } = useParams<{ clientId: string; orgId: string }>();
  const secret = (location.state as IState).secret;
  const clientId = (location.state as IState).clientId;

  return (
    <div>
      <div>
        <div className="text-center p-m-3 m-m-2">
          <div className="d-flex justify-content-center">
            <GBG.Icon className="text-g800" icon={GBG.IconKeys.CheckCircle} />
          </div>
          {clientId ? (
            <>
              <FormattedMessage id="clients.messages.added.title" tagName="h1" />
              <FormattedMessage id="clients.messages.added.text" />
            </>
          ) : (
            <>
              <FormattedMessage id="clients.messages.regenerated.title" tagName="h1" />
              <FormattedMessage id="clients.messages.regenerated.text" />
            </>
          )}
        </div>
      </div>
      {clientId && (
        <div className="d-inline">
          <FormattedMessage id="clients.messages.added.newClientId" tagName="h2" />
          <input
            className="p-s-3 m-s-3 bg-b100"
            style={{ width: '50%', minWidth: '300px' }}
            value={clientId}
            readOnly
            data-testid="clientId"
          />
          <CopyToClipboard text={clientId}>
            <GBG.Button kind={GBG.ButtonKind.Primary} className="m-m-2" data-testid="copyClientIdButton">
              <FormattedMessage id="label.copy" />
            </GBG.Button>
          </CopyToClipboard>
        </div>
      )}
      <div className="d-inline">
        <FormattedMessage id="clients.messages.added.newClientSecret" tagName="h2" />
        <input
          className="p-s-3 m-s-3 bg-b100"
          style={{ width: '50%', minWidth: '300px' }}
          value={secret}
          readOnly
          data-testid="clientSecret"
        />
        <CopyToClipboard text={secret}>
          <GBG.Button kind={GBG.ButtonKind.Primary} className="m-m-2" data-testid="copySecretButton">
            <FormattedMessage id="label.copy" />
          </GBG.Button>
        </CopyToClipboard>
      </div>

      <div className="alert-bar alert-bar--warn" style={{ alignItems: 'center' }}>
        <i className="icon alert-bar__icon">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11 0C11.3788 0 11.725 0.214002 11.8944 0.552786L21.8944 20.5528C22.0494 20.8628 22.0329 21.2309 21.8507 21.5257C21.6684 21.8205 21.3466 22 21 22H1C0.653423 22 0.331557 21.8205 0.14935 21.5257C-0.0328564 21.2309 -0.0494204 20.8628 0.105574 20.5528L10.1056 0.552786C10.275 0.214002 10.6212 0 11 0ZM19.382 20L11 3.23607L2.61804 20H19.382ZM11 8C11.5523 8 12 8.44771 12 9V13C12 13.5523 11.5523 14 11 14C10.4477 14 10 13.5523 10 13V9C10 8.44771 10.4477 8 11 8ZM11 18C11.5523 18 12 17.5523 12 17C12 16.4477 11.5523 16 11 16C10.4477 16 10 16.4477 10 17C10 17.5523 10.4477 18 11 18Z"
              fill="#772828"
            />
          </svg>
        </i>
        <div className="alert-bar__text">
          <span className="alert-bar__title heading-xsmall">
            <FormattedMessage id="clients.messages.added.warningTitle" />
          </span>
          <p className="alert-bar__text paragraph-small">
            <FormattedMessage id="clients.messages.added.warningText" />
          </p>
        </div>
      </div>

      <GBG.Button
        kind={GBG.ButtonKind.Secondary}
        className="m-m-2"
        onClick={() => history.push(`/organisations/${orgId}?tab=${OrganisationTabs.CLIENTS}`)}
      >
        <FormattedMessage id="label.back" />
      </GBG.Button>
    </div>
  );
};

interface IState {
  secret: string;
  clientId?: string;
}

export default SecretClient;
