import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ModalContentType } from '../../../features/modal/config';
import { useAppDispatch } from '../../../app/hooks';
import { useGetApplicationQuery } from '../../../api/applications';
import { useGetFunctionQuery } from '../../../api/functions';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import Breadcrumb from '../../../components/layout/Breadcrumb';
import Spinner from '../../../components/common/Spinner';
import FunctionForm from './FunctionForm';
import { userRoleTypes, SCOPES } from '../../../auth/permissions';

export const FunctionPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { appId, id } = useParams<{ appId: string; id: string }>();
  const { data, isFetching, isError, error } = useGetFunctionQuery(id);
  const { data: appData, isFetching: isAppFetching } = useGetApplicationQuery(appId);
  const permissions = { [userRoleTypes.Application_Admin]: SCOPES.FullAccess };

  useEffect(() => {
    if (!isError) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
    }
  }, [isError, error, closeModal, openModal]);

  return (
    <>
      {!isFetching && data && !isAppFetching && appData && (
        <Breadcrumb params={{ [appId]: appData.gbgName, [id]: data.gbgName }} />
      )}

      <h1>{data?.gbgName}</h1>

      {isFetching ? <Spinner /> : <FunctionForm {...{ appId, funcData: data, isNew: false, permissions }} />}
    </>
  );
};

export default memo(FunctionPage);
