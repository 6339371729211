import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { ModalContentType } from '../../../../features/modal/config';
import { useAppDispatch } from '../../../../app/hooks';
import { useGetOrganisationQuery } from '../../../../api/organisations';
import { useGetUserQuery } from '../../../../api/users';
import { closeModal, openModal } from '../../../../features/modal/modalSlice';
import useTabBar from '../../../../hooks/useTabBar';
import Breadcrumb from '../../../../components/layout/Breadcrumb';
import UserRoles from '../../Users/UserRoles';
import UserForm from '../../Users/UserForm';

const DepartmentUserPage = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { orgId, departId, userId } = useParams<{ orgId: string; departId: string; userId: string }>();
  const tabs = useMemo<string[]>(
    () => ['label.details', 'role.title'].map(x => intl.formatMessage({ id: x })),
    [intl.locale],
  );
  const { render: renderTabBar, tabIndex } = useTabBar(tabs);
  const { data, isFetching, isError, error } = useGetUserQuery({ userId, orgId });
  const { data: orgData, isFetching: isOrgFetching } = useGetOrganisationQuery(orgId);
  const baseCreateEditPageURL = `/organisations/${orgId}/departments/${departId}/users/${userId}/roles`;

  const departmentOptions = useMemo<ISelectOption[]>(
    () => (!data ? [] : [{ value: data.gbgDepartmentId, label: data.gbgDepartmentName }]),
    [data, data?.gbgDepartmentId, data?.gbgDepartmentName],
  );

  useEffect(() => {
    if (!isError) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
    }
  }, [isError, error]);

  return (
    <>
      {!isFetching && data && !isOrgFetching && orgData && (
        <Breadcrumb
          params={{ [orgId]: orgData.gbgName, [departId]: data.gbgDepartmentName, [userId]: data.username }}
        />
      )}

      <h1>{data?.email}</h1>

      {renderTabBar()}

      {tabIndex === 0 &&
        (isFetching ? (
          <div className="text-center p-5">
            <GBG.Spinner data-testid="spinner" />
          </div>
        ) : (
          <UserForm isNew={false} userData={data} {...{ orgId, departId, departmentOptions }} />
        ))}
      {tabIndex === 1 &&
        (isFetching || !data ? (
          <div className="text-center p-5">
            <GBG.Spinner data-testid="spinner" />
          </div>
        ) : (
          <UserRoles user={data} orgId={orgId} departId={departId} baseCreateEditPageURL={baseCreateEditPageURL} />
        ))}
    </>
  );
};

export default memo(DepartmentUserPage);
