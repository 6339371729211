import { FormattedMessage } from 'react-intl';
import GBG from '@gbg/gbgcomponentlibrary_react';

import { ORGANISATION_LICENCE_FIELDS } from '../../../constants/formData';
import Breadcrumb from '../../../components/layout/Breadcrumb';
import { useHistory, useParams } from 'react-router-dom';
import { useGetOrganisationQuery } from '../../../api/organisations';
import { memo, useCallback, useMemo, useState } from 'react';
import { useUrlParams } from '../../../hooks/useUrlParams';
import Form from '../../../components/forms/Form';
import { useForm } from 'react-hook-form';
import { useAddLicenceMutation, useGetLicenceByParamQuery } from '../../../api/licences';
import { useAppDispatch } from '../../../app/hooks';
import { TOAST_SUCCESS } from '../../../constants';
import { ModalContentType } from '../../../features/modal/config';
import { openModal } from '../../../features/modal/modalSlice';
import { DepartmentTabs, OrganisationTabs } from '../../../constants/tabs';
import { useGetDepartmentQuery } from '../../../api/departments';
import { getCustomerViewPermissionScopes } from '../../../auth/customerViewAccess';
import { SCOPES } from '../../../auth/permissions';
import _ from 'lodash';
import Spinner from '../../../components/common/Spinner';
import LicenceFunctionsList from './LicenceFunctionsList';
import { LicenceItemSelectionStatus } from '../../../enums/LicenceSelectStatus';

const NewLicencePageDetails = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { orgId, departId } = useParams<{ orgId: string; departId?: string }>();
  const { appId } = useUrlParams();
  const { data: organisation } = useGetOrganisationQuery(orgId);
  const { permissionScope } = getCustomerViewPermissionScopes();

  const { data: department, isFetching: isDepartFetching } = useGetDepartmentQuery(
    { orgId, departId: departId ?? '' },
    { skip: !departId },
  );
  const [addLicence] = useAddLicenceMutation();
  const { data: parentLicence, isFetching: isFetchingParentLicence } = useGetLicenceByParamQuery(
    { orgId, appId },
    { skip: !departId || !appId },
  );

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [functions, setFunctions] = useState<ISelectableLicenceFunctionItem[]>([]);
  const onSubmit = useCallback(
    handleSubmit((data: ILicence) => {
      const selectedFunctions = functions.filter(x => x.status === LicenceItemSelectionStatus.Selected).map(x => x.id);
      const selectedMandatoryFuncIds = functions
        .filter(x => x.status === LicenceItemSelectionStatus.Mandatory)
        .map(x => x.id);

      selectedMandatoryFuncIds.forEach(id => {
        if (!selectedFunctions.includes(id)) {
          selectedFunctions.push(id);
        }
      });

      const licence = {
        ...data,
        gbgFunctionId: selectedFunctions,
        gbgMandatoryFunctionId: selectedMandatoryFuncIds,
        gbgCompanyId: orgId,
        gbgApplicationId: appId,
        gbgAllowedSeats: data.restrictSeats ? data.gbgAllowedSeats : 0,
      } as ILicence;

      if (departId) licence.gbgDepartmentId = departId;

      addLicence(licence)
        .unwrap()
        .then(() => {
          GBG.toast(TOAST_SUCCESS);
          history.push(
            `/organisations/${orgId}${departId ? `/departments/${departId}` : ''}?tab=${
              departId ? DepartmentTabs.LICENCES : OrganisationTabs.LICENCES
            }`,
          );
        })
        .catch(error => dispatch(openModal({ type: ModalContentType.API_ERROR, data: error })));
    }),
    [handleSubmit, functions, addLicence, orgId, departId, appId, dispatch, history],
  );

  const [displayRestrictedSeat, setDisplayRestrictedSeat] = useState<boolean>(false);

  const getFormInputs = useMemo<IFormField[]>(() => {
    let fields = _.cloneDeep(ORGANISATION_LICENCE_FIELDS);
    const hasParentLicence = departId && parentLicence;

    const agreementNumberDefaultValue = hasParentLicence && parentLicence ? parentLicence.gbgAgreementNumber : '';
    const gbgAgreementNumber = fields.filter(x => x.inputName === 'gbgAgreementNumber')[0];
    gbgAgreementNumber.defaultValue = agreementNumberDefaultValue;
    if (hasParentLicence && permissionScope === SCOPES.CanView) {
      gbgAgreementNumber.disabled = true;
    }

    const restrictedSeatSwitch = fields.filter(x => x.inputName === 'restrictSeats')[0];
    restrictedSeatSwitch.onChange = event => handleRestrictedSeatSwitch(event.target.checked);

    if (hasParentLicence) {
      if ((parentLicence?.gbgAllowedSeats ?? 0) <= 0)
        fields = fields.filter(x => x.inputName !== 'restrictSeats' && x.inputName !== 'gbgAllowedSeats');
      else {
        restrictedSeatSwitch.disabled = true;
        restrictedSeatSwitch.defaultValue = 'true';
      }
    } else if (!displayRestrictedSeat) {
      fields = fields.filter(x => x.inputName !== 'gbgAllowedSeats');
    }

    return fields;
  }, [permissionScope, departId, parentLicence, displayRestrictedSeat]);

  const handleRestrictedSeatSwitch = (showRestrictedSeatInput: boolean) => {
    setDisplayRestrictedSeat(showRestrictedSeatInput);
  };

  return (
    <>
      {organisation && !isDepartFetching && (
        <Breadcrumb
          params={{
            [orgId]: organisation.gbgName,
            [departId ?? '']: department?.gbgName ?? '',
          }}
        />
      )}
      <FormattedMessage id="licence.add.application.to" tagName="h1" values={{ value: organisation?.gbgName }} />

      {isFetchingParentLicence ? (
        <Spinner />
      ) : (
        <Form
          {...{
            register,
            setValue,
            errors,
            onSubmit,
            fields: getFormInputs,
            shouldDisplaySaveButton:
              functions.some(x => x.status === LicenceItemSelectionStatus.Selected) ||
              functions.some(x => x.status === LicenceItemSelectionStatus.Mandatory),
          }}
        >
          <LicenceFunctionsList
            licence={
              {
                gbgApplicationId: appId,
                gbgCompanyId: orgId,
                gbgDepartmentId: departId,
                gbgMandatoryFunctionId: parentLicence?.gbgMandatoryFunctionId,
                gbgFunctionId: parentLicence?.gbgMandatoryFunctionId,
              } as ILicence
            }
            readOnlyMode={permissionScope === SCOPES.CanView && !departId}
            setFunctions={setFunctions}
            functions={functions}
          />
        </Form>
      )}
    </>
  );
};

export default memo(NewLicencePageDetails);
