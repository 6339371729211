import { adminApi } from '../app/api';
import { SCOPES } from '../auth/permissions';

const endpoint = '/api/v1/organisation';

const extendedApi = adminApi.injectEndpoints({
  endpoints: build => ({
    getOrganisations: build.query<IPaginatedRes<IOrganisation>, IPaginatedReq>({
      query: ({ search, limit, cursor }) => ({
        url: `${endpoint}?search=${search ?? ''}&cursor=${cursor ?? ''}&limit=${limit ?? ''}`,
        method: 'GET',
      }),
      providesTags: result =>
        result
          ? [
              ...result?.embedded.entries.map(({ gbgCompanyId }) => ({ type: 'Organisations', gbgCompanyId } as const)),
              { type: 'Organisations', id: 'LIST' },
            ]
          : [{ type: 'Organisations', id: 'LIST' }],
    }),
    getResellers: build.query<IOrganisation[], { organisationId?: string; permissionScope: string }>({
      query: ({ organisationId, permissionScope }) => ({
        url: `${endpoint}/${permissionScope !== SCOPES.FullAccess ? organisationId + '/' : ''}resellers`,
        method: 'GET',
      }),
      providesTags: result =>
        result
          ? [
              ...result.map(({ gbgCompanyId }) => ({ type: 'Organisations', gbgCompanyId } as const)),
              { type: 'Organisations', id: 'RESELLERS' },
            ]
          : [{ type: 'Organisations', id: 'RESELLERS' }],
    }),
    getOrganisation: build.query<IOrganisation, string>({
      query: id => ({ url: `${endpoint}/${id}`, method: 'GET' }),
      providesTags: result => (result ? [{ type: 'Organisations', gbgCompanyId: result?.gbgCompanyId }] : []),
    }),
    addOrganisation: build.mutation<IOrganisation, Partial<IOrganisation>>({
      query: data => ({ url: endpoint, method: 'POST', data }),
      invalidatesTags: [
        { type: 'Organisations', id: 'LIST' },
        { type: 'Organisations', id: 'RESELLERS' },
      ],
    }),
    updateOrganisation: build.mutation<IOrganisation, Partial<IOrganisation>>({
      query: data => ({ url: endpoint, method: 'PUT', data }),
      invalidatesTags: result => (result ? [{ type: 'Organisations', gbgCompanyId: result?.gbgCompanyId }] : []),
    }),
    deleteOrganisation: build.mutation<string, string>({
      query: id => ({ url: `${endpoint}/${id}`, method: 'DELETE' }),
      invalidatesTags: gbgCompanyId => (gbgCompanyId ? [{ type: 'Organisations', gbgCompanyId }] : []),
    }),
  }),
});

export const {
  useGetOrganisationsQuery,
  useGetResellersQuery,
  useGetOrganisationQuery,
  useAddOrganisationMutation,
  useUpdateOrganisationMutation,
  useDeleteOrganisationMutation,
} = extendedApi;
