import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { IUseCase } from '../../../../interfaces/models/IUseCase';
import useFlowdownUpdate from '../../../../hooks/useFlowdownUpdate';
import {
  useLazyGetAffectedItemsOnDepartmentUseCaseUpdateQuery,
  useGetUseCaseQuery,
  useUpdateUseCaseMutation,
} from '../../../../api/useCase';
import { useAppDispatch } from '../../../../app/hooks';
import { useGetOrganisationQuery } from '../../../../api/organisations';
import { closeModal, openModal } from '../../../../features/modal/modalSlice';
import { ModalContentType } from '../../../../features/modal/config';
import { useGetDepartmentQuery } from '../../../../api/departments';
import UseCaseDataSources from '../../../../components/forms/fields/UseCaseDataSources';
import Breadcrumb from '../../../../components/layout/Breadcrumb';
import { useGetAllowedDataSourcesForDepartmentUseCaseQuery } from '../../../../api/dataSource';
import { DepartmentTabs } from '../../../../constants/tabs';

const DepartmentUseCaseDetailsPage = () => {
  const { orgId, departId, useCaseId } = useParams<{ orgId: string; departId: string; useCaseId: string }>();
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const {
    data: useCaseDetails,
    isFetching,
    isError,
    error,
  } = useGetUseCaseQuery({ id: useCaseId, organisationId: orgId });
  const [updateUseCase, { isLoading: isUpdatingUseCase }] = useUpdateUseCaseMutation();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [getAffectedDetails] = useLazyGetAffectedItemsOnDepartmentUseCaseUpdateQuery();
  const [selectedDataSourcesArr, setSelectedDataSourcesArr] = useState(
    useCaseDetails?.gbgDataSourceID?.length ? useCaseDetails.gbgDataSourceID : [],
  );
  const {
    data: organisationDetails,
    isFetching: organisationIsFetching,
    isError: organisationHasError,
    error: organisationErrorMessage,
  } = useGetOrganisationQuery(orgId);

  const {
    data: department,
    isFetching: depIsFetching,
    isError: depHasError,
    error: depError,
  } = useGetDepartmentQuery({ orgId, departId });

  const {
    data: departmentDataSources,
    isFetching: isFetchingDataSources,
    isError: isErrorDataSources,
    error: errorDatasources,
  } = useGetAllowedDataSourcesForDepartmentUseCaseQuery(
    {
      orgId,
      useCaseText: useCaseDetails?.gbgUseCaseText as string,
    },
    { skip: !useCaseDetails },
  );

  useEffect(() => {
    setSelectedDataSourcesArr(useCaseDetails?.gbgDataSourceID?.length ? useCaseDetails.gbgDataSourceID : []);
  }, [useCaseDetails?.gbgDataSourceID, setSelectedDataSourcesArr]);

  useEffect(() => {
    if (!isError && !organisationHasError && !depHasError && !isErrorDataSources) dispatch(closeModal());
    else
      dispatch(
        openModal({
          type: ModalContentType.API_ERROR,
          data: error || organisationErrorMessage || depError || errorDatasources,
        }),
      );
  }, [
    isError,
    error,
    organisationHasError,
    isError,
    depHasError,
    depError,
    isErrorDataSources,
    errorDatasources,
    dispatch,
  ]);

  const refreshDatasource = () => {
    setIsRefreshing(true);
    setSelectedDataSourcesArr(useCaseDetails?.gbgDataSourceID?.length ? useCaseDetails.gbgDataSourceID : []);
  };

  const getUpdatingObjects = useCallback(() => {
    const data = {
      ...useCaseDetails,
      gbgDataSourceID: selectedDataSourcesArr,
    } as IUseCase;
    return { data };
  }, [useCaseDetails, useCaseDetails?.gbgDataSourceID, selectedDataSourcesArr]);

  const { isUpdating, setIsUpdating, createFlowDownModal, handleUpdate, flowdownCallback, errorCallback } =
    useFlowdownUpdate({
      orgId,
      departId,
      resourceName: useCaseDetails?.gbgUseCaseText ?? '',
      resourceTabId: DepartmentTabs.USECASES,
      getUpdatingObjects,
      submitFunction: updateUseCase,
      handleCancel: refreshDatasource,
    });

  const onUpdate = useCallback(() => {
    if (selectedDataSourcesArr.length <= 0) {
      dispatch(
        openModal({
          type: ModalContentType.API_ERROR,
          data: { message: intl.formatMessage({ id: 'dataSources.notSelectedAny' }) },
        }),
      );

      return;
    }
    if (!useCaseDetails?.gbgDataSourceID?.length) return handleUpdate();

    const removedDataSourceIDs = useCaseDetails.gbgDataSourceID.filter(
      dataSourceId => selectedDataSourcesArr.indexOf(dataSourceId) < 0,
    );
    if (!removedDataSourceIDs.length) return handleUpdate();

    setIsUpdating(true);
    getAffectedDetails({
      orgId,
      departId,
      useCaseText: useCaseDetails?.gbgUseCaseText,
      removedIDs: removedDataSourceIDs.join(','),
    })
      .unwrap()
      .then(result => {
        flowdownCallback(result);
      })
      .catch((errors: any) => errorCallback(errors));
  }, [
    selectedDataSourcesArr,
    setIsUpdating,
    handleUpdate,
    getAffectedDetails,
    orgId,
    departId,
    useCaseDetails?.gbgDataSourceID,
    useCaseDetails?.gbgUseCaseText,
    flowdownCallback,
    errorCallback,
  ]);

  return isFetching ||
    organisationIsFetching ||
    depIsFetching ||
    !useCaseDetails ||
    !organisationDetails ||
    !department ? (
    <div className="text-center p-5">
      <GBG.Spinner data-testid="spinner" />
    </div>
  ) : (
    <>
      <Breadcrumb
        params={{
          [orgId]: organisationDetails.gbgName,
          [departId]: department.gbgName,
          [useCaseId]: useCaseDetails.gbgUseCaseText ?? '',
        }}
      />
      <div className="m-m-t-2">
        <h1>{useCaseDetails.gbgUseCaseText}</h1>
        <div className="form__small">
          <div className="m-m-t-3 full-size-input">
            <UseCaseDataSources
              key="gbgSelectedDatasource"
              id="dataSources.title"
              setSelectedDataSources={setSelectedDataSourcesArr}
              selectedItem={useCaseDetails}
              triggerRefresh={isRefreshing}
              isFetching={isFetchingDataSources}
              items={departmentDataSources?.embedded.entries ?? []}
            />
          </div>
          <GBG.Button
            type="submit"
            className="m-m-t-2"
            kind={GBG.ButtonKind.Primary}
            worker={true}
            active={isUpdating || isUpdatingUseCase}
            onClick={onUpdate}
          >
            <FormattedMessage id="label.update" />
          </GBG.Button>
        </div>
        {createFlowDownModal()}
      </div>
    </>
  );
};

export default memo(DepartmentUseCaseDetailsPage);
