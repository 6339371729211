import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@gbg/gbgcomponentlibrary/dist/gbgcomponentlibrary.css';
import '@gbg/gbgcomponentlibrary_react/build/index.css';
import './index.css';
import App from './App';
import { persistor, store } from './app/store';
import * as serviceWorker from './serviceWorker';
import ConnectedIntlProvider from './containers/ConnectedIntlProvider';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedIntlProvider>
          <Router>
            <Switch>
              <Route path={'/'}>
                <App />
              </Route>
            </Switch>
          </Router>
        </ConnectedIntlProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

TimeAgo.addDefaultLocale(en);
