import { FC, memo } from 'react';
import GBG from '@gbg/gbgcomponentlibrary_react';
import useModalData from './useModalData';
import FlowDownModalBody from './FlowDownModalBody';
import { useDispatch } from 'react-redux';
import { setModalVisibility } from '../store/actions/generalActions';

interface IUseFlowDownModal {
  departId: string | undefined;
  handleUpdate: () => void;
  handleCancel?: () => void;
  selectedLicencesForPromote: string[];
  setSelectedLicencesForPromote: React.Dispatch<React.SetStateAction<string[]>>;
  flowDownItems: IAffectedDetailDto | undefined;
  errors: IApiError | undefined;
  gotoDepartment: (id: string, tabIndex: number) => void;
}
const FlowDownModal: FC<IUseFlowDownModal> = ({
  selectedLicencesForPromote,
  setSelectedLicencesForPromote,
  handleCancel,
  handleUpdate,
  departId,
  flowDownItems,
  gotoDepartment,
  errors,
}) => {
  const { title, subtitle, type, isVisible } = useModalData();
  const dispatch = useDispatch();

  return !isVisible ? null : (
    <GBG.Modal
      style={{ zIndex: 9 }}
      isVisible={isVisible}
      className={`modal-lg ${isVisible ? 'modal-enter-done' : ''}`}
    >
      <GBG.ModalHeader>
        <GBG.AlertBar
          data-testid="alertBar"
          icon={GBG.IconKeys.AlertTriangle}
          type={type == 'error' ? GBG.AlertBarType.Error : GBG.AlertBarType.Info}
        >
          <GBG.AlertBarText title={title} text={subtitle} />
        </GBG.AlertBar>
      </GBG.ModalHeader>
      <GBG.ModalBody data-testid="modal-body">
        {errors && (
          <>
            <p>The request could not be completed.</p>
            <p>{errors.message}</p>
            {errors.details?.map((detail, index) => {
              return <p key={index}>{detail.message}</p>;
            })}
          </>
        )}

        {flowDownItems && (
          <FlowDownModalBody
            departId={departId}
            selectedLicencesForPromote={selectedLicencesForPromote}
            setSelectedLicencesForPromote={setSelectedLicencesForPromote}
            flowDownItems={flowDownItems}
            gotoDepartment={gotoDepartment}
          />
        )}

        <div className="modal-footer">
          {type != 'error' && (
            <GBG.Button
              kind={GBG.ButtonKind.Primary}
              className="active-list__controls--all-active"
              aria-label="Set all active"
              onClick={handleUpdate}
            >
              Apply
            </GBG.Button>
          )}
          <GBG.Button
            kind={GBG.ButtonKind.Secondary}
            className="active-list__controls--all-active"
            aria-label="Set all active"
            onClick={() => {
              dispatch(setModalVisibility(false));
              if (handleCancel) handleCancel();
            }}
          >
            Cancel
          </GBG.Button>
        </div>
      </GBG.ModalBody>
    </GBG.Modal>
  );
};

export default memo(FlowDownModal);
