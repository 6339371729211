import GBG from '@gbg/gbgcomponentlibrary_react';

export const getToastSuccess = () => ({
  title: 'Success!',
  content: 'The content has been updated.',
  duration: 3000,
  position: GBG.ToastPosition.TopRight,
  type: GBG.ToastType.Success,
  dismissOthers: false,
  dismissable: true,
});

export const getToastError = () => ({
  title: 'Error!',
  content: 'The name is already used',
  duration: 3000,
  position: GBG.ToastPosition.TopRight,
  type: GBG.ToastType.Error,
  dismissOthers: false,
  dismissable: true,
});
