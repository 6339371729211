import GBG from '@gbg/gbgcomponentlibrary_react';
import { ElementType, memo, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PermissionGate, { getPermissionType } from '../../auth/PermissionGate';
import { SCOPES } from '../../auth/permissions';

import { useTableDataset } from '../../hooks/table/useTableDataset';

interface ITable {
  title?: string;
  addUrl?: string;
  headers: string[];
  isPaginated?: boolean;
  data: IPaginatedRes<any> | undefined;
  isFetching: boolean;
  searchFields?: string[];
  onItemClicked?: (item: any) => void;
  actions: (item: any) => any[];
  setSearch?: React.Dispatch<React.SetStateAction<string>>;
  setCursor?: React.Dispatch<React.SetStateAction<string>>;
  titleTag?: ElementType<any>;
  permissions?: { [x: string]: string };
  isUninitialized?: boolean;
  extraButtons?: JSX.Element;
}

const Table: React.FC<ITable> = ({
  title,
  addUrl,
  headers,
  isPaginated = true,
  data,
  isFetching,
  searchFields = [],
  onItemClicked,
  actions,
  setSearch,
  setCursor,
  titleTag = 'h1',
  permissions,
  isUninitialized = false,
  extraButtons,
}: ITable) => {
  const intl = useIntl();
  const history = useHistory();
  const { emptyStateContent, headerContent, cellContent } = useTableDataset(headers, isUninitialized);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [cursors, setCursors] = useState<string[]>(['']);
  const [internSearch, setInternSearch] = useState<string>('');
  const permissionType = permissions ? getPermissionType(permissions) : SCOPES.FullAccess;
  const clickableClass = onItemClicked === undefined ? '' : 'clickable';

  const handleSearch = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key !== 'Enter') return;

      // pagination
      if (isPaginated && setCursor) {
        setCursor('');
        setCurrentPage(1);
        setCursors(['']);
      }

      // search
      const _search = (ev.target as HTMLInputElement).value;
      if (setSearch) {
        setSearch(_search);
      } else {
        setInternSearch(_search.toLowerCase());
      }
    },
    [isPaginated, setSearch],
  );

  const onNext = useCallback(() => {
    if (!isPaginated || !setCursor) return;

    setCurrentPage(prev => prev + 1);
    setCursors(prev => [...prev, data?.next ?? '']);
    setCursor(data?.next ?? '');
  }, [data, isPaginated]);

  const onPrevious = useCallback(() => {
    if (!isPaginated || !setCursor) return;

    setCurrentPage(prev => prev - 1);
    setCursor(cursors[cursors.length - 2]);
    setCursors(prev => prev.slice(0, prev.length - 1));
  }, [cursors, isPaginated, setCursor]);

  const handleAdd = useCallback(() => {
    if (addUrl) history.push(addUrl);
  }, [addUrl]);

  const filteredData = useMemo<any[]>(() => {
    if (!data?.embedded?.entries?.length) return [];

    if (!internSearch?.length) return data.embedded.entries;

    return data.embedded.entries.filter((item: { [x: string]: string }) =>
      searchFields.find(field => item[field].toLowerCase().includes(internSearch)),
    );
  }, [data?.embedded?.entries, internSearch, searchFields]);

  return (
    <>
      <div className="row">
        <div className="col-md-6 text-left p-3">{title && <FormattedMessage id={title} tagName={titleTag} />}</div>
        <PermissionGate permissionType={permissionType}>
          <div className="col-md-6 col-xs-12 text-right">
            <div className="btn-group">
              {extraButtons && <>{extraButtons}</>}
              {addUrl && (
                <GBG.Button type="submit" kind={GBG.ButtonKind.Primary} className="mt-3 m-l-1" onClick={handleAdd}>
                  <FormattedMessage id="btn.add" />
                </GBG.Button>
              )}
            </div>
          </div>
        </PermissionGate>
      </div>

      <div className="my-3 d-grid align-items-center justify-content-end">
        <GBG.Slug
          className="mt-2"
          data-testid="search"
          slug={GBG.IconKeys.Search}
          placeholder={intl.formatMessage({ id: 'label.search' })}
          onKeyUp={handleSearch}
        />
      </div>

      <GBG.Table
        data-testid="table"
        className={`w-100 ${clickableClass}`}
        dataSet={{
          emptyStateContent,
          headerContent,
          cellContent,
          cellFilter: headers,
          loading: isFetching,
          selectable: false,
          data: filteredData,
          onItemClicked,
          actions: permissionType !== SCOPES.None && permissionType !== SCOPES.CanView ? actions : undefined,
        }}
      />

      {isPaginated && !isFetching && (currentPage > 1 || data?.next) && (
        <GBG.TablePager
          data-testid="pager"
          totalPages={!data?.next ? currentPage : currentPage + 1}
          {...{ currentPage, onNext, onPrevious }}
        />
      )}
    </>
  );
};

export default memo(Table);
