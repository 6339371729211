import { memo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAppDispatch } from '../../../app/hooks';
import { ModalContentType } from '../../../features/modal/config';
import { useGetAppFunctionsQuery } from '../../../api/functions';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import Table from '../../../components/table/Table';
import { userRoleTypes, SCOPES } from '../../../auth/permissions';

interface IFunctionsList {
  appId: string;
}

const FunctionsList: React.FC<IFunctionsList> = ({ appId }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const headers = ['gbgName', 'gbgFunctionStatus'];
  const { data: entries, isFetching, isError, error } = useGetAppFunctionsQuery({ appId });
  const permissions = { [userRoleTypes.Application_Admin]: SCOPES.FullAccess };

  const onItemClicked = useCallback(
    (item: IFunction) => history.push(`/applications/${appId}/functions/${item.gbgFunctionID}`),
    [appId],
  );

  useEffect(() => {
    if (!isError) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
    }
  }, [isError, error, closeModal, openModal]);

  const actions = useCallback(
    (item: IFunction) => [
      {
        title: 'Remove',
        danger: true,
        action: (dataItem: IFunction) => {
          dispatch(
            openModal({
              type: ModalContentType.REMOVE_FUNCTION,
              data: { ...(item || dataItem), appId },
            }),
          );
        },
      },
    ],
    [appId, openModal],
  );

  return (
    <Table
      addUrl={`/applications/${appId}/functions/new-function`}
      isPaginated={false}
      searchFields={headers}
      data={{ embedded: { entries: entries ?? [] }, size: entries?.length ?? 0 }}
      {...{ headers, isFetching, onItemClicked, actions, permissions }}
    />
  );
};

export default memo(FunctionsList);
