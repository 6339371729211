import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import GBG from '@gbg/gbgcomponentlibrary_react';
import { nanoid } from 'nanoid';

const AutoCompleteInput: React.FC<IAutoCompleteInput> = ({
  register,
  errors,
  name,
  id,
  required,
  setValue,
  val = '',
  disabled = false,
  optional = false,
  assistiveText,
  options = [],
}) => {
  useEffect(() => {
    setValue(name, val);
  }, [val]);

  const optionId: string = nanoid();

  return (
    <React.Fragment>
      <GBG.FormGroup>
        <GBG.Label>
          <div className="space__between">
            <FormattedMessage id={id} />
            {optional && (
              <span className="label--assistive">
                <FormattedMessage id="label.optional" />
              </span>
            )}
          </div>
        </GBG.Label>
        {assistiveText && (
          <GBG.Assistive>
            <FormattedMessage id={`${id}.details`} />
          </GBG.Assistive>
        )}
        <GBG.Text
          list={optionId}
          {...register(name, {
            required: required,
          })}
          error={errors[name]}
          disabled={disabled}
          autoComplete="off"
        />
        <datalist id={optionId}>
          {options?.length &&
            options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
        </datalist>
        {errors[name] && <GBG.ErrorText>{errors[name].message || 'This field is required'}</GBG.ErrorText>}
      </GBG.FormGroup>
    </React.Fragment>
  );
};

interface IAutoCompleteInput {
  register?: any;
  errors?: any;
  name: string;
  id: string;
  required: boolean;
  disabled?: boolean;
  setValue?: any;
  val?: string;
  optional: boolean;
  assistiveText?: boolean;
  extraProp?: string;
  options: { value: string; label: string }[];
}

export default AutoCompleteInput;
