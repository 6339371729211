import { memo, useCallback, useEffect, useState } from 'react';
import GBG from '@gbg/gbgcomponentlibrary_react';
import { closeModal, openModal } from '../../../../features/modal/modalSlice';
import { ModalContentType } from '../../../../features/modal/config';
import { TOAST_SUCCESS } from '../../../../constants';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/hooks';
import { useGetUseCaseQuery, useUpdateUseCaseMutation } from '../../../../api/useCase';
import { IUseCase } from '../../../../interfaces/models/IUseCase';
import { FormattedMessage, useIntl } from 'react-intl';
import UseCaseDataSources from '../../../../components/forms/fields/UseCaseDataSources';
import { useGetAllowedDataSourcesForRoleUseCaseQuery } from '../../../../api/dataSource';
import RoleUseCaseBreadcrumbs from './RoleUseCaseBreadcrumbs';

const RoleUseCaseDetailsPage = () => {
  const [selectedDataSources, setSelectedDataSources] = useState<string[]>([]);
  const { orgId, departId, roleId, useCaseId, userId, clientId } = useParams<{
    orgId: string;
    departId: string;
    userId?: string;
    roleId: string;
    clientId?: string;
    useCaseId: string;
  }>();

  const history = useHistory();
  const intl = useIntl();
  const [departmentId, setDepartmentId] = useState(departId);
  const dispatch = useAppDispatch();
  const [updateUseCase, { isLoading: isUpdating }] = useUpdateUseCaseMutation();
  const {
    data: useCaseDetails,
    isError: isErrorUC,
    error: errorUC,
  } = useGetUseCaseQuery({ id: useCaseId, organisationId: orgId });

  const {
    data: roleDataSources,
    isFetching: isFetchingDataSources,
    isError: isErrorDataSources,
    error: errorDatasources,
  } = useGetAllowedDataSourcesForRoleUseCaseQuery(
    {
      orgId,
      departId: departmentId,
      useCaseText: useCaseDetails?.gbgUseCaseText as string,
    },
    { skip: !useCaseDetails && !departmentId },
  );

  useEffect(() => {
    if (!isErrorUC && !isErrorDataSources) dispatch(closeModal());
    else
      dispatch(
        openModal({
          type: ModalContentType.API_ERROR,
          data: errorUC || errorDatasources,
        }),
      );
  }, [errorUC, errorDatasources, isErrorDataSources, dispatch]);

  useEffect(() => {
    setSelectedDataSources(useCaseDetails?.gbgDataSourceID ?? []);
  }, [useCaseDetails?.gbgDataSourceID]);

  const onSubmit = useCallback(() => {
    if (selectedDataSources.length <= 0) {
      dispatch(
        openModal({
          type: ModalContentType.API_ERROR,
          data: { message: intl.formatMessage({ id: 'dataSources.notSelectedAny' }) },
        }),
      );

      return;
    }

    const formData: IUseCase = {
      ...useCaseDetails,
      gbgUseCaseType: 'Role',
      gbgDataSourceID: selectedDataSources,
    };

    updateUseCase(formData)
      .unwrap()
      .then(() => {
        GBG.toast(TOAST_SUCCESS);
        let url = `/organisations/${orgId}`;
        if (clientId) url += `/clients/${clientId}`;
        if (departId) url += `/departments/${departId}`;
        if (userId) url += `/users/${userId}`;
        url += `/roles/${roleId}`;
        history.push(url);
      })
      .catch((errors: any) => {
        dispatch(openModal({ type: ModalContentType.API_ERROR, data: errors }));
      });
  }, [
    selectedDataSources,
    useCaseDetails,
    useCaseDetails?.gbgDataSourceID,
    orgId,
    departId,
    roleId,
    userId,
    clientId,
    dispatch,
  ]);

  return !useCaseDetails ? (
    <div className="text-center p-5">
      <GBG.Spinner data-testid="spinner" />
    </div>
  ) : (
    <>
      <RoleUseCaseBreadcrumbs
        {...{
          orgId,
          roleId,
          departmentId,
          userId,
          clientId,
          setDepartmentId,
          useCaseId,
          gbgUseCaseText: useCaseDetails.gbgUseCaseText,
        }}
      />
      <div className="m-m-t-3 full-size-input">
        <UseCaseDataSources
          id="dataSources.title"
          selectedItem={useCaseDetails}
          isFetching={isFetchingDataSources}
          items={roleDataSources?.embedded.entries ?? []}
          setSelectedDataSources={setSelectedDataSources}
        />
        <GBG.Button
          type="submit"
          className="m-m-t-2"
          kind={GBG.ButtonKind.Primary}
          worker={true}
          active={isUpdating}
          onClick={onSubmit}
        >
          <FormattedMessage id="label.update" />
        </GBG.Button>
      </div>
    </>
  );
};

export default memo(RoleUseCaseDetailsPage);
