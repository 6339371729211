import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import useTabBar from '../../../hooks/useTabBar';
import { useGetClientQuery } from '../../../api/client';
import Breadcrumb from '../../../components/layout/Breadcrumb';
import ClientUsersList from './Users/ClientUsersList';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import { ModalContentType } from '../../../features/modal/config';
import { useAppDispatch } from '../../../app/hooks';
import CreateEditClientForm from './CreateEditClientForm';
import { useGetOrganisationQuery } from '../../../api/organisations';

export const ClientPage = () => {
  const intl = useIntl();
  const { clientId, orgId } = useParams<{ clientId: string; orgId: string }>();
  const {
    data: organisation,
    isError: isErrorOrg,
    isFetching: isFetchingOrg,
    error: errorOrg,
  } = useGetOrganisationQuery(orgId);

  const dispatch = useAppDispatch();
  const tabs = useMemo<string[]>(() => ['label.details', 'user.title'].map(id => intl.formatMessage({ id })), []);

  const { render: renderTabBar, tabIndex } = useTabBar(tabs);
  const { isFetching, data: client, error, isError } = useGetClientQuery(clientId);

  useEffect(() => {
    if (!isError || !isErrorOrg) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: error ?? errorOrg }));
    }
  }, [isError, error, errorOrg, isErrorOrg]);

  return isFetching || !client || isFetchingOrg || !organisation ? (
    <div className="text-center p-5">
      <GBG.Spinner data-testid="spinner" />
    </div>
  ) : (
    <>
      <Breadcrumb params={{ [orgId]: organisation.gbgName, [clientId]: client.gbgName }} />
      <h1>{client.gbgName}</h1>
      {renderTabBar()}

      {tabIndex == 0 ? (
        <CreateEditClientForm isCreateMode={false} client={client} orgId={orgId} />
      ) : (
        <ClientUsersList clientId={client.gbgClientId} orgId={client.gbgCompanyId} />
      )}
    </>
  );
};

export default memo(ClientPage);
