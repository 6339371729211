import GBG from '@gbg/gbgcomponentlibrary_react';
import { AnyAction } from '@reduxjs/toolkit';
import { Dispatch, memo, useCallback, useEffect, useRef, useState } from 'react';

import { closeModal, openModal } from '../../../features/modal/modalSlice';
import { FormattedMessage } from 'react-intl';

import { useLazyGetUserRolesQuery } from '../../../api/roles';
import Spinner from '../../../components/common/Spinner';
import { TOAST_SUCCESS } from '../../../constants';
import { useUpdateUserMutation } from '../../../api/users';
import { ModalContentType } from '../../../features/modal/config';

interface IChangeUserDepartmentModal {
  user?: IUser;
  setUser: React.Dispatch<React.SetStateAction<IUser | undefined>>;
  dispatch: Dispatch<AnyAction>;
  departments?: IDepartment[];
}
const ChangeUserDepartmentModal: React.FC<IChangeUserDepartmentModal> = ({ user, departments, dispatch, setUser }) => {
  const [showRoles, setShowRoles] = useState<Boolean>(true);
  const [userRoles, setUserRoles] = useState<IDepartmentRole[] | null>(null);
  const [getUserRoles, { isLoading }] = useLazyGetUserRolesQuery();
  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();
  const rolesDropdownRef = useRef<HTMLSelectElement>(null);
  const [formError, setFormError] = useState<string>();
  const handleConfirm = () => {
    if (!user || !user.id) return;
    if (showRoles) {
      setShowRoles(!showRoles);
      return;
    }

    const newDepartmentId = rolesDropdownRef.current?.value;
    setFormError(newDepartmentId ? '' : 'Please select department.');
    if (!newDepartmentId) return;
    const updatedUser = { ...user, gbgDepartmentId: newDepartmentId };
    updateUser(updatedUser)
      .unwrap()
      .then(result => {
        if (result) {
          GBG.toast(TOAST_SUCCESS);
          handleClose();
        }
      })
      .catch(error => {
        dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
      });
  };

  useEffect(() => {
    if (user && user.id) {
      getUserRoles({ orgId: user.gbgCompanyId, userId: user.id, departId: user.gbgDepartmentId })
        .unwrap()
        .then(result => {
          if (result?.embedded?.entries?.length <= 0) setShowRoles(false);
          else {
            setUserRoles(result?.embedded?.entries);
            setShowRoles(true);
          }
        });
    }
  }, [user?.id, user?.gbgCompanyId, user?.gbgDepartmentId, setUserRoles, setShowRoles]);

  const handleClose = useCallback(() => {
    setUser(undefined);
    setShowRoles(true);
    dispatch(closeModal());
  }, [setUser]);
  return (
    <GBG.Modal style={{ zIndex: 9 }} isVisible={user} className="modal-lg">
      <GBG.ModalHeader>
        <GBG.AlertBar data-testid="alertBar" className="alert-bar--info" icon={GBG.IconKeys.Info}>
          <h5>Moving User Department</h5>
        </GBG.AlertBar>
      </GBG.ModalHeader>

      <GBG.ModalBody data-testid="modal-body">
        {isLoading ? (
          <Spinner />
        ) : showRoles && userRoles ? (
          <>
            <p>
              By moving user department, the following user roles will be removed, Are you sure you want to proceed?
            </p>
            <ul className="user-roles-list">
              {userRoles.map(role => (
                <li>{role.gbgName}</li>
              ))}
            </ul>
          </>
        ) : (
          <div className="form-group">
            <label className="m-m-b-2">Please select the department:</label>
            <select
              className="dropdown__input form-control"
              ref={rolesDropdownRef}
              data-testid="move-to-departments-select"
            >
              {departments
                ?.filter(x => x.gbgDeptId !== user?.gbgDepartmentId)
                .map((department: IDepartment) => (
                  <option value={department.gbgDeptId}>{department.gbgName}</option>
                ))}
            </select>
            <div className="invalid-feedback">{formError}</div>
          </div>
        )}
      </GBG.ModalBody>

      <div className="modal-footer">
        <div className="button-group col-md-12">
          <GBG.Button
            kind={GBG.ButtonKind.Secondary}
            className="m-m-t-2 m-m-r-1"
            onClick={handleClose}
            data-testid="cancel"
          >
            <FormattedMessage id="btn.cancel" />
          </GBG.Button>

          <GBG.Button
            worker={true}
            active={isUpdating || isLoading}
            kind={GBG.ButtonKind.Destructive}
            onClick={handleConfirm}
            data-testid="remove"
          >
            {showRoles ? 'Proceed' : 'Save'}
          </GBG.Button>
        </div>
      </div>
    </GBG.Modal>
  );
};

export default memo(ChangeUserDepartmentModal);
