import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import GBG from '@gbg/gbgcomponentlibrary_react';
import { DEPARTMENT_USECASE_FORM_FIELDS } from '../../../../constants/formData';
import Form from '../../../../components/forms/Form';
import { useForm, useWatch } from 'react-hook-form';
import { closeModal, openModal } from '../../../../features/modal/modalSlice';
import { ModalContentType } from '../../../../features/modal/config';
import { TOAST_SUCCESS } from '../../../../constants';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/hooks';
import { useAddUseCaseMutation, useGetAvailableUseCasesQuery } from '../../../../api/useCase';
import { IUseCase } from '../../../../interfaces/models/IUseCase';
import _ from 'lodash';
import UseCaseDataSources from '../../../../components/forms/fields/UseCaseDataSources';
import { useGetAllowedDataSourcesForRoleUseCaseQuery } from '../../../../api/dataSource';
import { useIntl } from 'react-intl';
import RoleUseCaseBreadcrumbs from './RoleUseCaseBreadcrumbs';

const NewRoleUseCasePage = () => {
  const [selectedDataSources, setSelectedDataSources] = useState<string[]>([]);
  const { orgId, departId, roleId, userId, clientId } = useParams<{
    orgId: string;
    roleId: string;
    departId: string;
    userId?: string;
    clientId?: string;
  }>();
  const [departmentId, setDepartmentId] = useState(departId);
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [addUseCase, { isLoading: isAdding }] = useAddUseCaseMutation();
  const {
    data: availableUseCases,
    isError: isErrorTexts,
    error: errorTexts,
  } = useGetAvailableUseCasesQuery(
    { gbgCompanyId: orgId, gbgDepartmentId: departmentId ?? '', gbgRoleId: roleId },
    { skip: !departmentId },
  );

  const fields = useMemo<IFormField[]>(() => {
    const formFields = _.cloneDeep(DEPARTMENT_USECASE_FORM_FIELDS);
    const useCaseTextsField = formFields.filter(x => x.inputName === 'gbgUseCaseText')[0];
    useCaseTextsField.options = [{ label: 'Please Select', value: '' }];
    availableUseCases?.embedded.entries.map(x =>
      useCaseTextsField.options?.push({
        label: x.gbgUseCaseText ?? '',
        value: x.gbgUseCaseText ?? '',
      }),
    );

    return formFields;
  }, [availableUseCases?.embedded.entries]);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const selectedUseCaseText: string = useWatch({ control, name: 'gbgUseCaseText' });
  const selectedUseCaseDetails = useMemo(() => {
    if (availableUseCases)
      return availableUseCases.embedded.entries.filter(x => x.gbgUseCaseText === selectedUseCaseText)[0];
  }, [selectedUseCaseText, availableUseCases?.embedded.entries]);

  const {
    data: items,
    isFetching: isFetchingDataSources,
    isError: isErrorDataSources,
    error: errorDatasources,
  } = useGetAllowedDataSourcesForRoleUseCaseQuery(
    {
      orgId,
      departId: departmentId,
      useCaseText: selectedUseCaseDetails?.gbgUseCaseText as string,
    },
    { skip: !selectedUseCaseDetails || !departmentId },
  );

  useEffect(() => {
    if (!isErrorTexts && !isErrorDataSources) dispatch(closeModal());
    else
      dispatch(
        openModal({
          type: ModalContentType.API_ERROR,
          data: errorTexts || errorDatasources,
        }),
      );
  }, [errorTexts, errorDatasources, isErrorDataSources, dispatch]);

  useEffect(() => {
    setSelectedDataSources(selectedUseCaseDetails?.gbgDataSourceID ?? []);
  }, [selectedUseCaseDetails?.gbgDataSourceID, setSelectedDataSources]);

  const onSubmit = useCallback(
    handleSubmit(() => {
      if (selectedDataSources.length <= 0) {
        dispatch(
          openModal({
            type: ModalContentType.API_ERROR,
            data: { message: intl.formatMessage({ id: 'dataSources.notSelectedAny' }) },
          }),
        );

        return;
      }

      const formData: IUseCase = {
        ...selectedUseCaseDetails,
        gbgCompanyId: orgId,
        GbgDepartmentId: departmentId ?? '',
        GbgRoleId: roleId,
        gbgUseCaseType: 'Role',
        gbgDataSourceID: selectedDataSources,
      };

      addUseCase(formData)
        .unwrap()
        .then(() => {
          GBG.toast(TOAST_SUCCESS);
          let url = `/organisations/${orgId}`;
          if (clientId) url += `/clients/${clientId}`;
          if (departId) url += `/departments/${departId}`;
          if (userId) url += `/users/${userId}`;
          url += `/roles/${roleId}`;
          history.push(url);
        })
        .catch((errors: any) => {
          dispatch(openModal({ type: ModalContentType.API_ERROR, data: errors }));
        });
    }),
    [
      handleSubmit,
      selectedDataSources,
      selectedUseCaseDetails,
      orgId,
      roleId,
      departId,
      clientId,
      userId,
      departmentId,
      dispatch,
    ],
  );

  return (
    <>
      <RoleUseCaseBreadcrumbs
        {...{
          orgId,
          roleId,
          departmentId,
          userId,
          clientId,
          setDepartmentId,
        }}
      />
      {availableUseCases ? (
        <Form
          {...{
            onSubmit,
            data: {},
            register,
            errors,
            setValue,
            isBusy: isAdding,
            isNew: true,
            fields,
          }}
        >
          <div className="m-m-t-3 full-size-input">
            <UseCaseDataSources
              id="dataSources.title"
              selectedItem={selectedUseCaseDetails}
              items={items?.embedded.entries ?? []}
              isFetching={isFetchingDataSources}
              setSelectedDataSources={setSelectedDataSources}
            />
          </div>
        </Form>
      ) : (
        <div className="text-center p-5">
          <GBG.Spinner data-testid="spinner" />
        </div>
      )}
    </>
  );
};

export default memo(NewRoleUseCasePage);
