import { memo, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import GBG from '@gbg/gbgcomponentlibrary_react';
import { useAddClientUserMutation } from '../../../../api/users';
import Form from '../../../../components/forms/Form';
import { TOAST_SUCCESS } from '../../../../constants';
import { CLIENT_USER_FIELDS } from '../../../../constants/formData';
import { useAppDispatch } from '../../../../app/hooks';
import { useHistory, useParams } from 'react-router-dom';
import { closeModal, openModal } from '../../../../features/modal/modalSlice';
import { ModalContentType } from '../../../../features/modal/config';
import Breadcrumb from '../../../../components/layout/Breadcrumb';
import { useGetOrganisationQuery } from '../../../../api/organisations';
import { useGetClientQuery } from '../../../../api/client';
import { ClientUserTabs } from '../../../../constants/tabs';

const NewClientUser = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { orgId, clientId } = useParams<{ orgId: string; clientId: string }>();

  const {
    data: organisation,
    isFetching: isOrgFetching,
    isError: isOrgError,
    error: orgError,
  } = useGetOrganisationQuery(orgId);
  const {
    data: client,
    isFetching: isClientFetching,
    isError: isClientError,
    error: clientError,
  } = useGetClientQuery(clientId);
  const [addClientUser, { isLoading }] = useAddClientUserMutation();

  useEffect(() => {
    if (!isClientError || !isOrgError) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: orgError || clientError }));
    }
  }, [isOrgError, orgError, isClientError, clientError]);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = useCallback(
    handleSubmit(async (data: IUser) => {
      try {
        await addClientUser({ ...data, gbgClientId: clientId, gbgCompanyId: orgId }).unwrap();
        GBG.toast(TOAST_SUCCESS);
        history.push(`/organisations/${orgId}/clients/${clientId}?tab=${ClientUserTabs.USERS}`);
      } catch (err) {
        dispatch(openModal({ type: ModalContentType.API_ERROR, data: err }));
      }
    }),
    [handleSubmit, clientId, orgId],
  );

  return (
    <>
      {isOrgFetching || isClientFetching || !organisation || !client ? (
        <GBG.Spinner data-testid="spinner" />
      ) : (
        <Breadcrumb params={{ [orgId]: organisation.gbgName, [clientId]: client.gbgName }} />
      )}

      <FormattedMessage id="users.new" tagName="h1" />
      <Form
        {...{
          onSubmit,
          fields: CLIENT_USER_FIELDS,
          register,
          errors,
          setValue,
          isBusy: isLoading,
          isNew: true,
        }}
      />
    </>
  );
};

export default memo(NewClientUser);
