import { memo, useCallback } from 'react';

import { useDeleteApplicationMutation } from '../../../api/applications';
import RemoveModalContent from './RemoveModalContent';

const RemoveApplication = ({ application }: { application: IApplication }) => {
  const [deleteApplication, { isLoading: isDeleting }] = useDeleteApplicationMutation();

  const handleRemove = useCallback(
    () => deleteApplication(application.gbgApplicationId).unwrap(),
    [application?.gbgApplicationId],
  );

  return <RemoveModalContent name={application.gbgName} handleRemove={handleRemove} isDeleting={isDeleting} />;
};

export default memo(RemoveApplication);
