import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getModalMessages } from '../constants/modal';
import { IModal } from '../interfaces/IModal';
import { IStore } from '../interfaces/IStore';
import { setModalData } from '../store/actions/generalActions';

const useModalData = () => {
  const MODAL_MESSAGES = getModalMessages();
  const selectedItemType = useSelector((state: IStore) => state.general.type);
  const isVisible = useSelector((state: IStore) => state.general.isVisible);
  const selectedItemIndex = useSelector((state: IStore) => state.general.index);
  const selectedModalTitle = useSelector((state: IStore) => state.general.title);
  const selectedModalSubtitle = useSelector((state: IStore) => state.general.subtitle);
  const selectedItemMessage = useSelector((state: IStore) => state.general.message);
  const selectedButtons = useSelector((state: IStore) => state.general.buttons);
  const selectedItem: any = useSelector((state: IStore) => state.organisation.selectedItem);
  const { title, subtitle, buttons } =
    MODAL_MESSAGES[selectedItemType.toLocaleLowerCase().replace(' ', '') as keyof IModal] ||
    MODAL_MESSAGES['errorData'];
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(
        setModalData({
          index: -1,
          type: '',
          message: '',
        }),
      );
    };
  }, []);

  return {
    selectedItemIndex,
    selectedItemName: selectedItem.gbgName,
    title: selectedModalTitle || title,
    subtitle: selectedModalSubtitle || subtitle,
    buttons: selectedButtons || buttons,
    selectedItemMessage,
    isVisible,
    username: selectedItem.username,
    type: selectedItemType,
  };
};

export default useModalData;
