import OrganisationsListPage from '../pages/Organisations/OrganisationsListPage';
import NewOrganisationPage from '../pages/Organisations/NewOrganisationPage';
import OrganisationPage from '../pages/Organisations/OrganisationPage';
import ApplicationsListPage from '../pages/Applications/ApplicationsListPage';
import NewApplicationPage from '../pages/Applications/NewApplicationPage';
import ApplicationPage from '../pages/Applications/ApplicationPage';
import NewFunctionPage from '../pages/Applications/Functions/NewFunctionPage';
import FunctionPage from '../pages/Applications/Functions/FunctionPage';
import NewDepartmentPage from '../pages/Organisations/Departments/NewDepartmentPage';
import DepartmentPage from '../pages/Organisations/Departments/DepartmentPage';
import DepartmentUserPage from '../pages/Organisations/Departments/Users/DepartmentUserPage';
import NewDepartmentUserPage from '../pages/Organisations/Departments/Users/NewDepartmentUserPage';
import DataSourceDetailPage from '../pages/DataSources/DataSourceDetailPage';
import DataSourcesListPage from '../pages/DataSources/DataSourcesListPage';
import DepartmentUseCaseDetailsPage from '../pages/Organisations/Departments/Use Case/DepartmentUseCaseDetailsPage';
import ErrorPage from '../pages/ErrorPage';
import NewDepartmentUseCasePage from '../pages/Organisations/Departments/Use Case/NewDepartmentUseCasePage';
import NewLicencePage from '../pages/Organisations/Licences/NewLicencePage';
import NewLicencePageDetails from '../pages/Organisations/Licences/NewLicencePageDetails';
import NewUseCasePage from '../pages/Organisations/UseCases/NewUseCasePage';
import NewRolePage from '../pages/Organisations/Departments/Roles/NewRolePage';
import NewUser from '../pages/Organisations/Users/NewUser';
import UserPage from '../pages/Organisations/Users/UserPage';
import ImportDepartmentUseCasePage from '../pages/Organisations/Departments/Use Case/ImportDepartmentUseCase';
import NewRoleUseCasePage from '../pages/Organisations/Departments/Roles/NewRoleUseCasePage';
import AddDepartmentRoleFeaturePage from '../pages/Organisations/Departments/Roles/AddDepartmentRoleFeaturePage';
import RoleUseCaseDetailsPage from '../pages/Organisations/Departments/Roles/RoleUseCaseDetailsPage';
import LicenceDetailsPage from '../pages/Organisations/Licences/LicenceDetailsPage';
import NewClient from '../pages/Organisations/Clients/NewClient';
import ClientPage from '../pages/Organisations/Clients/ClientPage';
import NewClientUser from '../pages/Organisations/Clients/Users/NewClientUser';
import NewDataSourcePage from '../pages/DataSources/NewDataSourcePage';
import RoleDetailsPage from '../pages/Organisations/Departments/Roles/RoleDetailsPage';
import OrganisationUseCaseDetails from '../pages/Organisations/UseCases/OrganisationUseCaseDetails';
import UserRoleDetailsPage from '../pages/Organisations/Users/UserRoleDetailsPage';
import AddUserRolePage from '../pages/Organisations/Users/AddUserRolePage';
import AddDepartmentUserRolePage from '../pages/Organisations/Departments/Users/AddDepartmentUserRolePage';
import AddUserRoleFeatures from '../pages/Organisations/Users/AddUserRoleFeatures';
import DepartmentUserRoleDetailsPage from '../pages/Organisations/Departments/Users/DepartmentUserRoleDetailsPage';
const departmentUserRoleBaseUrl = '/organisations/:orgId/departments/:departId/users/:userId';
const clientUserRoleBaseUrl = '/organisations/:orgId/clients/:clientId/users/:userId/roles';
import SecretClient from '../pages/Organisations/Clients/SecretClient';

interface IRoute {
  text: string;
  route: string;
  component: React.FC;
  exact: boolean;
  isInMenu: boolean;
}

export const routes: IRoute[] = [
  // Organisations
  {
    text: 'Organisations',
    route: '/organisations',
    component: OrganisationsListPage,
    exact: true,
    isInMenu: true,
  },
  {
    text: 'New Organisation',
    route: '/organisations/new-organisation',
    component: NewOrganisationPage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Organisation',
    route: '/organisations/:id',
    component: OrganisationPage,
    exact: true,
    isInMenu: false,
  },
  // Applications
  {
    text: 'Applications',
    route: '/applications',
    component: ApplicationsListPage,
    exact: true,
    isInMenu: true,
  },
  {
    text: 'New Application',
    route: '/applications/new-application',
    component: NewApplicationPage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Application',
    route: '/applications/:id',
    component: ApplicationPage,
    exact: true,
    isInMenu: false,
  },
  // Functions
  {
    text: 'New Function',
    route: '/applications/:appId/functions/new-function',
    component: NewFunctionPage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Function',
    route: '/applications/:appId/functions/:id',
    component: FunctionPage,
    exact: true,
    isInMenu: false,
  },
  // Departments
  {
    text: 'New Department',
    route: '/organisations/:orgId/departments/new-department',
    component: NewDepartmentPage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Department',
    route: '/organisations/:orgId/departments/:departId',
    component: DepartmentPage,
    exact: true,
    isInMenu: false,
  },
  // Department Users
  {
    text: 'New Department User',
    route: '/organisations/:orgId/departments/:departId/users/new-user',
    component: NewDepartmentUserPage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Department User',
    route: departmentUserRoleBaseUrl,
    component: DepartmentUserPage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'New Client',
    route: '/organisations/:orgId/clients/new-client',
    component: NewClient,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Regenerated Client Secret',
    route: '/organisations/:orgId/clients/secret-client',
    component: SecretClient,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Edit Client',
    route: '/organisations/:orgId/Clients/:clientId',
    component: ClientPage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'New Client User',
    route: '/organisations/:orgId/clients/:clientId/new-user',
    component: NewClientUser,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'New User',
    route: '/organisations/:orgId/users/new-user',
    component: NewUser,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Organisation User Page',
    route: '/organisations/:orgId/users/:userId',
    component: UserPage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Organisation Client User Page',
    route: '/organisations/:orgId/clients/:clientId/users/:userId',
    component: UserPage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Add Department Role Feature',
    route: '/organisations/:orgId/departments/:departId/roles/:roleId/add-permission',
    component: AddDepartmentRoleFeaturePage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Add Department User Role',
    route: `${departmentUserRoleBaseUrl}/roles/add-role`,
    component: AddDepartmentUserRolePage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Department User Role Details',
    route: `${departmentUserRoleBaseUrl}/roles/:roleId`,
    component: DepartmentUserRoleDetailsPage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Add Department User Role Feature',
    route: `${departmentUserRoleBaseUrl}/roles/:roleId/add-permission`,
    component: AddDepartmentRoleFeaturePage, // to Do
    exact: true,
    isInMenu: false,
  },
  {
    text: 'New Org Role Feature',
    route: '/organisations/:orgId/users/:userId/roles/:roleId/add-permission',
    component: AddUserRoleFeatures,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'New Client User Role Feature',
    route: `${clientUserRoleBaseUrl}/:roleId/add-permission`,
    component: AddUserRoleFeatures,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Import Use Case',
    route: '/organisations/:orgId/departments/:departId/usecases/add-use-case',
    component: ImportDepartmentUseCasePage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'New Role Page',
    route: '/organisations/:orgId/departments/:departId/roles/new-role',
    component: NewRolePage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Edit Department Role',
    route: '/organisations/:orgId/departments/:departId/roles/:roleId',
    component: RoleDetailsPage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Add Organisation User Role',
    route: '/organisations/:orgId/users/:userId/roles/add-role',
    component: AddUserRolePage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Edit Organisation User Role',
    route: '/organisations/:orgId/users/:userId/roles/:roleId',
    component: UserRoleDetailsPage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Add Client User Role',
    route: `${clientUserRoleBaseUrl}/add-role`,
    component: AddUserRolePage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Edit Client User Role',
    route: `${clientUserRoleBaseUrl}/:roleId`,
    component: UserRoleDetailsPage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'New Organisation Use Case',
    route: '/organisations/:orgId/usecases/new-use-case',
    component: NewUseCasePage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Edit Organisation Use Case',
    route: '/organisations/:orgId/usecases/:useCaseId',
    component: OrganisationUseCaseDetails,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'New Organisation User Role Use Case',
    route: '/organisations/:orgId/users/:userId/roles/:roleId/usecases/new-use-case',
    component: NewRoleUseCasePage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Edit Organisation User Role Use Case',
    route: '/organisations/:orgId/users/:userId/roles/:roleId/usecases/:useCaseId',
    component: RoleUseCaseDetailsPage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'New Organisation Client User Role Use Case',
    route: `${clientUserRoleBaseUrl}/:roleId/usecases/new-use-case`,
    component: NewRoleUseCasePage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Edit Organisation Client User Role Use Case',
    route: `${clientUserRoleBaseUrl}/:roleId/usecases/:useCaseId`,
    component: RoleUseCaseDetailsPage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'New Department Use Case',
    route: '/organisations/:orgId/departments/:departId/usecases/new-use-case',
    component: NewDepartmentUseCasePage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Edit Department Use Case',
    route: '/organisations/:orgId/departments/:departId/usecases/:useCaseId',
    component: DepartmentUseCaseDetailsPage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'New Department Role Use Case',
    route: '/organisations/:orgId/departments/:departId/roles/:roleId/new-use-case',
    component: NewRoleUseCasePage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Edit Department Role Use Case',
    route: '/organisations/:orgId/departments/:departId/roles/:roleId/:useCaseId',
    component: RoleUseCaseDetailsPage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'New Department User Role Use Case Page',
    route: `${departmentUserRoleBaseUrl}/roles/:roleId/new-use-case`,
    component: NewRoleUseCasePage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Edit Department User Role Use Case Page',
    route: `${departmentUserRoleBaseUrl}/roles/:roleId/:useCaseId`,
    component: RoleUseCaseDetailsPage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Data Sources',
    route: '/data-sources',
    component: DataSourcesListPage,
    exact: true,
    isInMenu: true,
  },
  {
    text: 'New Data Source',
    route: '/data-sources/new-data-source',
    component: NewDataSourcePage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Data Source Details',
    route: '/data-sources/:id',
    component: DataSourceDetailPage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'New Organisation Licence Page',
    route: '/organisations/:orgId/Licences/new-licence',
    component: NewLicencePage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'New Department Licence Page',
    route: '/organisations/:orgId/Departments/:departId/Licences/new-licence',
    component: NewLicencePage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'New Organisation Licence Details',
    route: '/organisations/:orgId/licences/new-licence-details',
    component: NewLicencePageDetails,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'New Department Licence Details',
    route: '/organisations/:orgId/departments/:departId/licences/new-licence-details',
    component: NewLicencePageDetails,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Department Licence Details Page',
    route: '/organisations/:orgId/Departments/:departId/Licences/:licenceId',
    component: LicenceDetailsPage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Organisation Licence Details Page',
    route: '/organisations/:orgId/Licences/:licenceId',
    component: LicenceDetailsPage,
    exact: true,
    isInMenu: false,
  },
  {
    text: 'Error Page',
    route: '/error/:id',
    component: ErrorPage,
    exact: true,
    isInMenu: false,
  },
];

export const ROUTES_URL = {
  ORGANISATION: '/organisation',
  DEPARTMENT: '/department',
  APPLICATION: '/application',
  DATASOURCE: '/datasource',
  FUNCTION: '/function',
  LICENCE: '/licence',
  CLIENT: '/client',
};
