import config from './config';

export const authorize = async () => {
  const signOnUrl = `${config.LOGIN_BASE_URL()}/login?&redirect_url=${encodeURIComponent(config.redirectUri())}`;
  window.location.replace(signOnUrl);
};

export const signOff = async () => {
  //TODO - this needs testing in federated environments
  const url = `${config.LOGOUT_BASE_URL()}/as/signoff`;
  window.location.assign(url);
};

export const parseHash = (): any => {
  if (window.location.hash == '') return null;
  return window.location.hash
    .replace('#', '')
    .split('&')
    .reduce((prev, item) => {
      return Object.assign({ [item.split('=')[0]]: decodeURIComponent(item.split('=')[1]) }, prev);
    }, {});
};

export const removeHash = () => {
  history.pushState('', document.title, window.location.pathname + window.location.search);
};

export default {};
