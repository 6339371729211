import { memo, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import GBG from '@gbg/gbgcomponentlibrary_react';
import Modal from 'react-modal';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { ModalContentType } from '../../../features/modal/config';
import { useAppDispatch } from '../../../app/hooks';
import { generateStrongPassword } from '../../../utils/password';
import { getToastSuccess } from '../../../utils/getToastOptions';
import { useResetPasswordMutation } from '../../../api/users';
import { openModal } from '../../../features/modal/modalSlice';

interface ITempPasswordModal {
  user?: IUser;
  onClose: () => void;
}

const TempPasswordModal: React.FC<ITempPasswordModal> = ({ user, onClose }) => {
  const { messages } = useIntl();
  const dispatch = useAppDispatch();
  const [password, setPassword] = useState<string>('');
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [resetPassword, { isLoading: isPatching }] = useResetPasswordMutation();

  useEffect(() => {
    if (user) {
      setPassword(generateStrongPassword());
    }
  }, [user, setPassword]);

  const handleRegenerate = useCallback(() => {
    setPassword(generateStrongPassword());
  }, [setPassword]);

  const handlePasswordChange = useCallback(
    (ev: any) => {
      setPassword(ev.target.value);
    },
    [setPassword],
  );

  const handleClose = useCallback(() => {
    onClose();
    if (isConfirm) setIsConfirm(false);
  }, [isConfirm, onClose, setIsConfirm]);

  const handleResetPassword = useCallback(async () => {
    try {
      if (!user?.id) return;
      await resetPassword({ userId: user.id, newPassword: password, orgId: user.gbgCompanyId }).unwrap();
      const toastOption = getToastSuccess();
      toastOption.content = 'Password has been reset successfully';
      GBG.toast(toastOption);
      setIsConfirm(true);
    } catch (err) {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: err }));
    }
  }, [resetPassword, user?.id, password, setIsConfirm, dispatch, openModal]);

  return !user ? null : (
    <Modal isOpen={true} className="modal modal-enter-done" ariaHideApp={false}>
      <div className="modal__overlay">
        <div className="modal__content">
          <div className="modal__header bg-basic">
            <div className="alert-bar">
              <div className="alert-bar__text m-m-r-8">
                <h1 className="alert-bar__title">{messages[`password.modal.${isConfirm ? 'confirm.' : ''}title`]}</h1>
                <p className="alert-bar__text">
                  {isConfirm ? (
                    <FormattedMessage
                      id="password.modal.confirm.subtitle"
                      values={{ firstName: user.userGivenName }}
                    ></FormattedMessage>
                  ) : (
                    messages['password.modal.subtitle']
                  )}
                </p>
              </div>
              <i className="icon alert-bar__icon m-0" onClick={handleClose} role="button">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L15 15M15 1L1 15" stroke="#000D1A" strokeWidth="2" strokeLinecap="round" />
                </svg>
              </i>
            </div>
          </div>

          <div className="modal__body">
            <GBG.FormGroup>
              <p className="bold">{messages['label.password']} *</p>
              <div className="d-flex align-items-baseline">
                <GBG.Text
                  className="flex-grow-1"
                  disabled={isConfirm}
                  value={password}
                  onChange={handlePasswordChange}
                />
                <CopyToClipboard text={password}>
                  <GBG.Button className="m-m-l-1">{messages['label.copy']}</GBG.Button>
                </CopyToClipboard>
              </div>
            </GBG.FormGroup>

            {!isConfirm && (
              <>
                <a href="#" className="d-block m-m-t-1 m-m-b-2" onClick={handleRegenerate}>
                  {messages['password.regenerate']}
                </a>
                <p className="m-m-t-1">
                  <FormattedMessage
                    id="password.modal.info"
                    values={{
                      userName: (
                        <b>
                          "{user.userGivenName} {user.userFamilyName}"
                        </b>
                      ),
                      firstName: user.userGivenName,
                    }}
                  ></FormattedMessage>
                </p>
              </>
            )}
          </div>

          <div className="modal__footer">
            <GBG.Button
              className="m-m-b-2 m-m-l-2 m-m-t-2"
              onClick={handleClose}
              kind={GBG.ButtonKind.Secondary}
              data-testid="temp-pass-cancel-btn"
            >
              {isConfirm ? messages['label.done'] : messages['btn.cancel']}
            </GBG.Button>
            {!isConfirm && (
              <GBG.Button
                className="m-m-b-2 m-m-l-1"
                onClick={handleResetPassword}
                worker={true}
                active={isPatching}
                disabled={isPatching}
              >
                {messages['password.set']}
              </GBG.Button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default memo(TempPasswordModal);
