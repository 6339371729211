import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetOrganisationQuery } from '../../../../api/organisations';
import { useGetRoleQuery } from '../../../../api/roles';
import { useGetUserQuery } from '../../../../api/users';
import { useAppDispatch } from '../../../../app/hooks';
import Breadcrumb from '../../../../components/layout/Breadcrumb';
import { ModalContentType } from '../../../../features/modal/config';
import { closeModal, openModal } from '../../../../features/modal/modalSlice';
import RolesFeatures from '../Roles/RolesFeatures';
import RolesUseCases from '../Roles/RolesUseCases';

export const DepartmentUserRoleDetailsPage = () => {
  const dispatch = useAppDispatch();
  const { orgId, departId, roleId, userId } = useParams<{
    orgId: string;
    departId: string;
    roleId: string;
    userId: string;
  }>();
  const baseUrl = `/organisations/${orgId}/departments/${departId}/users/${userId}/roles/${roleId}`;
  const { data: organisation, isError: isErrorOrg, error: errorOrg } = useGetOrganisationQuery(orgId);
  const { data: user, isError: isErrorUser, error: errorUser } = useGetUserQuery({ userId, orgId });
  const { data: departmentRole, isError: isErrorDepRole, error: errorDepRole } = useGetRoleQuery({ orgId, roleId });

  useEffect(() => {
    if (!isErrorOrg && !isErrorUser && !isErrorDepRole) dispatch(closeModal());
    else dispatch(openModal({ type: ModalContentType.API_ERROR, data: errorOrg || errorUser || errorDepRole }));
  }, [isErrorUser, isErrorOrg, isErrorDepRole, errorUser, errorOrg, errorDepRole, dispatch]);

  return !organisation || !user || !departmentRole ? (
    <div className="text-center p-5">
      <GBG.Spinner data-testid="spinner" />
    </div>
  ) : (
    <>
      <Breadcrumb
        params={{
          [orgId]: organisation.gbgName,
          [departId]: user.gbgDepartmentName,
          [userId]: user.username,
          [roleId]: departmentRole.gbgName,
        }}
      />
      <h1>{departmentRole.gbgName}</h1>
      <div className="m-m-t-2">
        <RolesFeatures role={departmentRole} addUrl={`${baseUrl}/add-permission`} />
      </div>
      <div className="m-m-t-2">
        <RolesUseCases departId={departId} orgId={orgId} roleId={roleId} baseCreateEditPageURL={baseUrl} />
      </div>
    </>
  );
};
export default memo(DepartmentUserRoleDetailsPage);
