import GBG from '@gbg/gbgcomponentlibrary_react';
import { nanoid } from '@reduxjs/toolkit';
import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch } from '../../../app/hooks';
import { closeModal } from '../../../features/modal/modalSlice';

const ApiError: React.FC<{ error: IApiError }> = ({ error }) => {
  const dispatch = useAppDispatch();
  const { message, title, detail, details, errors } = error;

  const handleClose = useCallback(() => dispatch(closeModal()), []);

  return (
    <>
      {message && (
        <p data-testid="message" dangerouslySetInnerHTML={{ __html: message.replace(/\n/g, '<br><br>') }}></p>
      )}
      {title && <p data-testid="title" dangerouslySetInnerHTML={{ __html: title.replace(/\n/g, '<br><br>') }}></p>}
      {detail && <p data-testid="detail" dangerouslySetInnerHTML={{ __html: detail.replace(/\n/g, '<br><br>') }}></p>}

      {details?.map(item => (
        <p
          key={nanoid()}
          data-testid="details"
          dangerouslySetInnerHTML={{ __html: item.message.replace(/\n/g, '<br><br>') }}
        ></p>
      ))}

      {errors &&
        Object.keys(errors)?.map(key => (
          <div key={nanoid()} data-testid="errors">
            <strong>- {key}</strong>
            {errors[key]?.map(message => (
              <p key={nanoid()} dangerouslySetInnerHTML={{ __html: message.replace(/\n/g, '<br><br>') }}></p>
            ))}
          </div>
        ))}

      <GBG.Button kind={GBG.ButtonKind.Secondary} className="m-m-t-2 m-m-r-1" onClick={handleClose} data-testid="close">
        <FormattedMessage id="btn.close" />
      </GBG.Button>
    </>
  );
};

export default memo(ApiError);
