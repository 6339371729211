import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useCallback, useEffect, useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useGetAllDataSourcesQuery } from '../../../api/dataSource';
import { useGetOrganisationQuery } from '../../../api/organisations';
import {
  useGetUseCaseQuery,
  useLazyGetAffectedItemsOnOrganisationUseCaseUpdateQuery,
  useUpdateUseCaseMutation,
  useGetUseCaseDataSourcesQuery,
} from '../../../api/useCase';
import { useAppDispatch } from '../../../app/hooks';
import { getCustomerViewPermissionScopes } from '../../../auth/customerViewAccess';
import UseCaseDataSources from '../../../components/forms/fields/UseCaseDataSources';
import Textarea from '../../../components/forms/fields/Textarea';
import TextInput from '../../../components/forms/fields/TextInput';
import Breadcrumb from '../../../components/layout/Breadcrumb';
import { ModalContentType } from '../../../features/modal/config';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import useFlowdownUpdate from '../../../hooks/useFlowdownUpdate';
import { IUseCase } from '../../../interfaces/models/IUseCase';
import { IDatasource } from '../../../interfaces/models/IDatasource';
import { OrganisationTabs } from '../../../constants/tabs';
import { SCOPES } from '../../../auth/permissions';
import PermissionGate from '../../../auth/PermissionGate';

const OrganisationUseCaseDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const intl = useIntl();
  const { orgId, useCaseId } = useParams<{ orgId: string; useCaseId: string }>();
  const {
    data: useCaseDetails,
    isFetching,
    isError,
    error,
  } = useGetUseCaseQuery({ organisationId: orgId, id: useCaseId });
  const [getAffectedDetails] = useLazyGetAffectedItemsOnOrganisationUseCaseUpdateQuery();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [updateUseCase, { isLoading: isUpdatingUseCase }] = useUpdateUseCaseMutation();
  const { permissionScope } = getCustomerViewPermissionScopes();

  const [selectedDataSourcesArr, setSelectedDataSourcesArr] = useState(
    useCaseDetails?.gbgDataSourceID?.length ? useCaseDetails.gbgDataSourceID : [],
  );
  const {
    data: organisationDetails,
    isFetching: organisationIsFetching,
    isError: organisationHasError,
    error: organisationErrorMessage,
  } = useGetOrganisationQuery(orgId);

  const {
    data: orgDataSources,
    isFetching: isFetchingDataSources,
    isError: isErrorDataSources,
    error: errorDatasources,
  } = permissionScope === SCOPES.FullAccess
    ? useGetAllDataSourcesQuery()
    : useGetUseCaseDataSourcesQuery({ id: useCaseId, organisationId: orgId });

  useEffect(() => {
    setSelectedDataSourcesArr(useCaseDetails?.gbgDataSourceID?.length ? useCaseDetails.gbgDataSourceID : []);
  }, [useCaseDetails?.gbgDataSourceID, setSelectedDataSourcesArr]);

  const refreshDatasource = () => {
    setIsRefreshing(true);
    setSelectedDataSourcesArr(useCaseDetails?.gbgDataSourceID?.length ? useCaseDetails.gbgDataSourceID : []);
  };

  const getUpdatingObjects = useCallback(() => {
    const data = {
      ...useCaseDetails,
      gbgDataSourceID: selectedDataSourcesArr,
      ...getValues(),
    } as IUseCase;
    return { data };
  }, [useCaseDetails, selectedDataSourcesArr, useCaseId]);

  const { isUpdating, setIsUpdating, handleUpdate, flowdownCallback, createFlowDownModal, errorCallback } =
    useFlowdownUpdate({
      orgId,
      resourceName: useCaseDetails?.gbgUseCaseText ?? '',
      resourceTabId: OrganisationTabs.USECASES,
      getUpdatingObjects,
      submitFunction: updateUseCase,
      handleCancel: refreshDatasource,
    });

  const onUpdate = useCallback(() => {
    if (selectedDataSourcesArr.length <= 0) {
      dispatch(
        openModal({
          type: ModalContentType.API_ERROR,
          data: { message: intl.formatMessage({ id: 'dataSources.notSelectedAny' }) },
        }),
      );

      return;
    }

    if (!useCaseDetails?.gbgDataSourceID?.length) return handleUpdate();

    const removedDataSourceIDs = useCaseDetails.gbgDataSourceID.filter(
      (dataSourceId: string) => selectedDataSourcesArr.indexOf(dataSourceId) < 0,
    );
    if (!removedDataSourceIDs.length) return handleUpdate();
    setIsUpdating(true);
    getAffectedDetails({
      orgId,
      useCaseText: useCaseDetails.gbgUseCaseText,
      removedIDs: removedDataSourceIDs.join(','),
    })
      .unwrap()
      .then(res => {
        flowdownCallback(res);
      })
      .catch((errors: any) => errorCallback(errors));
  }, [
    useCaseDetails?.gbgDataSourceID,
    selectedDataSourcesArr,
    getAffectedDetails,
    setIsUpdating,
    orgId,
    useCaseDetails?.gbgUseCaseText,
    flowdownCallback,
    errorCallback,
    handleUpdate,
  ]);

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (!isError && !organisationHasError && !errorDatasources) dispatch(closeModal());
    else
      dispatch(
        openModal({ type: ModalContentType.API_ERROR, data: error || organisationErrorMessage || errorDatasources }),
      );
  }, [isError, error, organisationHasError, isError, isErrorDataSources, errorDatasources, dispatch]);

  return isFetching || organisationIsFetching || !useCaseDetails || !organisationDetails ? (
    <div className="text-center p-5">
      <GBG.Spinner data-testid="spinner" />
    </div>
  ) : (
    <>
      <Breadcrumb params={{ [orgId]: organisationDetails.gbgName, [useCaseId]: useCaseDetails.gbgUseCaseText ?? '' }} />
      <div className="m-m-t-2">
        <h1>{useCaseDetails.gbgUseCaseText}</h1>
        <div className="form__small">
          <PermissionGate
            permissionType={permissionScope}
            viewOnlyChildren={
              <OrganisationUseDetailsReadOnly
                useCaseDetails={useCaseDetails}
                orgDataSources={orgDataSources}
                history={history}
                orgId={orgId}
              />
            }
          >
            <>
              <TextInput
                name="gbgUseCaseText"
                id="form.useCase.type"
                {...{ register, errors, setValue }}
                defaultValue={useCaseDetails['gbgUseCaseText'] || ''}
                required={true}
                disabled={true}
                optional={false}
              />

              <Textarea
                name="gbgDescription"
                id="form.description"
                optional={true}
                defaultValue={useCaseDetails['gbgDescription'] || ''}
                {...{ register, errors, setValue }}
              />
              <div className="m-m-t-3 full-size-input">
                <UseCaseDataSources
                  key="gbgSelectedDatasource"
                  id="dataSources.title"
                  setSelectedDataSources={setSelectedDataSourcesArr}
                  selectedItem={useCaseDetails}
                  triggerRefresh={isRefreshing}
                  items={orgDataSources?.embedded.entries ?? []}
                  isFetching={isFetchingDataSources}
                />
              </div>
              <GBG.Button
                type="submit"
                className="m-m-t-2"
                kind={GBG.ButtonKind.Primary}
                worker={true}
                active={isUpdating || isUpdatingUseCase}
                onClick={onUpdate}
              >
                <FormattedMessage id="label.update" />
              </GBG.Button>
            </>
          </PermissionGate>
          {createFlowDownModal()}
        </div>
      </div>
    </>
  );
};

export default memo(OrganisationUseCaseDetails);

interface IOrganisationUseDetailsReadOnly {
  useCaseDetails: IUseCase;
  orgDataSources: IPaginatedRes<IDatasource> | undefined;
  orgId: string;
  history: any;
}
const OrganisationUseDetailsReadOnly: React.FC<IOrganisationUseDetailsReadOnly> = ({
  useCaseDetails,
  orgDataSources,
  orgId,
  history,
}) => {
  return (
    <>
      <div>{useCaseDetails.gbgDescription ? <p>{useCaseDetails.gbgDescription || ''}</p> : null}</div>
      <h3 className="m-m-t-5 heading-small">Data Sources</h3>
      <dl>
        {(orgDataSources?.embedded.entries ?? []).map((ds: IDatasource) => (
          <Fragment key={ds.gbgDataSourceID[0]}>
            <dt className="m-m-t-3 paragraph-large">
              {ds.gbgDataSourceGroup ? `${ds.gbgName} (${ds.gbgDataSourceGroup})` : ds.gbgName}
            </dt>
            <dd className="m-m-l-1">{ds.gbgDescription}</dd>
          </Fragment>
        ))}
      </dl>
      <GBG.Button
        kind={GBG.ButtonKind.Secondary}
        onClick={() => history.push(`/organisations/${orgId}?tab=${OrganisationTabs.USECASES}`)}
        data-testid="back"
        className="m-m-r-2 ms-3"
      >
        <FormattedMessage id="btn.back" />
      </GBG.Button>
    </>
  );
};
