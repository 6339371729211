import GBG from '@gbg/gbgcomponentlibrary_react';
import { nanoid } from '@reduxjs/toolkit';
import React, { memo, useEffect } from 'react';
import { FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

interface ICheckBox {
  id: string;
  name: string;
  register: UseFormRegister<FieldValues>;
  defaultValue?: boolean;
  setValue: UseFormSetValue<FieldValues>;
}

const CheckBox: React.FC<ICheckBox> = ({ id, name, register, defaultValue = false, setValue }) => {
  const uid: string = nanoid();

  useEffect(() => {
    setValue(`${name}` as const, defaultValue);
  }, [defaultValue]);

  return (
    <GBG.FormGroup type={GBG.FormGroupType.Checkbox}>
      <GBG.Checkbox {...register(`${name}` as const)} id={uid} data-testid={name} />

      <GBG.FormGroupInfo>
        <GBG.Label htmlFor={uid}>
          <GBG.LabelText>
            <div className="space__between">
              <FormattedMessage id={id} tagName="p" />
              <span className="label--assistive">
                <FormattedMessage id="label.optional" />
              </span>
            </div>
          </GBG.LabelText>
        </GBG.Label>

        <GBG.Assistive>
          <FormattedMessage id={`${id}.details`} />
        </GBG.Assistive>
      </GBG.FormGroupInfo>
    </GBG.FormGroup>
  );
};

export default memo(CheckBox);
