import GBG from '@gbg/gbgcomponentlibrary_react';
import React, { memo, useEffect } from 'react';
import { FieldValues, MultipleFieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

interface ISelect {
  id: string;
  name: string;
  options?: ISelectOption[];
  register: UseFormRegister<FieldValues>;
  defaultValue?: string;
  setValue: UseFormSetValue<FieldValues>;
  errors: { [fieldName: string]: { type: string; message?: string; types: MultipleFieldErrors } };
  required?: boolean;
  disabled?: boolean;
  optional?: boolean;
  assistiveText?: boolean;
}

const Select: React.FC<ISelect> = ({
  id,
  name,
  options = [],
  register,
  defaultValue = '',
  setValue,
  errors,
  required = true,
  disabled = false,
  optional = false,
  assistiveText = false,
}) => {
  useEffect(() => {
    setValue(`${name}` as const, defaultValue);
  }, [name, defaultValue, options]);

  return (
    <GBG.FormGroup>
      <GBG.Label>
        <div className="space__between">
          <FormattedMessage id={id} tagName="p" />
          {optional && (
            <span className="label--assistive">
              <FormattedMessage id="label.optional" />
            </span>
          )}
        </div>
      </GBG.Label>

      {assistiveText && (
        <GBG.Assistive>
          <FormattedMessage id={`${id}.details`} />
        </GBG.Assistive>
      )}

      <GBG.Select
        {...register(`${name}` as const, { required })}
        error={errors[name]}
        data-testid={name}
        disabled={disabled}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </GBG.Select>
      {errors[name] && (
        <GBG.ErrorText>{errors[name].message || <FormattedMessage id="errors.required" />}</GBG.ErrorText>
      )}
    </GBG.FormGroup>
  );
};

export default memo(Select);
