import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import GBG from '@gbg/gbgcomponentlibrary_react';

import { useRegenerateSecretMutation } from '../../../api/client';
import { useAppDispatch } from '../../../app/hooks';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import { ModalContentType } from '../../../features/modal/config';
import { getToastSuccess } from '../../../utils/getToastOptions';
import { useHistory } from 'react-router-dom';

const RegenerateSecret = ({ client }: { client: IClient }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [regenerateSecret, { isLoading: isDeleting }] = useRegenerateSecretMutation();

  const handleClose = useCallback(() => dispatch(closeModal()), []);
  const handleRegenerate = useCallback(
    () =>
      regenerateSecret({ gbgClientId: client.gbgClientId })
        .unwrap()
        .then(res => {
          GBG.toast(getToastSuccess());
          dispatch(closeModal());

          history.push(`/organisations/${client.gbgCompanyId}/clients/secret-client`, { secret: res });
        })
        .catch(error => dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }))),
    [client],
  );

  return (
    <>
      <p>
        <FormattedMessage id="modal.regenerate-question" />
        <strong>{client.gbgName}</strong>?
      </p>

      <GBG.Button
        kind={GBG.ButtonKind.Secondary}
        className="m-m-t-2 m-m-r-1"
        onClick={handleClose}
        data-testid="cancel"
      >
        <FormattedMessage id="btn.cancel" />
      </GBG.Button>

      <GBG.Button worker={true} active={isDeleting} kind={GBG.ButtonKind.Destructive} onClick={handleRegenerate}>
        <FormattedMessage id="btn.regenerateSecret" />
      </GBG.Button>
    </>
  );
};

export default memo(RegenerateSecret);
