import { useHistory } from 'react-router-dom';
import GBG from '@gbg/gbgcomponentlibrary_react';
import { useAppDispatch } from '../../../app/hooks';
import { useForm } from 'react-hook-form';
import { IUseCase } from '../../../interfaces/models/IUseCase';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { TOAST_SUCCESS } from '../../../constants';
import { ModalContentType } from '../../../features/modal/config';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import { useAddUseCaseMutation } from '../../../api/useCase';
import { USECASE_FORM_FIELDS } from '../../../constants/form';
import Form from '../../../components/forms/Form';
import UseCaseDataSources from '../../../components/forms/fields/UseCaseDataSources';
import _ from 'lodash';
import { useGetAllDataSourcesQuery } from '../../../api/dataSource';
import { OrganisationTabs } from '../../../constants/tabs';
import { useIntl } from 'react-intl';
interface IUseCaseFormData {
  orgId: string;
  useCaseTexts?: ISelectOption[];
}
const CreateUseCaseForm: FC<IUseCaseFormData> = ({ orgId, useCaseTexts }) => {
  const history = useHistory();
  const intl = useIntl();
  const [addUseCase, { isLoading: isAdding }] = useAddUseCaseMutation();
  const dispatch = useAppDispatch();
  const [selectedDataSources, setSelectedDataSources] = useState<string[]>([]);
  const {
    data: dataSources,
    isError: isErroDS,
    isFetching: isFetchingDS,
    error: errorDs,
  } = useGetAllDataSourcesQuery();

  useEffect(() => {
    if (!isErroDS) dispatch(closeModal());
    else dispatch(openModal({ type: ModalContentType.API_ERROR, data: errorDs }));
  }, [isErroDS, errorDs]);

  const fields = useMemo<IFormField[]>(() => {
    const formFields = _.cloneDeep(USECASE_FORM_FIELDS);
    const useCaseTextsField = formFields.filter(x => x.inputName === 'gbgUseCaseText')[0];
    useCaseTextsField.options = useCaseTexts;
    return formFields;
  }, [useCaseTexts]);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = useCallback(
    handleSubmit((values: IUseCase) => {
      if (selectedDataSources.length <= 0) {
        dispatch(
          openModal({
            type: ModalContentType.API_ERROR,
            data: { message: intl.formatMessage({ id: 'dataSources.notSelectedAny' }) },
          }),
        );

        return;
      }
      const formData = {
        gbgUseCaseText: values.gbgUseCaseText,
        gbgDescription: values.gbgDescription,
        gbgCompanyId: orgId,
        gbgUseCaseType: 'Company',
        gbgDataSourceID: selectedDataSources,
      } as IUseCase;

      addUseCase(formData)
        .unwrap()
        .then(() => {
          GBG.toast(TOAST_SUCCESS);
          history.push(`/organisations/${orgId}?tab=${OrganisationTabs.USECASES}`);
        })
        .catch((errors: any) => {
          dispatch(openModal({ type: ModalContentType.API_ERROR, data: errors }));
        });
    }),
    [handleSubmit, selectedDataSources, orgId, dispatch, addUseCase],
  );

  return (
    <Form
      {...{
        onSubmit,
        data: {},
        register,
        errors,
        setValue,
        isBusy: isAdding,
        isNew: true,
        fields,
      }}
    >
      <div className="m-m-t-3 full-size-input">
        <UseCaseDataSources
          key="gbgSelectedDatasource"
          id="dataSources.title"
          items={dataSources?.embedded.entries ?? []}
          isFetching={isFetchingDS}
          setSelectedDataSources={setSelectedDataSources}
        />
      </div>
    </Form>
  );
};
export default memo(CreateUseCaseForm);
