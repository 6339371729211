import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetClientQuery } from '../../../api/client';
import { useGetOrganisationQuery } from '../../../api/organisations';
import { useGetRoleQuery } from '../../../api/roles';
import { useGetUserQuery } from '../../../api/users';
import { useAppDispatch } from '../../../app/hooks';
import Breadcrumb from '../../../components/layout/Breadcrumb';
import { ModalContentType } from '../../../features/modal/config';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import RolesFeatures from '../Departments/Roles/RolesFeatures';
import RolesUseCases from '../Departments/Roles/RolesUseCases';

export const UserRoleDetailsPage = () => {
  const dispatch = useAppDispatch();
  const { orgId, roleId, userId, clientId } = useParams<{
    orgId: string;
    roleId: string;
    userId: string;
    clientId?: string;
  }>();
  const { data: user, isError, error } = useGetUserQuery({ userId, orgId });
  const {
    data: client,
    isError: isErrorClient,
    error: errorClient,
  } = useGetClientQuery(clientId ?? '', { skip: !clientId });
  const { data: organisation, isError: isErrorOrg, error: errorOrg } = useGetOrganisationQuery(orgId);
  const { data: departmentRole, isError: isErrorDepRole, error: errorDepRole } = useGetRoleQuery({ orgId, roleId });
  const baseUrl = `/organisations/${orgId}${clientId ? `/clients/${clientId}` : ''}/users/${userId}/roles/${roleId}`;
  useEffect(() => {
    if (!isErrorOrg && !isErrorDepRole && !isError && !isErrorClient) dispatch(closeModal());
    else
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: errorOrg || errorDepRole || error || errorClient }));
  }, [isErrorOrg, isErrorDepRole, errorOrg, errorDepRole, isError, error, isErrorClient, errorClient]);

  return !departmentRole || !user ? (
    <div className="text-center p-5">
      <GBG.Spinner data-testid="spinner" />
    </div>
  ) : (
    <>
      {organisation && (!clientId || client) && (
        <Breadcrumb
          params={{
            [orgId]: organisation.gbgName,
            [clientId ?? '']: client?.gbgName ?? '',
            [userId]: user?.username,
            [roleId]: departmentRole.gbgName,
          }}
        />
      )}
      <h1>{departmentRole.gbgName}</h1>
      <div className="m-m-t-2">
        <RolesFeatures role={departmentRole} addUrl={`${baseUrl}/add-permission`} />
      </div>
      <div className="m-m-t-2">
        <RolesUseCases
          departId={user.gbgDepartmentId}
          orgId={orgId}
          roleId={roleId}
          baseCreateEditPageURL={`${baseUrl}/usecases`}
        />
      </div>
    </>
  );
};
export default memo(UserRoleDetailsPage);
