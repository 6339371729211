import { FC, memo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetUseCasesForRoleQuery } from '../../../../api/useCase';
import { useAppDispatch } from '../../../../app/hooks';
import Table from '../../../../components/table/Table';
import { ModalContentType } from '../../../../features/modal/config';
import { closeModal, openModal } from '../../../../features/modal/modalSlice';
import { IUseCase } from '../../../../interfaces/models/IUseCase';
interface RoleUseCaseProps {
  orgId: string;
  roleId: string;
  departId: string;
  baseCreateEditPageURL: string;
}
export const RolesUseCases: FC<RoleUseCaseProps> = ({ orgId, roleId, departId, baseCreateEditPageURL }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const headers = ['gbgUseCaseText'];
  const { data, isFetching, isError, error } = useGetUseCasesForRoleQuery({
    gbgCompanyId: orgId,
    gbgRoleId: roleId,
    gbgDepartmentId: departId,
  });

  const onItemClicked = useCallback(
    (item: IUseCase) => {
      history.push(`${baseCreateEditPageURL}/${item.gbgUseCaseID}`);
    },
    [baseCreateEditPageURL],
  );

  useEffect(() => {
    if (!isError) dispatch(closeModal());
    else dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
  }, [isError, error, dispatch]);

  const actions = useCallback(
    (item: IUseCase) => [
      {
        title: 'Remove',
        danger: true,
        action: (dataItem: IUseCase) => {
          dispatch(
            openModal({
              type: ModalContentType.REMOVE_ROLEUSECASE,
              data: item || dataItem,
            }),
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <Table
        title="useCase.title"
        titleTag="h5"
        addUrl={`${baseCreateEditPageURL}/new-use-case`}
        isPaginated={false}
        {...{ headers, data, isFetching, onItemClicked, actions }}
      />
    </>
  );
};

export default memo(RolesUseCases);
