import GBG from '@gbg/gbgcomponentlibrary_react';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useUpdateUserMutation } from '../../../api/users';
import { ModalContentType } from '../../../features/modal/config';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import { useAppDispatch } from '../../../app/hooks';
import { TOAST_SUCCESS } from '../../../constants';
import { useGetAvailableUserRolesQuery } from '../../../api/roles';

const UserRoleForm: FC<{ baseCreateEditPageURL: string; userDetails: IUser; orgId: string }> = ({
  baseCreateEditPageURL,
  userDetails,
  orgId,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [selectedItems, setSelectedItems] = useState<string[]>();
  const [updateUser, { isLoading: isFormSubmitting }] = useUpdateUserMutation();

  const {
    data: userRoles,
    isError: isErrorUserRoles,
    error: errorUserRoles,
    isFetching: isFetchingUserRoles,
  } = useGetAvailableUserRolesQuery(
    { userId: userDetails.id ?? '', orgId, departId: userDetails?.gbgDepartmentId ?? '' },
    { skip: !userDetails },
  );

  useEffect(() => {
    if (!isErrorUserRoles) dispatch(closeModal());
    else dispatch(openModal({ type: ModalContentType.API_ERROR, data: errorUserRoles }));
  }, [isErrorUserRoles, errorUserRoles]);

  const dataSetData = useMemo(
    () => userRoles?.embedded.entries.map(role => ({ name: role.gbgName, id: role.gbgRoleID })) ?? [],
    [userRoles],
  );

  const handleSubmit = useCallback(() => {
    if (!selectedItems || selectedItems.length <= 0) {
      dispatch(
        openModal({ type: ModalContentType.API_ERROR, data: { message: 'Please select at least one use case.' } }),
      );
      return;
    }

    const previsoulyAssignedRoles = userDetails?.userRoles ? userDetails.userRoles : [];
    const formData = { ...userDetails, userRoles: [...previsoulyAssignedRoles, ...selectedItems] } as IUser;

    updateUser(formData)
      .unwrap()
      .then(() => {
        GBG.toast(TOAST_SUCCESS);
        history.push(baseCreateEditPageURL);
      })
      .catch((errors: any) => {
        dispatch(openModal({ type: ModalContentType.API_ERROR, data: errors }));
      });
  }, [selectedItems, userDetails, baseCreateEditPageURL, dispatch]);

  return isFetchingUserRoles || !userRoles || !userDetails ? (
    <div className="text-center p-5">
      <GBG.Spinner data-testid="spinner" />
    </div>
  ) : (
    <>
      {userRoles && userRoles.embedded.entries.length > 0 ? (
        <>
          <FormattedMessage id="users.add.role.to" tagName="h1" values={{ value: userDetails.username }} />
          <GBG.Table
            dataSet={{
              selectable: true,
              data: dataSetData,
              onSelectionChanged: (items: { name: string; id: string }[]) =>
                setSelectedItems(items.map(item => item.id ?? '')),
            }}
            className="custom__table m-m-t-2"
          />
        </>
      ) : (
        <div className="label m-m-t-5">
          <FormattedMessage id={'users.role.noData'} />
        </div>
      )}
      {selectedItems && selectedItems.length > 0 && (
        <GBG.Button
          type="button"
          kind={GBG.ButtonKind.Primary}
          className="m-m-r-2"
          onClick={handleSubmit}
          active={isFormSubmitting}
        >
          {isFormSubmitting && <GBG.Spinner data-testid="spinner" className="white-spinner" />}
          <FormattedMessage id="users.addRole" />
        </GBG.Button>
      )}

      <GBG.Button
        type="button"
        kind={GBG.ButtonKind.Secondary}
        className="m-m-r-2"
        onClick={() => {
          history.push(baseCreateEditPageURL);
        }}
      >
        <FormattedMessage id="users.roles.cancel" />
      </GBG.Button>
    </>
  );
};

export default memo(UserRoleForm);
