import { adminApi } from '../app/api';
import { IDatasource } from '../interfaces/models/IDatasource';

const endpoint = '/api/v1/datasource';
const orgEndpointPrefix = '/api/v1/organisations';
const orgEndPoint = (organisationId: string) => `${orgEndpointPrefix}/${organisationId}`;
const departmentEndPoint = (organisationId: string, departmentId: string) =>
  `${orgEndpointPrefix}/${organisationId}/departments/${departmentId}`;

const dataSourceService = adminApi.injectEndpoints({
  endpoints: build => ({
    getDatasourceList: build.query<IPaginatedRes<IDatasource>, IPaginatedReq>({
      query: (request: IPaginatedReq) => ({
        url: `${endpoint}?nameOrGroup=${request.search ?? ''}&cursor=${request.cursor ?? ''}&limit=${
          request.limit ?? ''
        }`,
        method: 'GET',
      }),
      providesTags: result =>
        result
          ? [
              ...result.embedded.entries.map(item => ({ type: 'DataSources', id: item.gbgDataSourceID[0] } as const)),
              { type: 'DataSources', id: 'LIST' },
            ]
          : [{ type: 'DataSources', id: 'LIST' }],
    }),
    getDataSource: build.query<IDatasource, string>({
      query: (dn: string) => ({ url: `${endpoint}/${dn}`, method: 'GET' }),
      providesTags: result => (result ? [{ type: 'DataSources', id: result?.gbgDataSourceID[0] }] : []),
    }),

    addDataSource: build.mutation<IDatasource, IDatasource>({
      query: data => ({ url: endpoint, method: 'POST', data }),
      invalidatesTags: result => (result ? [{ type: 'DataSources', id: 'LIST' }] : []),
    }),
    updateDataSource: build.mutation<IDatasource, IDatasource>({
      query: data => ({ url: endpoint, method: 'PUT', data }),
      invalidatesTags: result => (result ? [{ type: 'DataSources', id: result?.gbgDataSourceID[0] }] : []),
    }),
    deleteDataSource: build.mutation<string, string>({
      query: id => ({ url: `${endpoint}/${id}`, method: 'DELETE' }),
      invalidatesTags: result => (result ? [{ type: 'DataSources', id: result }] : []),
    }),
    getAllDataSources: build.query<IPaginatedRes<IDatasource>, void>({
      query: () => ({
        url: `${endpoint}?getAll=true`,
        method: 'GET',
      }),
      providesTags: result =>
        result
          ? [
              ...result.embedded.entries.map(
                item => ({ type: 'CompanyUseCaseDataSources', id: item.gbgDataSourceID[0] } as const),
              ),
              { type: 'CompanyUseCaseDataSources', id: 'LIST' },
            ]
          : [{ type: 'CompanyUseCaseDataSources', id: 'LIST' }],
    }),
    getAllowedDataSourcesForRoleUseCase: build.query<
      IPaginatedRes<IDatasource>,
      { departId: string; orgId: string; useCaseText: string }
    >({
      query: ({ departId, orgId, useCaseText }) => ({
        url: `${departmentEndPoint(orgId, departId)}/data-sources?useCaseText=${useCaseText}`,
        method: 'GET',
      }),
      providesTags: result =>
        result
          ? [
              ...result.embedded.entries.map(
                item => ({ type: 'RoleUseCaseDataSources', id: item.gbgDataSourceID[0] } as const),
              ),
              { type: 'RoleUseCaseDataSources', id: 'LIST' },
            ]
          : [{ type: 'RoleUseCaseDataSources', id: 'LIST' }],
    }),
    getAllowedDataSourcesForDepartmentUseCase: build.query<
      IPaginatedRes<IDatasource>,
      { orgId: string; useCaseText: string }
    >({
      query: ({ orgId, useCaseText }) => ({
        url: `${orgEndPoint(orgId)}/data-sources?useCaseText=${useCaseText}`,
        method: 'GET',
      }),
      providesTags: result =>
        result
          ? [
              ...result.embedded.entries.map(
                item => ({ type: 'DepartmentUseCaseDataSources', id: item.gbgDataSourceID[0] } as const),
              ),
              { type: 'DepartmentUseCaseDataSources', id: 'LIST' },
            ]
          : [{ type: 'DepartmentUseCaseDataSources', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetDatasourceListQuery,
  useGetDataSourceQuery,
  useAddDataSourceMutation,
  useUpdateDataSourceMutation,
  useDeleteDataSourceMutation,
  useGetAllDataSourcesQuery,
  useGetAllowedDataSourcesForDepartmentUseCaseQuery,
  useGetAllowedDataSourcesForRoleUseCaseQuery,
} = dataSourceService;
