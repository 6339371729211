import React, { useState } from 'react';
import './TriSelectSwitch.css';
enum SwitchPosition {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}
const TriSelectSwitch: React.FC<ITriSelectSwitch> = ({ labels, defaultValue, onChange, name, disabled }) => {
  const [state, setState] = useState<{
    value: string;
    currentPosition: SwitchPosition;
    animation: string | null;
  }>({
    value: defaultValue || 'left',
    animation: null,
    currentPosition:
      defaultValue === labels.left.value
        ? SwitchPosition.Left
        : defaultValue === labels.center.value
        ? SwitchPosition.Center
        : SwitchPosition.Right,
  });

  const getSwitchAnimation = (value: string, targetPosition: SwitchPosition) => {
    const newAnimation = `${state.currentPosition}-to-${targetPosition}`;
    onChange(value);
    setState({ value, animation: newAnimation, currentPosition: targetPosition });
  };

  return (
    <div className={`tri-container${disabled ? ' bg-dark' : ''}`}>
      <div className={`switch ${state.animation ?? ''} ${state.currentPosition}-position`}></div>
      <input
        onChange={e => getSwitchAnimation(e.target.value, SwitchPosition.Left)}
        name={name}
        id={`left-select-${name}`}
        className="left-select"
        type="radio"
        value={labels.left.value}
        checked={state.value === labels.left.value}
        disabled={disabled}
      />
      <label className="left-label" htmlFor={`left-select-${name}`}>
        {labels.left.label}
      </label>

      <input
        onChange={e => getSwitchAnimation(e.target.value, SwitchPosition.Center)}
        name={name}
        id={`center-select-${name}`}
        className="center-select"
        type="radio"
        value={labels.center.value}
        checked={state.value === labels.center.value}
        disabled={disabled}
      />
      <label className="center-label" htmlFor={`center-select-${name}`}>
        {labels.center.label}
      </label>

      <input
        onChange={e => getSwitchAnimation(e.target.value, SwitchPosition.Right)}
        name={name}
        className="right-select"
        id={`right-select-${name}`}
        type="radio"
        value={labels.right.value}
        checked={state.value === labels.right.value}
        disabled={disabled}
      />
      <label className="right-label" htmlFor={`right-select-${name}`}>
        {labels.right.label}
      </label>
    </div>
  );
};

export default TriSelectSwitch;

interface ITriSelectSwitch {
  labels: {
    left: ISelectOption;
    center: ISelectOption;
    right: ISelectOption;
  };
  defaultValue?: string;
  name: string;
  onChange: (value: string) => void;
  disabled: boolean;
}
