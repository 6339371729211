import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useGetAvailableUseCasesForDepartmentQuery, useImportUseCaseMutation } from '../../../../api/useCase';
import { useHistory, useParams } from 'react-router-dom';
import { IImportUseCase } from '../../../../interfaces/models/IUseCase';
import { useAppDispatch } from '../../../../app/hooks';
import { closeModal, openModal } from '../../../../features/modal/modalSlice';
import { ModalContentType } from '../../../../features/modal/config';
import { TOAST_SUCCESS } from '../../../../constants';
import Breadcrumb from '../../../../components/layout/Breadcrumb';
import { useGetOrganisationQuery } from '../../../../api/organisations';
import { useGetDepartmentQuery } from '../../../../api/departments';
import { DepartmentTabs } from '../../../../constants/tabs';

const ImportDepartmentUseCasePage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { orgId, departId } = useParams<{ orgId: string; departId: string }>();
  const [selectedItems, setSelectedItems] = useState<string[]>();
  const [addUseCases, { isLoading: isFormSubmitting }] = useImportUseCaseMutation();
  const { data: org, isError: isErrorOrg, isFetching: isFetchingOrg, error: errorOrg } = useGetOrganisationQuery(orgId);
  const {
    data: department,
    isError: isErrorDep,
    isFetching: isFetchingDep,
    error: errorDep,
  } = useGetDepartmentQuery({ orgId, departId });

  const {
    data: availableUseCases,
    isError,
    isFetching,
    error,
  } = useGetAvailableUseCasesForDepartmentQuery({
    gbgCompanyId: orgId,
    gbgDepartmentId: departId,
  });

  useEffect(() => {
    if (!isError && !isErrorDep && !isErrorOrg) dispatch(closeModal());
    else dispatch(openModal({ type: ModalContentType.API_ERROR, data: error || errorDep || errorOrg }));
  }, [isError, error, isErrorDep, isErrorOrg, errorDep, errorOrg, dispatch]);

  const availableUseCasesDataSet = useMemo(
    () =>
      availableUseCases?.embedded.entries.map(usecase => ({
        name: usecase.gbgUseCaseText,
        id: usecase.gbgUseCaseID,
      })) ?? [],
    [availableUseCases?.embedded.entries],
  );

  const handleSubmit = useCallback(() => {
    if (!selectedItems || selectedItems.length <= 0) {
      dispatch(
        openModal({ type: ModalContentType.API_ERROR, data: { message: 'Please select at least one use case.' } }),
      );
      return;
    }

    dispatch(closeModal());
    const formData: IImportUseCase = {
      gbgOrganisationId: orgId,
      gbgDepartmentId: departId,
      useCaseIDs: selectedItems,
    };

    addUseCases(formData)
      .unwrap()
      .then(() => {
        GBG.toast(TOAST_SUCCESS);
        history.push(`/organisations/${orgId}/Departments/${departId}?tab=${DepartmentTabs.USECASES}`);
      })
      .catch((errors: any) => {
        dispatch(openModal({ type: ModalContentType.API_ERROR, data: errors }));
      });
  }, [selectedItems, departId, dispatch, addUseCases]);

  return isFetching || isFetchingDep || isFetchingOrg || !department || !org ? (
    <div className="text-center p-5">
      <GBG.Spinner data-testid="spinner" />
    </div>
  ) : availableUseCases && availableUseCases.embedded.entries.length > 0 ? (
    <>
      <Breadcrumb params={{ [orgId]: org.gbgName, [departId]: department.gbgName }} />
      <GBG.Table
        dataSet={{
          selectable: true,
          data: availableUseCasesDataSet,
          onSelectionChanged: (items: { id: string; name: string }[]) =>
            setSelectedItems(items.map(item => item.id ?? '')),
        }}
        className="custom__table m-m-t-2"
      />
      <GBG.Button
        type="submit"
        className="m-m-t-2"
        kind={GBG.ButtonKind.Primary}
        worker={true}
        active={isFormSubmitting}
        onClick={handleSubmit}
      >
        <FormattedMessage id="btn.add" />
      </GBG.Button>
    </>
  ) : (
    <div className="label m-m-t-5">
      <FormattedMessage id={'form.useCase.noData'} />
    </div>
  );
};

export default memo(ImportDepartmentUseCasePage);
