import GBG from '@gbg/gbgcomponentlibrary_react';
import React, { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch } from '../../../app/hooks';
import { ModalContentType } from '../../../features/modal/config';
import { closeModal, openModal } from '../../../features/modal/modalSlice';

interface IRemoveModalContent {
  name: string;
  handleRemove: () => Promise<string>;
  isDeleting: boolean;
}

const RemoveModalContent: React.FC<IRemoveModalContent> = ({ name, handleRemove, isDeleting }) => {
  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => dispatch(closeModal()), []);

  const handleDelete = useCallback(
    () =>
      handleRemove()
        .then(() => dispatch(closeModal()))
        .catch(error => dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }))),
    [handleRemove],
  );

  return (
    <>
      <p>
        <FormattedMessage id="modal.remove-question" />
        <strong>{name}</strong>?
      </p>

      <GBG.Button
        kind={GBG.ButtonKind.Secondary}
        className="m-m-t-2 m-m-r-1"
        onClick={handleClose}
        data-testid="cancel"
      >
        <FormattedMessage id="btn.cancel" />
      </GBG.Button>

      <GBG.Button
        worker={true}
        active={isDeleting}
        kind={GBG.ButtonKind.Destructive}
        onClick={handleDelete}
        data-testid="remove"
      >
        <FormattedMessage id="btn.remove" />
      </GBG.Button>
    </>
  );
};

export default memo(RemoveModalContent);
