import { LICENCE_STATUS, STATUS_MOCKED } from '../components/forms/mockedData';

export const DEPARTMENT_USECASE_FORM_FIELDS: IFormField[] = [
  {
    fieldType: 'select',
    inputName: 'gbgUseCaseText',
    label: 'form.useCase.type',
    required: true,
  },
];

export const EDIT_ROLE_USECASE_FORM_FIELDS: IFormField[] = [
  {
    fieldType: 'dropzone',
    inputName: 'gbgSelectedDatasource',
    label: 'dataSources.title',
    isDepartment: false,
    isRoleUseCase: true,
  },
];

export const DATASOURCES_FORM_FIELDS: IFormField[] = [
  {
    fieldType: 'textInput',
    inputName: 'gbgDataSourceID',
    label: 'form.id',
    required: true,
  },
  {
    fieldType: 'textInput',
    inputName: 'gbgName',
    label: 'form.name',
    required: true,
  },
  {
    fieldType: 'textarea',
    inputName: 'gbgDescription',
    label: 'form.description',
    required: false,
    assistiveText: 'form.description.datasource.details',
    optional: false,
  },
  {
    fieldType: 'textInput',
    inputName: 'gbgDataSourceGroup',
    label: 'form.group',
    required: false,
    assistiveText: 'form.group.details',
    optional: true,
  },
  {
    fieldType: 'select',
    inputName: 'gbgDataSourceStatus',
    label: 'form.status',
    options: [...STATUS_MOCKED.map(item => ({ label: item.gbgName, value: item.gbgDataSourceStatus }))],
  },
  {
    fieldType: 'switch',
    inputName: 'gbgMFARequired',
    label: 'form.requires.mfa',
    required: false,
  },
];

export const DATASOURCES_FORM_FIELDS_DETAILS: IFormField[] = [
  {
    fieldType: 'textInput',
    inputName: 'gbgDataSourceID',
    label: 'form.id',
    disabled: true,
    required: true,
  },
  ...DATASOURCES_FORM_FIELDS.slice(1),
];

export const ORGANISATION_LICENCE_FIELDS: IFormField[] = [
  {
    fieldType: 'switch',
    inputName: 'restrictSeats',
    label: 'form.restrict.seats',
  },
  {
    fieldType: 'textInput',
    inputName: 'gbgAllowedSeats',
    label: 'form.seats',
    required: true,
  },
  {
    fieldType: 'textInput',
    inputName: 'gbgAgreementNumber',
    label: 'form.agreementNumber',
    required: true,
  },

  {
    fieldType: 'select',
    inputName: 'gbgLicenceStatus',
    label: 'form.status',
    options: LICENCE_STATUS,
  },
];

export const LICENCE_FIELDS_DETAILS: IFormField[] = [
  ...ORGANISATION_LICENCE_FIELDS,
  {
    fieldType: 'textInput',
    inputName: 'gbgUsedSeats',
    label: 'form.usedSeats',
    disabled: true,
  },
];
export const ROLE_FIELDS: IFormField[] = [
  {
    fieldType: 'select',
    inputName: 'gbgApplicationId',
    label: 'form.application',
    required: true,
  },
  {
    fieldType: 'textInput',
    inputName: 'gbgName',
    label: 'form.name',
    required: true,
  },
  {
    fieldType: 'textarea',
    inputName: 'gbgDescription',
    label: 'form.description',
    required: false,
    assistiveText: 'form.description.role.details',
    optional: true,
  },
];

export const USER_FIELDS: IFormField[] = [
  {
    fieldType: 'textInput',
    inputName: 'userGivenName',
    label: 'form.name.first',
    required: true,
  },
  {
    fieldType: 'textInput',
    inputName: 'userFamilyName',
    label: 'form.name.last',
    required: true,
  },
  {
    fieldType: 'textInput',
    inputName: 'email',
    label: 'form.email',
    required: true,
  },
  {
    fieldType: 'select',
    inputName: 'gbgDepartmentId',
    label: 'form.department',
    required: true,
  },
];

export const EDIT_USER_FIELDS: IFormField[] = [
  {
    fieldType: 'textInput',
    inputName: 'userGivenName',
    label: 'form.name.first',
    required: true,
  },
  {
    fieldType: 'textInput',
    inputName: 'userFamilyName',
    label: 'form.name.last',
    required: true,
  },
  {
    fieldType: 'textInput',
    inputName: 'email',
    label: 'form.email',
    required: false,
    disabled: true,
  },
  {
    fieldType: 'select',
    inputName: 'gbgDepartmentId',
    label: 'form.department',
    required: false,
    disabled: true,
  },
  {
    fieldType: 'switch',
    inputName: 'enabled',
    label: 'form.userActive',
  },
];

export const CLIENT_FIELDS: IFormField[] = [
  {
    fieldType: 'textInput',
    inputName: 'gbgClientId',
    label: 'table.header.gbgClientId',
    required: false,
    disabled: true,
  },
  {
    fieldType: 'textInput',
    inputName: 'gbgName',
    label: 'form.name',
    required: true,
  },
];
export const CLIENT_USER_FIELDS: IFormField[] = [
  {
    fieldType: 'textInput',
    inputName: 'username',
    label: 'form.username',
    required: true,
  },
  {
    fieldType: 'textInput',
    inputName: 'email',
    label: 'form.email',
    required: true,
  },
];
