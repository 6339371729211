import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useFlowdownUpdate from '../../../hooks/useFlowdownUpdate';
import { useGetOrganisationQuery } from '../../../api/organisations';
import Breadcrumb from '../../../components/layout/Breadcrumb';
import {
  useGetLicenceByParamQuery,
  useGetLicenceQuery,
  useLazyGetAffectedLicencesQuery,
  useUpdateLicenceMutation,
} from '../../../api/licences';
import { useAppDispatch } from '../../../app/hooks';
import { ModalContentType } from '../../../features/modal/config';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import Spinner from '../../../components/common/Spinner';
import { useGetDepartmentQuery } from '../../../api/departments';
import { DepartmentTabs, OrganisationTabs } from '../../../constants/tabs';
import { getCustomerViewPermissionScopes } from '../../../auth/customerViewAccess';
import { SCOPES } from '../../../auth/permissions';
import { useHistory } from 'react-router-dom';
import { LICENCE_FIELDS_DETAILS } from '../../../constants/formData';
import Form from '../../../components/forms/Form';
import _ from 'lodash';
import LicenceFunctionsList from './LicenceFunctionsList';
import { LicenceItemSelectionStatus } from '../../../enums/LicenceSelectStatus';

const LicenceDetailsPage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const intl = useIntl();
  const { orgId, licenceId, departId } = useParams<any>();
  const {
    data: licence,
    isFetching: isLicenceFetching,
    isError,
    error,
  } = useGetLicenceQuery({ licenceId, organisationId: orgId });
  const { data: organisation } = useGetOrganisationQuery(orgId);
  const { data: department, isFetching: isDepartFetching } = useGetDepartmentQuery(
    { orgId, departId },
    { skip: !departId },
  );
  const [updateLicence] = useUpdateLicenceMutation();
  const [getAffectedLicences] = useLazyGetAffectedLicencesQuery();
  const appId = licence?.gbgApplicationId || '';
  const { data: parentLicence, isFetching: isFetchingParentLicence } = useGetLicenceByParamQuery(
    { orgId, appId },
    { skip: !departId || !appId },
  );

  const permissionScope = getCustomerViewPermissionScopes().permissionScope;
  const [displayRestrictedSeat, setDisplayRestrictedSeat] = useState<boolean>(
    (licence?.gbgAllowedSeats as number) > 0 ? true : false,
  );

  const getFormInputs = useMemo<IFormField[]>(() => {
    let fields = _.cloneDeep(LICENCE_FIELDS_DETAILS);
    const hasParentLicence = departId && parentLicence;

    const restrictedSeatSwitch = fields.filter(x => x.inputName === 'restrictSeats')[0];
    restrictedSeatSwitch.onChange = () => handleRestrictedSeatSwitch();
    restrictedSeatSwitch.defaultValue = displayRestrictedSeat;

    if (hasParentLicence) {
      if ((parentLicence?.gbgAllowedSeats ?? 0) <= 0)
        fields = fields.filter(x => x.inputName !== 'restrictSeats' && x.inputName !== 'gbgAllowedSeats');
      else {
        restrictedSeatSwitch.disabled = true;
      }
    } else if (licence?.hasDepartmentsWithRestrictedSeats) {
      restrictedSeatSwitch.disabled = true;
    } else if (!displayRestrictedSeat) fields = fields.filter(x => x.inputName !== 'gbgAllowedSeats');

    if (permissionScope !== SCOPES.FullAccess) {
      fields.filter(x => x.inputName === 'gbgLicenceStatus')[0].disabled = true;
      fields.filter(x => x.inputName === 'gbgAgreementNumber')[0].disabled = true;
      restrictedSeatSwitch.disabled = true;
    }
    return fields;
  }, [permissionScope, departId, parentLicence, licence, displayRestrictedSeat]);

  const handleRestrictedSeatSwitch = () => {
    setDisplayRestrictedSeat(!displayRestrictedSeat);
  };

  // we need to have access to functions in the parent component
  const [functions, setFunctions] = useState<ISelectableLicenceFunctionItem[]>([]);

  useEffect(() => {
    if (!isError) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
    }
  }, [isError, error]);

  useEffect(() => {
    if (licence) {
      setDisplayRestrictedSeat((licence?.gbgAllowedSeats ?? 0) > 0);
    }
  }, [licence]);

  const getUpdatingObjects = useCallback(() => {
    const formValues = getValues();
    const data = {
      ...licence,
      gbgFunctionId: functions
        .filter(
          x => x.status === LicenceItemSelectionStatus.Selected || x.status === LicenceItemSelectionStatus.Mandatory,
        )
        .map(x => x.id),
      gbgMandatoryFunctionId: functions.filter(x => x.status === LicenceItemSelectionStatus.Mandatory).map(x => x.id),
      ...formValues,
      gbgAllowedSeats: formValues.restrictSeats ? formValues.gbgAllowedSeats : 0,
    } as ILicence;

    return { data };
  }, [licence, licence?.gbgFunctionId, functions]);

  const { isUpdating, setIsUpdating, createFlowDownModal, handleUpdate, flowdownCallback, errorCallback } =
    useFlowdownUpdate({
      orgId: orgId,
      departId: departId,
      resourceName: licence?.gbgName ?? '',
      resourceTabId: departId ? DepartmentTabs.LICENCES : OrganisationTabs.LICENCES,
      getUpdatingObjects,
      submitFunction: updateLicence,
      handleCancel: () => void 0,
    });

  const {
    register,
    setValue,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = useCallback(
    handleSubmit(async (formData: ILicence) => {
      if (!licence) return;
      const selectedFuncIds = functions
        ?.filter(
          x => x.status === LicenceItemSelectionStatus.Selected || x.status === LicenceItemSelectionStatus.Mandatory,
        )
        .map(x => x.id);

      if (selectedFuncIds.length <= 0) {
        dispatch(
          openModal({
            type: ModalContentType.API_ERROR,
            data: { message: intl.formatMessage({ id: 'licence.notSelectedAny' }) },
          }),
        );

        return;
      }

      const mandatoryFuncIds = functions?.filter(x => x.status === LicenceItemSelectionStatus.Mandatory).map(x => x.id);
      const removedFuncIDs = licence.gbgFunctionId?.filter(funcId => selectedFuncIds.indexOf(funcId) < 0) ?? [];
      const addedFuncIDs = licence.gbgFunctionId
        ? selectedFuncIds.filter(funcId => licence.gbgFunctionId?.indexOf(funcId) < 0)
        : selectedFuncIds ?? [];
      if (addedFuncIDs.length === 0 && removedFuncIDs.length === 0) return handleUpdate();

      setIsUpdating(true);
      const updatedLicence: ILicence = {
        ...licence,
        ...formData,
        gbgFunctionId: selectedFuncIds,
        gbgMandatoryFunctionId: mandatoryFuncIds,
      };
      getAffectedLicences(updatedLicence)
        .unwrap()
        .then(result => {
          const flowDownResults: IAffectedDetailDto = {
            ...result,
            addedOrRemovedItems: functions
              .filter(
                x =>
                  x.status != LicenceItemSelectionStatus.Mandatory &&
                  (addedFuncIDs.includes(x.id) || removedFuncIDs.includes(x.id)),
              )
              .map(x => ({
                id: x.id,
                name: x.name,
                changeType: addedFuncIDs.includes(x.id) ? 'Add' : 'Remove',
              })),
          };
          flowdownCallback(flowDownResults);
        })
        .catch((errors: any) => errorCallback(errors));
    }),
    [
      licence?.gbgFunctionId,
      licence?.gbgApplicationId,
      setIsUpdating,
      orgId,
      departId,
      flowdownCallback,
      errorCallback,
      functions,
    ],
  );
  return (
    <>
      {organisation && licence && !isDepartFetching && (
        <Breadcrumb
          params={{
            [orgId]: organisation.gbgName,
            [licenceId]: licence.gbgName,
            [departId]: department?.gbgName ?? '',
          }}
        />
      )}
      <div className="m-m-t-2">
        <h1>{licence?.gbgName}</h1>

        {isLicenceFetching || !licence || isFetchingParentLicence ? (
          <Spinner />
        ) : (
          <>
            <Form
              {...{
                register,
                setValue,
                errors,
                data: licence,
                onSubmit,
                fields: getFormInputs,
                isBusy: isUpdating,
                submitButtonText: 'label.update',
                shouldDisplaySaveButton:
                  functions.some(
                    x =>
                      x.status === LicenceItemSelectionStatus.Selected ||
                      x.status === LicenceItemSelectionStatus.Mandatory,
                  ) &&
                  (departId || permissionScope === SCOPES.FullAccess),
              }}
            >
              <LicenceFunctionsList
                licence={licence}
                readOnlyMode={permissionScope === SCOPES.CanView && !departId}
                setFunctions={setFunctions}
                functions={functions}
              />

              <GBG.Button
                kind={GBG.ButtonKind.Secondary}
                onClick={() =>
                  history.push(
                    `/organisations/${orgId}/${departId ? 'departments/' + departId : ''}?tab=${
                      OrganisationTabs.LICENCES
                    }`,
                  )
                }
                data-testid="cancel"
                className="m-m-r-2 ms-3"
              >
                <FormattedMessage id="btn.back" />
              </GBG.Button>
            </Form>
          </>
        )}

        {createFlowDownModal()}
      </div>
    </>
  );
};

export default memo(LicenceDetailsPage);
