import Cookies from 'js-cookie';

import { DEFAULT_LANGUAGE, ENGLISH, GBG_LANGUAGE_COOKIE, LANGUAGE_OPTIONS } from './config';

interface ExtendedNavigator extends Navigator {
  userLanguage: string;
}

const getBrowserLocale = (): string => {
  const language = window.navigator.language || (window.navigator as ExtendedNavigator).userLanguage || ENGLISH.value;
  const locale = language.split('-')[0];

  return locale;
};

export const getCurrentOption = (): ISelectOption => {
  const locale = Cookies.get(GBG_LANGUAGE_COOKIE) || getBrowserLocale();
  const selected = LANGUAGE_OPTIONS.find(option => option.value === locale);

  return selected || DEFAULT_LANGUAGE;
};
