import { FC, useEffect, memo, useCallback, useState } from 'react';
import Table from '../../../../components/table/Table';
import { ModalContentType } from '../../../../features/modal/config';
import { useAppDispatch } from '../../../../app/hooks';
import { useGetClientUsersQuery } from '../../../../api/users';
import { useHistory } from 'react-router-dom';
import { closeModal, openModal } from '../../../../features/modal/modalSlice';
import userListOverflowActions from '../../../../hooks/userListOverflowActions';

const ClientUsersList: FC<{ clientId: string; orgId: string }> = ({ clientId, orgId }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const headers = ['username', 'email', 'enabled'];

  const [search, setSearch] = useState<string>('');
  const { data, isFetching, isError, error } = useGetClientUsersQuery({
    clientId,
    search,
  });
  useEffect(() => {
    if (!isError) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
    }
  }, [isError, error]);

  const onItemClicked = useCallback(
    (item: IUser) => history.push(`/organisations/${orgId}/clients/${clientId}/users/${item.id}`),
    [orgId, clientId],
  );

  const { actions } = userListOverflowActions({ dispatch });

  return (
    <>
      <Table
        addUrl={`/organisations/${orgId}/clients/${clientId}/new-user`}
        isPaginated={false}
        {...{ headers, data, isFetching, onItemClicked, actions, setSearch }}
      ></Table>
    </>
  );
};

export default memo(ClientUsersList);
