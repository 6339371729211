import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import GBG from '@gbg/gbgcomponentlibrary_react';
import { DEPARTMENT_USECASE_FORM_FIELDS } from '../../../../constants/formData';
import Form from '../../../../components/forms/Form';
import { useForm, useWatch } from 'react-hook-form';
import { closeModal, openModal } from '../../../../features/modal/modalSlice';
import { ModalContentType } from '../../../../features/modal/config';
import { TOAST_SUCCESS } from '../../../../constants';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/hooks';
import { useAddUseCaseMutation, useGetAvailableUseCasesForDepartmentQuery } from '../../../../api/useCase';
import { IUseCase } from '../../../../interfaces/models/IUseCase';
import Breadcrumb from '../../../../components/layout/Breadcrumb';
import { useGetDepartmentQuery } from '../../../../api/departments';
import { useGetOrganisationQuery } from '../../../../api/organisations';
import _ from 'lodash';
import UseCaseDataSources from '../../../../components/forms/fields/UseCaseDataSources';
import { useGetAllowedDataSourcesForDepartmentUseCaseQuery } from '../../../../api/dataSource';
import { useIntl } from 'react-intl';
import { DepartmentTabs } from '../../../../constants/tabs';

const NewDepartmentUseCasePage = () => {
  const [selectedDataSources, setSelectedDataSources] = useState<string[]>([]);
  const { orgId, departId } = useParams<{ orgId: string; departId: string }>();
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [addUseCase, { isLoading: isAdding }] = useAddUseCaseMutation();
  const { data: org, isError: isErrorOrg, isFetching: isFetchingOrg, error: errorOrg } = useGetOrganisationQuery(orgId);
  const { data: department, isError, isFetching, error } = useGetDepartmentQuery({ orgId, departId });

  const {
    data: useCases,
    isError: isErrorTexts,
    isFetching: isFetchingText,
    error: errorTexts,
  } = useGetAvailableUseCasesForDepartmentQuery({ gbgCompanyId: orgId, gbgDepartmentId: departId });

  const fields = useMemo<IFormField[]>(() => {
    const formFields = _.cloneDeep(DEPARTMENT_USECASE_FORM_FIELDS);
    const useCaseTextsField = formFields.filter(x => x.inputName === 'gbgUseCaseText')[0];
    useCaseTextsField.options = [{ label: 'Please Select', value: '' }];
    useCases?.embedded.entries.map(x =>
      useCaseTextsField.options?.push({
        label: x.gbgUseCaseText ?? '',
        value: x.gbgUseCaseText ?? '',
      }),
    );

    return formFields;
  }, [useCases]);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const selectedUseCaseText: string = useWatch({ control, name: 'gbgUseCaseText' });
  const selectedUseCaseDetails = useMemo(() => {
    if (useCases) return useCases.embedded.entries.filter(x => x.gbgUseCaseText === selectedUseCaseText)[0];
  }, [selectedUseCaseText, useCases?.embedded.entries]);

  const {
    data: items,
    isFetching: isFetchingDataSources,
    isError: isErrorDataSources,
    error: errorDatasources,
  } = useGetAllowedDataSourcesForDepartmentUseCaseQuery(
    {
      orgId,
      useCaseText: selectedUseCaseDetails?.gbgUseCaseText as string,
    },
    { skip: !selectedUseCaseDetails },
  );

  useEffect(() => {
    setSelectedDataSources(selectedUseCaseDetails?.gbgDataSourceID ?? []);
  }, [selectedUseCaseDetails?.gbgDataSourceID, setSelectedDataSources]);

  useEffect(() => {
    if (!isErrorOrg && !isError && !isErrorTexts && !isErrorDataSources) dispatch(closeModal());
    else
      dispatch(
        openModal({ type: ModalContentType.API_ERROR, data: errorOrg || error || errorTexts || errorDatasources }),
      );
  }, [isErrorOrg, isError, isErrorTexts, errorOrg, error, errorTexts, isErrorDataSources, errorDatasources, dispatch]);

  const onSubmit = useCallback(
    handleSubmit(() => {
      if (selectedDataSources.length <= 0) {
        dispatch(
          openModal({
            type: ModalContentType.API_ERROR,
            data: { message: intl.formatMessage({ id: 'dataSources.notSelectedAny' }) },
          }),
        );

        return;
      }

      const formData: IUseCase = {
        ...selectedUseCaseDetails,
        gbgCompanyId: orgId,
        GbgDepartmentId: departId,
        gbgUseCaseType: 'Department',
        gbgDataSourceID: selectedDataSources,
      };

      addUseCase(formData)
        .unwrap()
        .then(() => {
          GBG.toast(TOAST_SUCCESS);
          history.push(`/organisations/${orgId}/Departments/${departId}?tab=${DepartmentTabs.USECASES}`);
        })
        .catch((errors: any) => {
          dispatch(openModal({ type: ModalContentType.API_ERROR, data: errors }));
        });
    }),
    [handleSubmit, selectedDataSources, selectedUseCaseDetails, departId, orgId, dispatch, addUseCase],
  );

  return isFetching || isFetchingOrg || !org || !department || isFetchingText || !useCases ? (
    <div className="text-center p-5">
      <GBG.Spinner data-testid="spinner" />
    </div>
  ) : (
    <>
      <Breadcrumb params={{ [orgId]: org.gbgName, [departId]: department.gbgName }} />
      <Form
        {...{
          onSubmit,
          data: {},
          register,
          errors,
          setValue,
          isBusy: isAdding,
          isNew: true,
          fields,
        }}
      >
        <div className="m-m-t-3 full-size-input">
          <UseCaseDataSources
            id="dataSources.title"
            selectedItem={selectedUseCaseDetails}
            setSelectedDataSources={setSelectedDataSources}
            items={items?.embedded.entries ?? []}
            isFetching={isFetchingDataSources}
          />
        </div>
      </Form>
    </>
  );
};

export default memo(NewDepartmentUseCasePage);
