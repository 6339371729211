import GBG from '@gbg/gbgcomponentlibrary_react';
import React, { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetClientQuery } from '../../../api/client';
import { useGetOrganisationQuery } from '../../../api/organisations';
import { useGetRoleQuery } from '../../../api/roles';
import { useGetUserQuery } from '../../../api/users';
import { useAppDispatch } from '../../../app/hooks';
import Breadcrumb from '../../../components/layout/Breadcrumb';
import { ModalContentType } from '../../../features/modal/config';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import RoleFeatures from '../Roles/RoleFeatures';

const AddUserRoleFeatures: React.FC = () => {
  const dispatch = useAppDispatch();
  const { orgId, userId, roleId, clientId } = useParams<{
    orgId: string;
    userId: string;
    roleId: string;
    clientId: string;
  }>();

  const {
    data: role,
    isError: isErrorRole,
    isFetching: isFetchingRole,
    error: errorRole,
  } = useGetRoleQuery({ orgId, roleId });
  const { data: org, isError: isErrorOrg, error: errorOrg } = useGetOrganisationQuery(orgId);
  const { data: user, isError: isErrorUser, error: errorUser } = useGetUserQuery({ userId, orgId });
  const {
    data: client,
    isError: isErrorClient,
    error: errorClient,
  } = useGetClientQuery(clientId ?? '', { skip: !clientId });

  const baseCreateEditPageURL = `/organisations/${orgId}${
    clientId ? `/clients/${clientId}` : ''
  }/users/${userId}/roles/${roleId}`;

  useEffect(() => {
    if (!isErrorOrg && !isErrorRole && !isErrorUser && !isErrorClient) dispatch(closeModal());
    else
      dispatch(
        openModal({ type: ModalContentType.API_ERROR, data: errorOrg || errorRole || errorUser || errorClient }),
      );
  }, [isErrorOrg, isErrorRole, errorOrg, errorRole, errorUser, isErrorUser, isErrorClient, errorClient]);

  return isFetchingRole || !role ? (
    <div className="text-center p-5">
      <GBG.Spinner data-testid="spinner" />
    </div>
  ) : (
    <>
      {org && role && user && (!clientId || client) && (
        <Breadcrumb
          params={{
            [orgId]: org.gbgName,
            [userId]: user.username,
            [clientId ?? '']: client?.gbgName ?? '',
            [roleId]: role.gbgName,
          }}
        />
      )}
      <RoleFeatures role={role} baseCreateEditPageURL={baseCreateEditPageURL} />
    </>
  );
};

export default memo(AddUserRoleFeatures);
