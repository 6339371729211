import { FieldValues, MultipleFieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import TextInput from './fields/TextInput';
import Textarea from './fields/Textarea';
import CheckBox from './fields/CheckBox';
import Select from './fields/Select';
import SwitchInput from './fields/SwitchInput';
import AutoCompleteInput from './fields/AutoCompleteInput';

interface IFormFieldItem {
  field: IFormField;
  data?: { [key: string]: any };
  register: UseFormRegister<FieldValues>;
  errors: { [fieldName: string]: { type: string; message?: string; types: MultipleFieldErrors } };
  setValue: UseFormSetValue<FieldValues>;
  disabled?: boolean;
  onChange?: ((event: any) => void) | undefined;
}

const FormFieldItem: React.FC<IFormFieldItem> = ({
  field,
  data = {},
  register,
  errors,
  setValue,
  disabled = false,
}) => {
  switch (field.fieldType) {
    case 'textInput':
      return (
        <TextInput
          key={field.inputName}
          id={field.label}
          name={field.inputName}
          defaultValue={data[field.inputName] ?? field.defaultValue}
          required={field.required}
          disabled={field.disabled}
          optional={field.optional}
          assistiveText={field.assistiveText as boolean}
          {...{ register, errors, setValue }}
        />
      );
    case 'textarea':
      return (
        <Textarea
          key={field.inputName}
          id={field.label}
          name={field.inputName}
          defaultValue={data[field.inputName]}
          required={field.required}
          optional={field.optional}
          assistiveText={field.assistiveText as string}
          {...{ register, errors, setValue, disabled }}
        />
      );
    case 'checkBox':
      return (
        <CheckBox
          key={field.inputName}
          id={field.label}
          name={field.inputName}
          defaultValue={data[field.inputName]}
          {...{ register, setValue, disabled }}
        />
      );
    case 'select':
      return (
        <Select
          key={field.inputName}
          id={field.label}
          name={field.inputName}
          options={field.options}
          defaultValue={data[field.inputName] ?? field.defaultValue}
          required={field.required}
          disabled={field.disabled || disabled}
          optional={field.optional}
          assistiveText={field.assistiveText as boolean}
          {...{ register, errors, setValue }}
        />
      );
    case 'switch':
      return (
        <SwitchInput
          key={field.inputName}
          id={field.label}
          name={field.inputName}
          defaultValue={data[field.inputName] ?? field.defaultValue}
          className={field.className}
          disabled={field.disabled || disabled}
          onChange={field.onChange}
          {...{ register, setValue }}
          hasAssistiveText={false}
        />
      );
    case 'autoCompleteInput':
      return (
        <AutoCompleteInput
          key={field.inputName}
          id={field.label}
          name={field.inputName}
          options={field.options ?? []}
          val={data[field.inputName]}
          optional={field.optional ?? false}
          required={field.required ?? false}
          assistiveText={field.assistiveText as boolean}
          {...{ register, setValue, errors }}
        />
      );
    default:
      return null;
  }
};

export default FormFieldItem;
