import { adminApi } from '../app/api';

const endpoint = '/api/v1/application';
const orgEndpoint = '/api/v1/organisations';

const extendedApi = adminApi.injectEndpoints({
  endpoints: build => ({
    getApplications: build.query<IApplication[], void>({
      query: () => ({ url: endpoint, method: 'GET' }),
      providesTags: result =>
        result
          ? [
              ...result.map(({ gbgApplicationId }) => ({ type: 'Applications', id: gbgApplicationId } as const)),
              { type: 'Applications', id: 'LIST' },
            ]
          : [{ type: 'Applications', id: 'LIST' }],
    }),
    getApplication: build.query<IApplication, string>({
      query: id => ({ url: `${endpoint}/${id}`, method: 'GET' }),
      providesTags: result => (result ? [{ type: 'Applications', id: result?.gbgApplicationId }] : []),
    }),
    addApplication: build.mutation<IApplication, Partial<IApplication>>({
      query: data => ({ url: endpoint, method: 'POST', data }),
      invalidatesTags: [
        { type: 'Applications', id: 'LIST' },
        { type: 'Applications', id: 'AVAILABLELIST' },
      ],
    }),
    updateApplication: build.mutation<IApplication, Partial<IApplication>>({
      query: data => ({ url: endpoint, method: 'PUT', data }),
      invalidatesTags: result => (result ? [{ type: 'Applications', id: result?.gbgApplicationId }] : []),
    }),
    deleteApplication: build.mutation<string, string>({
      query: id => ({ url: `${endpoint}/${id}`, method: 'DELETE' }),
      invalidatesTags: id => (id ? [{ type: 'Applications', id: id }] : []),
    }),
    getAvailableApplications: build.query<IApplication[], { orgId: string; departId?: string }>({
      query: ({ orgId, departId }) => ({
        url: `${orgEndpoint}/${orgId}/applications?departId=${departId ?? ''}`,
        method: 'GET',
      }),
      providesTags: result =>
        result
          ? [
              ...result.map(({ gbgApplicationId }) => ({ type: 'Applications', id: gbgApplicationId } as const)),
              { type: 'Applications', id: 'AVAILABLELIST' },
            ]
          : [{ type: 'Applications', id: 'AVAILABLELIST' }],
    }),
    getDepartmentRoleApplications: build.query<IApplication[], { orgId: string; departId: string }>({
      query: ({ orgId, departId }) => ({
        url: `${orgEndpoint}/${orgId}/departments/${departId}/applications`,
        method: 'GET',
      }),
      providesTags: result =>
        result
          ? [
              ...result.map(({ gbgApplicationId }) => ({ type: 'Applications', id: gbgApplicationId } as const)),
              { type: 'Applications', id: 'DEPARTMENTROLE_APPS' },
            ]
          : [{ type: 'Applications', id: 'DEPARTMENTROLE_APPS' }],
    }),
  }),
});

export const {
  useGetApplicationsQuery,
  useGetApplicationQuery,
  useAddApplicationMutation,
  useUpdateApplicationMutation,
  useDeleteApplicationMutation,
  useGetAvailableApplicationsQuery,
  useGetDepartmentRoleApplicationsQuery,
} = extendedApi;
