import { adminApi } from '../app/api';

const endpoint = '/api/v1/licence';
const customerViewEndPoint = '/api/v1/organisations';
const extendedApi = adminApi.injectEndpoints({
  endpoints: build => ({
    getLicences: build.query<IPaginatedRes<ILicenceWithDetails>, IPaginatedReqWithParams>({
      query: ({ organisationId, departmentId, search, limit, cursor }) => ({
        url: `${customerViewEndPoint}/${organisationId}/licences?departmentId=${departmentId ?? ''}&search=${
          search ?? ''
        }&cursor=${cursor ?? ''}&limit=${limit ?? ''}`,
        method: 'GET',
      }),
      // TODO: why does typescript complain about the response type when its IPaginatedRes<ILicenceWithDetails?
      transformResponse: function (response: any): IPaginatedRes<ILicenceWithDetails> {
        const entries = response?.embedded?.entries ?? [];
        for (const entry of entries) {
          entry.seats =
            entry.gbgAllowedSeats === null || entry.gbgAllowedSeats === 0
              ? entry?.gbgUsedSeats ?? 0
              : `${entry?.gbgUsedSeats ?? 0} of ${entry.gbgAllowedSeats}`;
        }
        return response;
      },
      providesTags: result =>
        result
          ? [
              ...result.embedded.entries.map(
                ({ gbgApplicationId }) => ({ type: 'Licences', id: gbgApplicationId } as const),
              ),
              { type: 'Licences', id: 'LIST' },
            ]
          : [{ type: 'Licences', id: 'LIST' }],
    }),
    getLicence: build.query<ILicence, { licenceId: string; organisationId: string }>({
      query: ({ licenceId, organisationId }) => ({
        url: `${customerViewEndPoint}/${organisationId}/licences/${licenceId}`,
        method: 'GET',
      }),
      providesTags: result => (result ? [{ type: 'Licences', id: result?.gbgApplicationId }] : []),
    }),
    getLicenceByParam: build.query<ILicence, { orgId: string; appId: string }>({
      query: ({ orgId, appId }) => ({
        url: `${customerViewEndPoint}/${orgId}/licences?applicationId=${appId}`,
        method: 'GET',
      }),
      providesTags: result => (result ? [{ type: 'Licences', id: result?.gbgApplicationId }] : []),
    }),
    getAffectedLicences: build.query<IAffectedDetailDto, ILicence>({
      query: licence => ({
        url: `${endpoint}/CheckLicences`,
        method: 'POST',
        data: licence,
      }),
      providesTags: result => (result ? [{ type: 'AffectedLicences', id: 'LIST' }] : []),
    }),
    addLicence: build.mutation<ILicence, Partial<ILicence>>({
      query: data => ({ url: `${customerViewEndPoint}/${data.gbgCompanyId}/licences`, method: 'POST', data }),
      invalidatesTags: result =>
        result
          ? [
              { type: 'Licences', id: 'LIST' },
              { type: 'Licences', id: result?.gbgApplicationId },
              { type: 'RoleFunctions', id: 'LIST' },
              { type: 'Applications', id: 'AVAILABLELIST' },
              { type: 'Applications', id: 'DEPARTMENTROLE_APPS' },
            ]
          : [],
    }),
    updateLicence: build.mutation<ILicence, Partial<ILicenceUpdate>>({
      query: data => ({ url: `${customerViewEndPoint}/${data.gbgCompanyId}/licences`, method: 'PUT', data }),
      invalidatesTags: result =>
        result
          ? [
              { type: 'Licences', id: result?.gbgApplicationId },
              { type: 'RoleFunctions', id: 'LIST' },
              { type: 'Functions', id: 'FUNCTIONS_BY_APPLICATION' },
            ]
          : [],
    }),
    deleteLicence: build.mutation<string, { licenceId: string }>({
      query: ({ licenceId }) => ({
        url: `${endpoint}/${licenceId}`,
        method: 'DELETE',
      }),
      invalidatesTags: applicationId =>
        applicationId
          ? [
              { type: 'Licences', id: 'LIST' },
              { type: 'Licences', id: applicationId },
              { type: 'RoleFunctions', id: 'LIST' },
              { type: 'Applications', id: 'AVAILABLELIST' },
              { type: 'Applications', id: 'DEPARTMENTROLE_APPS' },
            ]
          : [],
    }),
  }),
});

export const {
  useGetLicencesQuery,
  useGetLicenceQuery,
  useGetLicenceByParamQuery,
  useAddLicenceMutation,
  useUpdateLicenceMutation,
  useDeleteLicenceMutation,
  useLazyGetAffectedLicencesQuery,
} = extendedApi;
