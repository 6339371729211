import GBG from '@gbg/gbgcomponentlibrary_react';
import React, { memo, useEffect } from 'react';
import { FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

export interface ISwitchInput {
  id: string;
  name: string;
  register: UseFormRegister<FieldValues>;
  defaultValue?: boolean;
  setValue: UseFormSetValue<FieldValues>;
  onChange?: ((event: any) => void) | undefined;
  hasAssistiveText?: boolean;
  className?: string;
  disabled?: boolean;
}

const SwitchInput: React.FC<ISwitchInput> = ({
  id,
  name,
  register,
  defaultValue,
  setValue,
  onChange,
  hasAssistiveText = true,
  className,
  disabled = false,
}) => {
  useEffect(() => {
    setValue(`${name}` as const, defaultValue);
  }, [defaultValue]);

  return (
    <GBG.FormGroup type={GBG.FormGroupType.Checkbox} className={className}>
      <GBG.Switch {...register(`${name}` as const, { onChange })} data-testid={name} disabled={disabled} />

      <GBG.FormGroupInfo>
        <GBG.Label>
          <GBG.LabelText>
            <div className="space__between">
              <FormattedMessage id={id} tagName="p" />
            </div>
          </GBG.LabelText>
        </GBG.Label>

        {hasAssistiveText && (
          <GBG.Assistive>
            <FormattedMessage id={`${id}.details`} />
          </GBG.Assistive>
        )}
      </GBG.FormGroupInfo>
    </GBG.FormGroup>
  );
};

export default memo(SwitchInput);
