import { IOrganisationReducer } from '../../interfaces/IStore';

export const initOrganisationState: IOrganisationReducer = {
  selectedItem: { gbgCompanyId: '', UserRoles: [], gbgDepartmentId: '', gbgUseCaseText: '', username: '' },
  dataItems: { embedded: { entries: [] }, size: 0 },
  selectedDatasources: [],
  availableDatasources: [],
  selectedTab: 'Details',
  selectedChild: '',
  dropdownData: { gbgName: '', gbgDeptId: '' },
  initialItems: { embedded: { entries: [] }, size: 0 },
  refreshNeeded: false,
  availableFeatures: [],
};
