import { memo } from 'react';

import './App.css';
import './assets/style/_imports.scss';
import Navigation from './components/layout/Navigation';
import Content from './components/layout/Content';
import Modal from './components/modal/Modal';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { login, selectPing } from './features/ping/pingSlice';
import { authorize, parseHash } from './features/ping/utils';
import { getCustomerViewPermissionScopes } from './auth/customerViewAccess';
import { SCOPES } from './auth/permissions';
import jwt_decode from 'jwt-decode';
import { Unauthorised } from './pages/Unauthorised';

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const { accessToken, isAuthenticated } = useAppSelector(selectPing);
  const { permissionScope, organisationId } = getCustomerViewPermissionScopes();
  let tokenIsExpred = !accessToken || accessToken === 'EMPTY';
  let decodedUserToken: any = null;
  if (accessToken) {
    decodedUserToken = jwt_decode(accessToken);
    if (decodedUserToken.exp < Date.now() / 1000) tokenIsExpred = true;
  }

  if (!isAuthenticated || tokenIsExpred) {
    const at = parseHash();
    if (at) {
      dispatch(login(at));
    } else {
      if (!(window as any).loggedOut) {
        authorize();
      }
    }
    return <p>Redirecting...</p>;
  }

  return permissionScope === SCOPES.None ? (
    <Unauthorised />
  ) : (
    <div className={permissionScope == SCOPES.FullAccess ? 'structure' : 'full-width'} data-testid="app">
      <Navigation user={decodedUserToken} permissionScope={permissionScope} />
      <Content permissionScope={permissionScope} organisationId={organisationId} />
      <Modal />
    </div>
  );
};

export default memo(App);
