import { memo } from 'react';

import Breadcrumb from '../../components/layout/Breadcrumb';
import DataSourceForm from './DataSourceForm';

const NewDataSourcePage: React.FC = () => {
  return (
    <>
      <Breadcrumb />
      <DataSourceForm isCreateMode={true} />
    </>
  );
};

export default memo(NewDataSourcePage);
