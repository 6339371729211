import { memo, useCallback } from 'react';
import GBG from '@gbg/gbgcomponentlibrary_react';
import { FormattedMessage } from 'react-intl';
import { useAppDispatch } from '../../../app/hooks';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import { useUpdateLicenceMutation } from '../../../api/licences';
import { getToastSuccess } from '../../../utils/getToastOptions';
import { useHistory } from 'react-router-dom';
import { OrganisationTabs } from '../../../constants/tabs';
import { ModalContentType } from '../../../features/modal/config';

const PromptUpdateLicence = ({ data }: { data: { licence: ILicenceUpdate; error: IApiError } }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [updateLicence, { isLoading }] = useUpdateLicenceMutation();

  const handleUpdate = useCallback(
    () =>
      updateLicence({ ...data.licence, isForce: true })
        .unwrap()
        .then(() => {
          GBG.toast(getToastSuccess());
          dispatch(closeModal());
          history.push(`/organisations/${data.licence.gbgCompanyId}?tab=${OrganisationTabs.LICENCES}`);
        })
        .catch(error => {
          dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
        }),
    [data, updateLicence, dispatch, closeModal, openModal],
  );
  const handleClose = useCallback(() => dispatch(closeModal()), []);

  return (
    <>
      <p>{data.error?.message}</p>
      {data.error?.details?.map((detail, index) => (
        <p key={index}>{detail.message}</p>
      ))}

      <GBG.Button
        kind={GBG.ButtonKind.Secondary}
        className="m-m-t-2 m-m-r-1"
        onClick={handleClose}
        data-testid="cancel"
      >
        <FormattedMessage id="btn.cancel" />
      </GBG.Button>

      <GBG.Button
        worker={true}
        active={isLoading}
        kind={GBG.ButtonKind.Primary}
        onClick={handleUpdate}
        data-testid="update"
      >
        <FormattedMessage id="label.update" />
      </GBG.Button>
    </>
  );
};

export default memo(PromptUpdateLicence);
