import GBG from '@gbg/gbgcomponentlibrary_react';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useGetFunctionsByRoleIdQuery, useGetAvailableFunctionsByRoleIdQuery } from '../../../api/functions';
import { useUpdateRoleMutation } from '../../../api/roles';
import { useAppDispatch } from '../../../app/hooks';
import { TOAST_SUCCESS } from '../../../constants';
import { ModalContentType } from '../../../features/modal/config';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import LicenceFunctionsList from '../Licences/FunctionsList';
interface IRoleFeatureProps {
  role: IDepartmentRole;
  baseCreateEditPageURL: string;
}
const RoleFeatures: React.FC<IRoleFeatureProps> = ({ role, baseCreateEditPageURL }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [selectedFunctions, setSelectedFunctions] = useState<string[]>([]);
  const [updateRole, { isLoading }] = useUpdateRoleMutation();
  const {
    data: assignedFeatures,
    isFetching,
    isError,
    error,
  } = useGetFunctionsByRoleIdQuery({ orgId: role.gbgCompanyId, roleId: role.gbgRoleID });

  const {
    data: functions,
    isError: isErrorFunc,
    isFetching: isFetchingFunc,
    error: errorFunc,
  } = useGetAvailableFunctionsByRoleIdQuery({ orgId: role.gbgCompanyId, roleId: role.gbgRoleID });

  useEffect(() => {
    if (!isErrorFunc && !isError) dispatch(closeModal());
    else dispatch(openModal({ type: ModalContentType.API_ERROR, data: error || errorFunc }));
  }, [isError, isErrorFunc, error, errorFunc]);

  const onFormSubmit = useCallback(() => {
    const previsoulyAssignedFeatures = assignedFeatures ? assignedFeatures.map(x => x.gbgFunctionID) : [];
    const updatedRole = { ...role, gbgFunctionId: [...previsoulyAssignedFeatures, ...selectedFunctions] };
    updateRole(updatedRole)
      .unwrap()
      .then(() => {
        GBG.toast(TOAST_SUCCESS);
        history.push(baseCreateEditPageURL);
      })
      .catch(error => dispatch(openModal({ type: ModalContentType.API_ERROR, data: error })));
  }, [role, selectedFunctions, dispatch, baseCreateEditPageURL]);

  return isFetchingFunc || isFetching || !role || !functions ? (
    <div className="text-center p-5">
      <GBG.Spinner data-testid="spinner" />
    </div>
  ) : (
    <>
      <FormattedMessage id="role.feature.to.btn" tagName="h1" values={{ value: role.gbgName }} />
      <LicenceFunctionsList setSelectedFuncIds={setSelectedFunctions} functions={functions} showMessage={false} />
      {selectedFunctions.length > 0 && (
        <GBG.Button
          type="button"
          kind={GBG.ButtonKind.Primary}
          className="m-m-r-2"
          onClick={onFormSubmit}
          active={isLoading}
        >
          {isLoading && <GBG.Spinner data-testid="spinner" className="white-spinner" />}
          <FormattedMessage id="role.feature.btn" />
        </GBG.Button>
      )}
      <GBG.Button
        type="button"
        kind={GBG.ButtonKind.Secondary}
        className="m-m-r-2"
        onClick={() => {
          history.push(baseCreateEditPageURL);
        }}
      >
        <FormattedMessage id="users.roles.cancel" />
      </GBG.Button>
    </>
  );
};

export default memo(RoleFeatures);
