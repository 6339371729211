import { memo, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PAGE_SIZE } from '../../../../constants';
import { useAppDispatch } from '../../../../app/hooks';
import { ModalContentType } from '../../../../features/modal/config';
import { useGetUsersQuery } from '../../../../api/users';
import { closeModal, openModal } from '../../../../features/modal/modalSlice';
import Table from '../../../../components/table/Table';
import TempPasswordModal from '../../Users/TempPasswordModal';
import userListOverflowActions from '../../../../hooks/userListOverflowActions';
import { useGetDepartmentsQuery } from '../../../../api/departments';
import ChangeUserDepartmentModal from '../../Users/ChangeUserDepartmentModal';

interface IDepartmentUsersList {
  orgId: string;
  departId: string;
}

const DepartmentUsersList: React.FC<IDepartmentUsersList> = ({ orgId, departId }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const headers = ['fullName', 'lastSignOn.at', 'enabled', 'gbgDepartmentName'];
  const [search, setSearch] = useState<string>('');
  const [cursor, setCursor] = useState<string>('');
  const [passwordUser, setPasswordUser] = useState<IUser>();
  const [moveDepartmentUser, setMoveDepartmentUser] = useState<IUser | undefined>();
  const { data: allOrgDepartments } = useGetDepartmentsQuery(orgId);

  const { data, isFetching, isError, error } = useGetUsersQuery({
    organisationId: orgId,
    departmentId: departId,
    search,
    cursor,
    limit: PAGE_SIZE,
  });

  useEffect(() => {
    if (!isError) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
    }
  }, [isError, error]);

  const onItemClicked = useCallback(
    (item: IUser) => history.push(`/organisations/${orgId}/departments/${departId}/users/${item.id}`),
    [orgId, departId],
  );

  const { actions } = userListOverflowActions({
    dispatch,
    setPasswordUser,
    setMoveDepartmentUser,
    departments: allOrgDepartments,
  });

  const handlePasswordModalClose = useCallback(() => {
    setPasswordUser(undefined);
  }, []);

  return (
    <>
      <Table
        addUrl={`/organisations/${orgId}/departments/${departId}/users/new-user`}
        {...{ headers, data, isFetching, onItemClicked, actions, setSearch, setCursor }}
      ></Table>
      <TempPasswordModal user={passwordUser} onClose={handlePasswordModalClose}></TempPasswordModal>
      <ChangeUserDepartmentModal
        user={moveDepartmentUser}
        departments={allOrgDepartments}
        dispatch={dispatch}
        setUser={setMoveDepartmentUser}
      />
    </>
  );
};

export default memo(DepartmentUsersList);
