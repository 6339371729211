import { useSelector } from 'react-redux';
import { IModal } from '../interfaces/IModal';
import { IStore } from '../interfaces/IStore';

export const getModalMessages = () => {
  const item: any = useSelector((state: IStore) => state.organisation.selectedItem);
  const { gbgName, gbgUseCaseText } = item;

  const MODAL_MESSAGES: IModal = {
    organisations: {
      title: `Are you sure you want to remove the "${gbgName}" organisation?`,
      subtitle: 'This organisation will not be able to access GBG services',
      buttons: ['Cancel', 'Remove organisation'],
    },
    departments: {
      title: `Are you sure you want to remove the "${gbgName}" department?`,
      subtitle: 'This department will not be able to access GBG services',
      buttons: ['Cancel', 'Remove department'],
    },
    licences: {
      title: `Are you sure you want to remove the "${gbgName}" application?`,
      subtitle: 'This application will not be able to access GBG services',
      buttons: ['Cancel', 'Remove application'],
    },
    application: {
      title: `Are you sure you want to remove the "${gbgName}" application?`,
      subtitle: 'This application will not be able to access GBG services',
      buttons: ['Cancel', 'Remove application'],
    },
    clients: {
      title: `Are you sure you want to remove the "${gbgName}" client?`,
      subtitle: 'This client will not be able to access GBG services',
      buttons: ['Cancel', 'Remove client'],
    },
    errorData: {
      title: 'Error',
      subtitle: 'Unable to add',
      buttons: ['Close'],
    },
    errorChildren: {
      title: `Can't remove ${item}`,
      subtitle: `Unable to remove this ${item} due to existing dependencies`,
      buttons: ['Close'],
    },
    users: {
      title: 'Remove a team member?',
      subtitle: 'This team member will not be able to access to your account',
      buttons: ['Cancel', 'Remove user'],
    },
    usecases: {
      title: `Are you sure you want to remove the "${gbgUseCaseText}" use case?`,
      subtitle: 'This use case will not be able to access GBG services',
      buttons: ['Cancel', 'Remove use case'],
    },
    roles: {
      title: `Are you sure you want to remove the "${gbgName || gbgUseCaseText}"?`,
      subtitle: 'This role will not be able to access GBG services',
      buttons: ['Cancel', 'Remove'],
    },
    functions: {
      title: `Are you sure you want to remove the "${gbgName}" function?`,
      subtitle: 'This function will not be able to access GBG services',
      buttons: ['Cancel', 'Remove function'],
    },
    datasources: {
      title: `Are you sure you want to remove the "${gbgName}" data source?`,
      subtitle: 'This data source will not be able to access GBG services',
      buttons: ['Cancel', 'Remove data source'],
    },
  };

  return MODAL_MESSAGES;
};
