import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetUseCaseListQuery } from '../../../api/useCase';
import { useAppDispatch } from '../../../app/hooks';
import Table from '../../../components/table/Table';
import { PAGE_SIZE } from '../../../constants';
import { ModalContentType } from '../../../features/modal/config';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import { IUseCase } from '../../../interfaces/models/IUseCase';
import { customerViewPermissions } from '../../../auth/customerViewAccess';

export const OrganisationUseCaseList: FC<{ companyId: string }> = ({ companyId }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const headers = ['gbgUseCaseText'];
  const [search, setSearch] = useState<string>('');
  const [cursor, setCursor] = useState<string>('');
  const { data, isFetching, isError, error } = useGetUseCaseListQuery({
    paginatedRequest: { search, cursor, limit: PAGE_SIZE },
    organisationId: companyId,
  });

  const onItemClicked = useCallback(
    (item: IUseCase) => {
      history.push(`/organisations/${companyId}/UseCases/${item.gbgUseCaseID}`);
    },
    [companyId],
  );

  useEffect(() => {
    if (!isError) dispatch(closeModal());
    else dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
  }, [isError, error]);

  const actions = useCallback(
    (item: IUseCase) => [
      {
        title: 'Remove',
        danger: true,
        action: (dataItem: IUseCase) => {
          dispatch(
            openModal({
              type: ModalContentType.REMOVE_USECASE,
              data: item || dataItem,
            }),
          );
        },
      },
    ],
    [],
  );

  return (
    <Table
      title="useCase.title"
      addUrl={`/organisations/${companyId}/UseCases/new-use-case`}
      permissions={customerViewPermissions}
      {...{ headers, data, isFetching, onItemClicked, actions, setSearch, setCursor }}
    />
  );
};

export default memo(OrganisationUseCaseList);
