import React, { memo, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetLicencesQuery } from '../../../api/licences';
import { useAppDispatch } from '../../../app/hooks';
import { customerViewPermissions } from '../../../auth/customerViewAccess';
import Table from '../../../components/table/Table';
import { PAGE_SIZE } from '../../../constants';
import { ModalContentType } from '../../../features/modal/config';
import { closeModal, openModal } from '../../../features/modal/modalSlice';

const LicencesList: React.FC<ILicenceList> = ({ orgId, departId }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const headers = ['gbgName', 'gbgLicenceStatus', 'seats'];
  const [search, setSearch] = useState<string>('');
  const [cursor, setCursor] = useState<string>('');
  const { data, isFetching, isError, error } = useGetLicencesQuery({
    organisationId: orgId,
    departmentId: departId,
    search,
    cursor,
    limit: PAGE_SIZE,
  });

  const onItemClicked = useCallback(
    (item: ILicence) =>
      history.push(
        `/organisations/${orgId}/${departId ? `departments/${departId}/` : ''}licences/${item.gbgLicenceId}`,
        item,
      ),
    [],
  );

  useEffect(() => {
    if (!isError) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
    }
  }, [isError, error]);

  const actions = useCallback(
    (item: ILicence) => [
      {
        title: 'Remove',
        danger: true,
        action: (dataItem: ILicence) => {
          dispatch(
            openModal({
              type: ModalContentType.REMOVE_LICENCE,
              data: item || dataItem,
            }),
          );
        },
      },
    ],
    [],
  );

  return (
    <Table
      addUrl={`/organisations/${orgId}/${departId ? `departments/${departId}/` : ''}licences/new-licence`}
      permissions={!departId ? customerViewPermissions : undefined}
      {...{
        headers,
        data,
        isFetching,
        onItemClicked,
        actions,
        setSearch,
        setCursor,
      }}
    />
  );
};

interface ILicenceList {
  orgId: string;
  departId?: string;
}

export default memo(LicencesList);
