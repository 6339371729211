import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

import { RootState } from '../app/store';
import { selectLanguage } from '../features/language/languageSlice';

const mapStateToProps = (state: RootState) => {
  const {
    current: { value },
    messages,
  } = selectLanguage(state);

  return { locale: value, messages };
};

export default connect(mapStateToProps)(IntlProvider);
