import GBG from '@gbg/gbgcomponentlibrary_react';
import { FC, memo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../../app/hooks';
import { useGetClientListQuery } from '../../../api/client';
import Table from '../../../components/table/Table';
import { ModalContentType } from '../../../features/modal/config';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import { useIntl } from 'react-intl';

export const ClientsListPage: FC<{ orgId: string }> = ({ orgId }) => {
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const headers = ['gbgName', 'gbgClientId'];
  const { data, isFetching, isError, error } = useGetClientListQuery({ gbgCompanyId: orgId });

  const onItemClicked = useCallback((item: IClient) => {
    history.push(`/organisations/${orgId}/Clients/${item.gbgClientId}`);
  }, []);

  useEffect(() => {
    if (!isError) dispatch(closeModal());
    else dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
  }, [isError, error]);

  const actions = useCallback(
    (item: IClient) => [
      {
        title: intl.formatMessage({ id: 'btn.regenerateSecret' }),
        action: (dataItem: IClient) => {
          dispatch(
            openModal({
              type: ModalContentType.REGENERATE_SECRET,
              data: item || dataItem,
            }),
          );
        },
      },
    ],
    [intl],
  );

  return isFetching || !data ? (
    <div className="text-center p-5">
      <GBG.Spinner data-testid="spinner" />
    </div>
  ) : (
    <Table
      title="clients.title"
      addUrl={`/organisations/${orgId}/clients/new-client`}
      searchFields={headers}
      isPaginated={false}
      data={{ embedded: { entries: data ?? [] }, size: data?.length ?? 0 }}
      {...{ headers, isFetching, onItemClicked, actions }}
    />
  );
};
export default memo(ClientsListPage);
