export const PAGE_SIZE = 10;

export { TOAST_SUCCESS } from './toast';
export {
  ORGANISATION_FORM_FIELDS,
  APPLICATION_FORM_FIELDS,
  DEPARTMENT_FORM_FIELDS,
  FUNCTION_FORM_FIELDS,
  FUNCTION_IMPORT_FORM_FIELDS,
} from './form';
export { USER_FIELDS, EDIT_USER_FIELDS } from './formData';
