import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import { FUNCTION_IMPORT_FORM_FIELDS, TOAST_SUCCESS } from '../../../constants';
import { ModalContentType } from '../../../features/modal/config';
import { useAppDispatch } from '../../../app/hooks';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import { useGetAppFunctionsQuery, usePatchAddFunctionMutation } from '../../../api/functions';
import Form from '../../../components/forms/Form';

interface IFunctionImportForm {
  appId: string;
}

const FunctionImportForm: React.FC<IFunctionImportForm> = ({ appId }) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [addFunction, { isLoading: isAdding }] = usePatchAddFunctionMutation();
  const { data: importFuncs, isError, error } = useGetAppFunctionsQuery({ appId, isToImport: true });

  useEffect(() => {
    if (!isError) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
    }
  }, [isError, error, closeModal, openModal]);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const fields = useMemo<IFormField[]>(() => {
    const res = _.cloneDeep(FUNCTION_IMPORT_FORM_FIELDS);

    res[0].options = [{ label: intl.formatMessage({ id: 'form.select' }), value: '' }];
    if (importFuncs) {
      res[0].options = [
        ...res[0].options,
        ...importFuncs.map(item => ({ label: item.gbgName, value: item.gbgFunctionID })),
      ];
    }

    return res;
  }, [importFuncs, intl.locale]);

  const onSubmit = useCallback(
    handleSubmit(async ({ importFuncID }: { importFuncID: string }) => {
      try {
        const res = await addFunction({ appId, id: importFuncID }).unwrap();
        GBG.toast(TOAST_SUCCESS);
        history.push(`/applications/${appId}/functions/${res.gbgFunctionID}`);
      } catch (err) {
        dispatch(openModal({ type: ModalContentType.API_ERROR, data: err }));
      }
    }),
    [handleSubmit, addFunction, appId, openModal],
  );

  return (
    <>
      <FormattedMessage id="function.new-title" tagName="h1" />

      <Form {...{ onSubmit, fields, register, errors, setValue, isBusy: isAdding, isNew: true }} />
    </>
  );
};

export default memo(FunctionImportForm);
