export const ORGANISATION_FORM_FIELDS: IFormField[] = [
  {
    fieldType: 'textInput',
    inputName: 'gbgName',
    label: 'form.name',
    required: true,
  },
  {
    fieldType: 'textarea',
    inputName: 'gbgDescription',
    label: 'form.description',
    optional: true,
    assistiveText: 'organisation.form.description.details',
  },
  {
    fieldType: 'checkBox',
    inputName: 'gbgIsReseller',
    label: 'organisation.form.reseller',
    required: true,
  },
  {
    fieldType: 'select',
    inputName: 'gbgSoldById',
    label: 'organisation.form.childAccount',
    required: false,
    optional: true,
    assistiveText: true,
  },
  {
    fieldType: 'select',
    inputName: 'gbgMFAType',
    label: 'organisation.form.gbgMFAType',
    required: false,
    defaultValue: 'ROLE_BASED',
    options: [
      { label: 'Role Based', value: 'ROLE_BASED' },
      { label: 'Never', value: 'NEVER' },
      { label: 'Always', value: 'ALWAYS' },
      { label: 'Enforce', value: 'ENFORCE' },
    ],
  },
];

export const APPLICATION_FORM_FIELDS: IFormField[] = [
  {
    fieldType: 'textInput',
    inputName: 'gbgName',
    label: 'form.name',
    required: true,
  },
  {
    fieldType: 'textarea',
    inputName: 'gbgDescription',
    label: 'form.description',
    optional: true,
    assistiveText: 'application.form.description.details',
  },
];

export const FUNCTION_FORM_FIELDS: IFormField[] = [
  {
    fieldType: 'textInput',
    inputName: 'gbgFunctionID',
    label: 'form.id',
    required: true,
  },
  {
    fieldType: 'textInput',
    inputName: 'gbgName',
    label: 'form.name',
    required: true,
  },
  {
    fieldType: 'textarea',
    inputName: 'gbgDescription',
    label: 'form.description',
    required: false,
    assistiveText: 'form.description.function.details',
    optional: true,
  },
  {
    fieldType: 'switch',
    inputName: 'gbgDualAuth',
    label: 'function.form.dual.auth',
    required: true,
  },
  {
    fieldType: 'switch',
    inputName: 'gbgMFARequired',
    label: 'form.requires.mfa',
  },
  {
    fieldType: 'select',
    inputName: 'gbgFunctionStatus',
    label: 'form.status',
    options: [
      { label: 'Active', value: 'Active' },
      { label: 'Suspended', value: 'Suspended' },
    ],
    defaultValue: 'Active',
    required: true,
  },
];

export const FUNCTION_IMPORT_FORM_FIELDS: IFormField[] = [
  {
    fieldType: 'select',
    inputName: 'importFuncID',
    label: 'function.form.function',
    required: true,
  },
];

export const DEPARTMENT_FORM_FIELDS: IFormField[] = [
  {
    fieldType: 'textInput',
    inputName: 'gbgName',
    label: 'form.name',
    required: true,
  },
  {
    fieldType: 'textarea',
    inputName: 'gbgDescription',
    label: 'form.description',
    optional: true,
    assistiveText: 'department.form.description.details',
  },
];

export const USECASE_FORM_FIELDS: IFormField[] = [
  {
    fieldType: 'autoCompleteInput',
    inputName: 'gbgUseCaseText',
    label: 'form.useCase.type',
    required: true,
  },
  {
    fieldType: 'textarea',
    inputName: 'gbgDescription',
    label: 'form.description',
    assistiveText: 'form.description.useCase.details',
    optional: true,
  },
  {
    fieldType: 'dropzone',
    inputName: 'gbgSelectedDatasource',
    label: 'dataSources.title',
  },
];
