import { memo, useEffect, useState } from 'react';
import GBG from '@gbg/gbgcomponentlibrary_react';
import { useHistory, useParams } from 'react-router-dom';
import { useGetAvailableApplicationsQuery } from '../../../api/applications';
import { useGetOrganisationQuery } from '../../../api/organisations';
import Breadcrumb from '../../../components/layout/Breadcrumb';
import { useAppDispatch } from '../../../app/hooks';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import { ModalContentType } from '../../../features/modal/config';
import { TableProps, TableSelectionType } from '@gbg/gbgcomponentlibrary_react/build/Atoms/Tables/Table/Table.types';
import { FormattedMessage } from 'react-intl';
import Spinner from '../../../components/common/Spinner';
import { useGetDepartmentQuery } from '../../../api/departments';

const NewLicencePage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [selection, setSelection] = useState<IApplication>();
  const { orgId, departId } = useParams<{ orgId: string; departId: string }>();
  const { data: items, isFetching, isError, error } = useGetAvailableApplicationsQuery({ orgId, departId });
  const { data: department, isFetching: isDepartFetching } = useGetDepartmentQuery(
    { orgId, departId },
    { skip: !departId },
  );
  const { data: organisation } = useGetOrganisationQuery(orgId);

  useEffect(() => {
    if (!isError) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
    }
  }, [isError, error]);

  const dataSet: TableProps<IApplication> = {
    dataSet: {
      loading: isFetching,
      selectable: true,
      selectionType: 'Single' as TableSelectionType,
      data: items ?? [],
      headerContent: new Map<string, any>([['gbgName', <FormattedMessage id="table.header.gbgName" />]]),
      cellFilter: ['gbgName', '', ''],
      onSelectionChanged: (selectedItems: IApplication[]) => {
        setSelection(selectedItems[0]);
      },
    },
  };

  const onClickAction = () => {
    history.push(
      `/organisations/${orgId}/${departId ? `departments/${departId}/` : ''}licences/new-licence-details?appId=${
        selection?.gbgApplicationId
      }`,
    );
  };

  return (
    <>
      {organisation && !isDepartFetching && (
        <Breadcrumb
          params={{
            [orgId]: organisation.gbgName,
            [departId]: department?.gbgName ?? '',
          }}
        />
      )}

      {isFetching ? (
        <Spinner />
      ) : (
        <>
          <GBG.Table className="p-5 mt-1" {...dataSet}></GBG.Table>
          <GBG.Button
            onClick={onClickAction}
            kind={GBG.ButtonKind.Primary}
            className="mt-3"
            disabled={selection ? false : true}
          >
            <FormattedMessage id="btn.add" />
          </GBG.Button>
        </>
      )}
    </>
  );
};

export default memo(NewLicencePage);
