import { AnyAction } from 'redux';
import { IOrganisationReducer } from '../../interfaces/IStore';
import {
  SET_SELECTED_ITEM,
  SET_DATA_ITEMS,
  SET_SELECTED_DATASOURCES,
  SET_AVAILABLE_DATASOURCES,
  RESET_SELECTED_DATA_SOURCES,
  RESET_AVAILABLE_DATA_SOURCES,
  SET_SELECTED_TAB,
  SET_SELECTED_CHILD,
  SET_DROPDOWN_DATA,
  SET_INITIAL_ITEMS,
  SET_REFRESH_NEEDED,
  SET_AVAILABLE_FEATURES,
} from '../actions/actionTypes';
import { initOrganisationState } from '../initialState/organisation';

/**
 * Organisation reducer
 *
 * @param state
 * @param action
 */
export default (state: IOrganisationReducer = initOrganisationState, action: AnyAction) => {
  switch (action.type) {
    case SET_SELECTED_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
      };
    case SET_DATA_ITEMS:
      return {
        ...state,
        dataItems: action.payload,
      };

    case SET_SELECTED_DATASOURCES:
      return {
        ...state,
        selectedDatasources: action.payload,
      };
    case SET_AVAILABLE_DATASOURCES:
      return {
        ...state,
        availableDatasources: action.payload,
      };
    case RESET_SELECTED_DATA_SOURCES: {
      return {
        ...state,
        selectedDatasources: state.selectedDatasources.map(item => (item ? { ...item, checked: false } : item)),
      };
    }
    case RESET_AVAILABLE_DATA_SOURCES: {
      return {
        ...state,
        availableDatasources: state.availableDatasources.map(item => (item ? { ...item, checked: false } : item)),
      };
    }

    case SET_SELECTED_TAB: {
      return {
        ...state,
        selectedTab: action.payload,
      };
    }
    case SET_SELECTED_CHILD: {
      return {
        ...state,
        selectedChild: action.payload,
      };
    }
    case SET_DROPDOWN_DATA: {
      return {
        ...state,
        dropdownData: action.payload,
      };
    }
    case SET_INITIAL_ITEMS: {
      return {
        ...state,
        initialItems: action.payload,
      };
    }
    case SET_REFRESH_NEEDED: {
      return {
        ...state,
        refreshNeeded: action.payload,
      };
    }
    case SET_AVAILABLE_FEATURES: {
      return {
        ...state,
        availableFeatures: action.payload,
      };
    }
    default:
      return state;
  }
};
