import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { routes } from '../../app/routes';
import { SCOPES } from '../../auth/permissions';
interface IContent {
  organisationId: string;
  permissionScope: string;
}
const Content: React.FC<IContent> = ({ permissionScope, organisationId }) => {
  return (
    <div className="page__content">
      <GBG.Card isPrimaryContent={true}>
        <GBG.CardBody isPrimaryContent={true}>
          <Switch>
            <Route exact path="/">
              <Redirect
                to={permissionScope !== SCOPES.FullAccess ? `/organisations/${organisationId}` : '/organisations'}
              />
            </Route>

            {permissionScope !== SCOPES.FullAccess && (
              <Route exact path="/organisations">
                <Redirect from="/organisations" to={`/organisations/${organisationId}`} />
              </Route>
            )}

            {routes.map(item => (
              <Route key={item.route} exact={item.exact} path={item.route} component={item.component} />
            ))}
            <Redirect to="/error/404" />
          </Switch>
        </GBG.CardBody>
      </GBG.Card>
    </div>
  );
};

export default memo(Content);
