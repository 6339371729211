import { memo, useCallback } from 'react';

import { useDeleteUserMutation } from '../../../api/users';
import RemoveModalContent from './RemoveModalContent';

interface IRemoveUser {
  userId: string;
  username: string;
  orgId: string;
}

const RemoveUser: React.FC<IRemoveUser> = ({ userId, orgId, username }) => {
  const [deleteUser, { isLoading: isDeleting }] = useDeleteUserMutation();
  const handleRemove = useCallback(() => deleteUser({ userId, orgId }).unwrap(), [userId]);

  return <RemoveModalContent {...{ name: username, handleRemove, isDeleting }} />;
};

export default memo(RemoveUser);
