import { memo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAppDispatch } from '../../app/hooks';
import { ModalContentType } from '../../features/modal/config';
import { useGetApplicationsQuery } from '../../api/applications';
import { closeModal, openModal } from '../../features/modal/modalSlice';
import Table from '../../components/table/Table';
import { SCOPES, userRoleTypes } from '../../auth/permissions';

const ApplicationsListPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const headers = ['gbgName'];
  const { data: entries, isFetching, isError, error } = useGetApplicationsQuery();
  const permissions = { [userRoleTypes.Application_Admin]: SCOPES.FullAccess };

  const onItemClicked = useCallback((item: IApplication) => history.push(`/applications/${item.gbgApplicationId}`), []);

  useEffect(() => {
    if (!isError) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
    }
  }, [isError, error]);

  const actions = useCallback(
    (item: IApplication) => [
      {
        title: 'Remove',
        danger: true,
        action: (dataItem: IApplication) => {
          dispatch(
            openModal({
              type: ModalContentType.REMOVE_APPLICATION,
              data: item || dataItem,
            }),
          );
        },
      },
    ],
    [],
  );

  return (
    <Table
      title="application.title"
      addUrl="/applications/new-application"
      isPaginated={false}
      searchFields={headers}
      data={{ embedded: { entries: entries ?? [] }, size: entries?.length ?? 0 }}
      {...{ headers, isFetching, onItemClicked, actions, permissions }}
    />
  );
};

export default memo(ApplicationsListPage);
