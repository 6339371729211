import GBG from '@gbg/gbgcomponentlibrary_react';
import React, { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../../../components/layout/Breadcrumb';
import { useGetOrganisationQuery } from '../../../../api/organisations';
import { closeModal, openModal } from '../../../../features/modal/modalSlice';
import { ModalContentType } from '../../../../features/modal/config';
import { useAppDispatch } from '../../../../app/hooks';
import { useGetRoleQuery } from '../../../../api/roles';
import { useGetDepartmentQuery } from '../../../../api/departments';
import RoleFeatures from '../../Roles/RoleFeatures';
import { useGetUserQuery } from '../../../../api/users';

const AddDepartmentRoleFeaturePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { orgId, departId, roleId, userId } = useParams<{
    orgId: string;
    departId: string;
    roleId: string;
    userId: string;
  }>();

  const {
    data: role,
    isError: isErrorRole,
    isFetching: isFetchingRole,
    error: errorRole,
  } = useGetRoleQuery({ orgId, roleId });
  const { data: org, isError: isErrorOrg, error: errorOrg } = useGetOrganisationQuery(orgId);
  const baseCreateEditPageURL = `/organisations/${orgId}/departments/${departId}${
    userId ? `/users/${userId}` : ''
  }/roles/${roleId}`;

  const { data: department, isError: isErrorDep, error: errorDep } = useGetDepartmentQuery({ orgId, departId });
  const { data: user, isError: isErrorUser, error: errorUser } = useGetUserQuery({ userId, orgId }, { skip: !userId });

  useEffect(() => {
    if (!isErrorOrg && !isErrorDep && !isErrorRole && !isErrorUser) dispatch(closeModal());
    else
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: errorOrg || errorDep || errorRole || errorUser }));
  }, [isErrorDep, isErrorOrg, isErrorRole, errorDep, errorOrg, errorRole, isErrorUser]);

  return isFetchingRole || !role ? (
    <div className="text-center p-5">
      <GBG.Spinner data-testid="spinner" />
    </div>
  ) : (
    <>
      {org && department && role && (!userId || user) && (
        <Breadcrumb
          params={{
            [orgId]: org.gbgName,
            [departId]: department.gbgName,
            [userId]: user?.username ?? '',
            [roleId]: role.gbgName,
          }}
        />
      )}
      <RoleFeatures role={role} baseCreateEditPageURL={baseCreateEditPageURL} />
    </>
  );
};

export default memo(AddDepartmentRoleFeaturePage);
