import { memo, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PAGE_SIZE } from '../../constants';
import { ModalContentType } from '../../features/modal/config';
import { useAppDispatch } from '../../app/hooks';
import { useGetOrganisationsQuery } from '../../api/organisations';
import { closeModal, openModal } from '../../features/modal/modalSlice';
import Table from '../../components/table/Table';

const OrganisationsListPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const headers = ['gbgName'];
  const [search, setSearch] = useState<string>('');
  const [cursor, setCursor] = useState<string>('');
  const { data, isFetching, isError, error, isUninitialized } = useGetOrganisationsQuery(
    { search, cursor, limit: PAGE_SIZE },
    { skip: search.length === 0 },
  );

  const onItemClicked = useCallback((item: IOrganisation) => history.push(`/organisations/${item.gbgCompanyId}`), []);

  useEffect(() => {
    if (!isError) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
    }
  }, [isError, error]);

  const actions = useCallback(
    (item: IOrganisation) => [
      {
        title: 'Remove',
        danger: true,
        action: (dataItem: IOrganisation) => {
          dispatch(
            openModal({
              type: ModalContentType.REMOVE_ORGANISATION,
              data: item || dataItem,
            }),
          );
        },
      },
    ],
    [],
  );

  return (
    <Table
      title="organisation.title"
      addUrl="/organisations/new-organisation"
      {...{ headers, data, isFetching, onItemClicked, actions, setSearch, setCursor, isUninitialized }}
    />
  );
};

export default memo(OrganisationsListPage);
