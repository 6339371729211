import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import { ORGANISATION_FORM_FIELDS, TOAST_SUCCESS } from '../../constants';
import { ModalContentType } from '../../features/modal/config';
import { useAppDispatch } from '../../app/hooks';
import { openModal } from '../../features/modal/modalSlice';
import {
  useAddOrganisationMutation,
  useGetResellersQuery,
  useUpdateOrganisationMutation,
} from '../../api/organisations';
import Form from '../../components/forms/Form';
import { getCustomerViewPermissionScopes } from '../../auth/customerViewAccess';
import { SCOPES } from '../../auth/permissions';

interface IOrganisationForm {
  orgData?: IOrganisation;
  isNew?: boolean;
}

const OrganisationForm: React.FC<IOrganisationForm> = ({ orgData, isNew = true }) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { permissionScope } = getCustomerViewPermissionScopes();
  const { data: resellers } = useGetResellersQuery({ organisationId: orgData?.gbgCompanyId, permissionScope });
  const [addOrganisation, { isLoading: isAdding }] = useAddOrganisationMutation();
  const [updateOrganisation, { isLoading: isUpdating }] = useUpdateOrganisationMutation();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const fields = useMemo<IFormField[]>(() => {
    let res = _.cloneDeep(ORGANISATION_FORM_FIELDS);

    if (resellers) {
      res[3].options = [
        { label: intl.formatMessage({ id: 'organisation.no-parent' }), value: '' },
        ...resellers
          .filter(item => item.gbgCompanyId !== orgData?.gbgCompanyId)
          .map(item => ({ label: item.gbgName, value: item.gbgCompanyId })),
      ];
    }

    if (permissionScope != SCOPES.FullAccess) {
      res = res.filter(x => x.inputName !== 'gbgMFAType');
    }

    return res;
  }, [resellers, intl.locale, orgData?.gbgCompanyId, permissionScope]);

  const onSubmit = useCallback(
    handleSubmit(async (data: Partial<IOrganisation>) => {
      try {
        const editOrganisation = isNew ? addOrganisation : updateOrganisation;
        const res = await editOrganisation({ ...data, gbgCompanyId: orgData?.gbgCompanyId }).unwrap();
        GBG.toast(TOAST_SUCCESS);
        history.push(`/organisations/${res.gbgCompanyId}`);
      } catch (err) {
        dispatch(openModal({ type: ModalContentType.API_ERROR, data: err }));
      }
    }),
    [handleSubmit],
  );

  return (
    <>
      {isNew && <FormattedMessage id="organisation.new-title" tagName="h1" />}

      <Form
        {...{
          onSubmit,
          fields,
          data: orgData,
          register,
          errors,
          setValue,
          isBusy: isAdding || isUpdating,
          isNew,
          permissionType: permissionScope,
        }}
      ></Form>
    </>
  );
};

export default memo(OrganisationForm);
