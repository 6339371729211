import GBG from '@gbg/gbgcomponentlibrary_react';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../app/hooks';
import { ModalContentType } from '../features/modal/config';
import { openModal } from '../features/modal/modalSlice';
import { setModalData, setModalVisibility } from '../store/actions/generalActions';
import { getToastSuccess } from '../utils/getToastOptions';
import FlowDownModal from './FlowDownModal';

const useFlowdownUpdate = ({
  orgId,
  departId,
  resourceName,
  resourceTabId,
  getUpdatingObjects,
  submitFunction,
  handleCancel,
}: IUseFlowdownUpdate) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const appDispatch = useAppDispatch();

  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [selectedLicencesForPromote, setSelectedLicencesForPromote] = useState<string[]>([]);
  const [affectedDetails, setAffectedDetails] = useState<IAffectedDetailDto>();
  const [errors, setErrors] = useState<IApiError>();

  const errorCallback = useCallback(
    (err: string | IApiError) => {
      setIsUpdating(false);
      const apiError = err as IApiError;
      setAffectedDetails(undefined);
      setErrors(apiError);
      dispatch(
        setModalData({
          type: 'error',
          subtitle: ' ',
        }),
      );
      dispatch(setModalVisibility(true));
    },
    [dispatch, setIsUpdating, setModalData, setModalVisibility],
  );

  const gotoDepartment = useCallback(
    (id: string, tabIndex: number) => {
      dispatch(setModalVisibility(false));
      history.push(`/organisations/${orgId}/Departments/${id}?tab=${tabIndex}`);
    },
    [dispatch, setModalVisibility, orgId, history],
  );

  const gotoCompany = useCallback(() => {
    history.push(`/organisations/${orgId}?tab=${resourceTabId}`);
  }, [history, orgId, resourceTabId]);

  const handleUpdate = useCallback(() => {
    const { data } = getUpdatingObjects();
    const dataObj: ILicenceUpdate = { ...data, ...{ flowDownRoleIds: selectedLicencesForPromote } };

    const callback = () => {
      setIsUpdating(false);
      GBG.toast(getToastSuccess());
      setErrors(undefined);
      setAffectedDetails(undefined);
      if (departId) {
        gotoDepartment(departId, resourceTabId);
      } else {
        gotoCompany();
      }
    };

    setIsUpdating(true);
    submitFunction(dataObj)
      .unwrap()
      .then(() => callback())
      .catch((error: any) => {
        if (error.code === '428') {
          setIsUpdating(false);
          appDispatch(openModal({ type: ModalContentType.PROMPT_UPDATE_LICENCE, data: { error, licence: dataObj } }));
        } else {
          setAffectedDetails(undefined);
          errorCallback(error);
        }
      });

    dispatch(setModalVisibility(false));
  }, [getUpdatingObjects, setIsUpdating, gotoDepartment, gotoCompany, errorCallback, selectedLicencesForPromote]);

  const flowdownCallback = useCallback(
    (res: IAffectedDetailDto) => {
      setIsUpdating(false);
      // if it does not affect any user or department on remove functions , update it directly
      if (!res?.dns?.length && !res?.affectedItems?.length) return handleUpdate();

      setAffectedDetails(res);
      setErrors(undefined);
      // if returns affectedItems with flowDownType Remove, means it must show confirm modal

      dispatch(
        setModalData({
          type: 'errorData',
          title: `Are you sure you want to update "${resourceName}"?`,
          subtitle: ' ',
        }),
      );
      dispatch(setModalVisibility(true));
    },
    [setIsUpdating, handleUpdate, dispatch, setModalData, setModalVisibility, resourceName, departId],
  );

  const createFlowDownModal = useCallback(() => {
    return (
      <FlowDownModal
        departId={departId}
        handleCancel={handleCancel}
        handleUpdate={handleUpdate}
        flowDownItems={affectedDetails}
        selectedLicencesForPromote={selectedLicencesForPromote}
        setSelectedLicencesForPromote={setSelectedLicencesForPromote}
        errors={errors}
        gotoDepartment={gotoDepartment}
      />
    );
  }, [
    resourceName,
    departId,
    handleCancel,
    handleUpdate,
    affectedDetails,
    selectedLicencesForPromote,
    setSelectedLicencesForPromote,
    errors,
    gotoDepartment,
  ]);

  return { isUpdating, setIsUpdating, createFlowDownModal, handleUpdate, flowdownCallback, errorCallback };
};

interface IUseFlowdownUpdate {
  orgId: string;
  departId?: string;
  resourceName: string;
  resourceTabId: number;
  getUpdatingObjects: () => { data: any };
  submitFunction: any;
  handleCancel?: () => void;
}

export default useFlowdownUpdate;
