import { adminApi } from '../app/api';

const endpoint = '/api/v1/department';
const customerViewEndPoint = '/api/v1/organisations';

const extendedApi = adminApi.injectEndpoints({
  endpoints: build => ({
    getDepartments: build.query<IDepartment[], string>({
      query: orgId => ({ url: `${customerViewEndPoint}/${orgId}/departments`, method: 'GET' }),
      providesTags: result =>
        result
          ? [
              ...result.map(({ gbgDeptId }) => ({ type: 'Departments', gbgDeptId } as const)),
              { type: 'Departments', id: 'LIST' },
            ]
          : [{ type: 'Departments', id: 'LIST' }],
    }),
    getDepartment: build.query<IDepartment, { departId: string; orgId: string }>({
      query: ({ orgId, departId }) => ({
        url: `${customerViewEndPoint}/${orgId}/departments/${departId}`,
        method: 'GET',
      }),
      providesTags: result => (result ? [{ type: 'Departments', gbgDeptId: result?.gbgDeptId }] : []),
    }),
    addDepartment: build.mutation<IDepartment, IDepartment>({
      query: data => ({ url: endpoint, method: 'POST', data }),
      invalidatesTags: [{ type: 'Departments', id: 'LIST' }],
    }),
    updateDepartment: build.mutation<IDepartment, IDepartment>({
      query: data => ({ url: `${endpoint}/${data.gbgDeptId}`, method: 'PUT', data }),
      invalidatesTags: result => (result ? [{ type: 'Departments', gbgDeptId: result?.gbgDeptId }] : []),
    }),
    deleteDepartment: build.mutation<string, string>({
      query: id => ({ url: `${endpoint}/${id}`, method: 'DELETE' }),
      invalidatesTags: gbgDeptId => (gbgDeptId ? [{ type: 'Departments', gbgDeptId }] : []),
    }),
  }),
});

export const {
  useGetDepartmentsQuery,
  useGetDepartmentQuery,
  useAddDepartmentMutation,
  useUpdateDepartmentMutation,
  useDeleteDepartmentMutation,
} = extendedApi;
