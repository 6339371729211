import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetOrganisationQuery } from '../../../api/organisations';
import { useAppDispatch } from '../../../app/hooks';
import Breadcrumb from '../../../components/layout/Breadcrumb';
import { ModalContentType } from '../../../features/modal/config';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import CreateEditClientForm from './CreateEditClientForm';

const NewClient: React.FC = () => {
  const { orgId } = useParams<{ orgId: string }>();
  const { data, isError, isFetching, error } = useGetOrganisationQuery(orgId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isError) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
    }
  }, [isError, error]);

  return isFetching || !data ? (
    <div className="text-center p-5">
      <GBG.Spinner data-testid="spinner" />
    </div>
  ) : (
    <>
      <Breadcrumb params={{ [orgId]: data.gbgName }} />
      <CreateEditClientForm isCreateMode={true} orgId={orgId} />
    </>
  );
};

export default memo(NewClient);
