import { FormattedMessage } from 'react-intl';
import GBG from '@gbg/gbgcomponentlibrary_react';
import { CLIENT_FIELDS } from '../../../constants/formData';
import { FC, memo, useCallback, useMemo } from 'react';
import Form from '../../../components/forms/Form';
import { useForm } from 'react-hook-form';
import { useAddClientMutation, useUpdateClientMutation } from '../../../api/client';
import { TOAST_SUCCESS } from '../../../constants';
import { openModal } from '../../../features/modal/modalSlice';
import { ModalContentType } from '../../../features/modal/config';
import { useAppDispatch } from '../../../app/hooks';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

interface IProps {
  client?: IClient;
  isCreateMode: boolean;
  orgId: string;
}
const CreateEditClientForm: FC<IProps> = ({ client, isCreateMode = true, orgId }) => {
  const history = useHistory();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [addClient, { isLoading: isAddFormSubmitting }] = useAddClientMutation();
  const [updateClient, { isLoading: isUpdateFormSubmitting }] = useUpdateClientMutation();
  const dispatch = useAppDispatch();

  const fields = useMemo<IFormField[]>(() => {
    if (!isCreateMode) return CLIENT_FIELDS;
    const formFields = _.cloneDeep(CLIENT_FIELDS);
    formFields.filter(x => x.inputName == 'gbgClientId')[0].shouldNotBeRendered = true;
    return formFields;
  }, [isCreateMode]);

  const onSubmit = useCallback(
    handleSubmit(data => {
      const formData: IClient = {
        gbgClientId: data.gbgClientId,
        gbgClientSecret: data.gbgClientSecret,
        gbgName: data.gbgName,
        gbgCompanyId: orgId,
      };

      (isCreateMode ? addClient : updateClient)(formData).then((result: any) => {
        if (result != null && result.error == null) {
          GBG.toast(TOAST_SUCCESS);
          if (isCreateMode) {
            history.push(`/organisations/${orgId}/clients/secret-client`, {
              secret: result.data.gbgClientSecret,
              clientId: result.data.gbgClientId,
            });
          }
        } else {
          dispatch(openModal({ type: ModalContentType.API_ERROR, data: result.error }));
        }
      });
    }),
    [client, orgId, isCreateMode, handleSubmit, addClient, updateClient],
  );

  return (
    <div className="m-m-t-2">
      {!client && <FormattedMessage id="clients.add" tagName="h1" />}
      <Form
        fields={fields}
        {...{
          onSubmit,
          data: client,
          register,
          errors,
          setValue,
          isBusy: isAddFormSubmitting || isUpdateFormSubmitting,
          isNew: isCreateMode,
          shouldDisplaySaveButton: isCreateMode,
        }}
      ></Form>
    </div>
  );
};

export default memo(CreateEditClientForm);
