import { memo, useCallback } from 'react';
import { useDeleteRoleMutation } from '../../../api/roles';

import RemoveModalContent from './RemoveModalContent';

const RemoveDepartmentRole = ({ departmentRole }: { departmentRole: IDepartmentRole }) => {
  const [deleteDepartmentRole, { isLoading: isDeleting }] = useDeleteRoleMutation();

  const handleRemove = useCallback(
    () => deleteDepartmentRole(departmentRole.gbgRoleID).unwrap(),
    [departmentRole.gbgRoleID],
  );

  return <RemoveModalContent name={departmentRole.gbgName} handleRemove={handleRemove} isDeleting={isDeleting} />;
};

export default memo(RemoveDepartmentRole);
