import { memo, useCallback } from 'react';
import { useDeleteLicenceMutation } from '../../../api/licences';

import RemoveModalContent from './RemoveModalContent';

const RemoveLicence = ({ licence }: { licence: ILicence }) => {
  const [deleteLicence, { isLoading: isDeleting }] = useDeleteLicenceMutation();

  const handleRemove = useCallback(
    () => deleteLicence({ licenceId: licence?.gbgLicenceId }).unwrap(),
    [licence?.gbgLicenceId],
  );

  return <RemoveModalContent name={licence?.gbgName} handleRemove={handleRemove} isDeleting={isDeleting} />;
};

export default memo(RemoveLicence);
