import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getCustomerViewPermissionScopes } from '../../auth/customerViewAccess';
import { SCOPES } from '../../auth/permissions';

import { toTitleCase } from '../../utils/general';

interface IBreadcrumb {
  params?: { [id: string]: string };
}

export interface ICrumbItem {
  title: string;
  link: JSX.Element;
}

const Breadcrumb: React.FC<IBreadcrumb> = ({ params = {} }) => {
  const { pathname } = useLocation();
  const { permissionScope, organisationId } = getCustomerViewPermissionScopes();
  const canRenderOrganisation = permissionScope === SCOPES.FullAccess;
  const crumbs = useMemo<ICrumbItem[]>(() => {
    const res: ICrumbItem[] = [];

    const paths = pathname.split('/').filter(x => x);
    if (!canRenderOrganisation && paths[0] === 'organisations' && (paths.length == 2 || paths[1] != organisationId))
      return res;

    paths.forEach((path, index) => {
      const isCategory = !index ? false : !params[path];
      if (index < paths.length - 1 && isCategory) return;

      const link = '/' + paths.slice(0, index + 1).join('/');
      if (!canRenderOrganisation && link.toLocaleLowerCase() === '/organisations') return;
      if (params[path]) {
        // params
        res.push({ title: params[path], link: <Link to={link}>{params[path]}</Link> });
      } else if (!index || (index === paths.length - 1 && (path.startsWith('new-') || path.startsWith('add-')))) {
        // first path || create new resource route
        res.push({ title: toTitleCase(path), link: <Link to={link}>{toTitleCase(path)}</Link> });
      }
    });

    return res;
  }, [pathname]);

  return <GBG.Breadcrumb crumbs={crumbs} />;
};

export default memo(Breadcrumb);
