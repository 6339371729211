import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetOrganisationQuery } from '../../../api/organisations';
import { useGetUseCaseTextQuery } from '../../../api/useCase';
import { useAppDispatch } from '../../../app/hooks';
import Breadcrumb from '../../../components/layout/Breadcrumb';
import { ModalContentType } from '../../../features/modal/config';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import CreateUseCaseForm from './CreateUseCaseForm';

const NewUseCasePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { orgId } = useParams<{ orgId: string }>();
  const { data: organisationDetails, isFetching, isError, error } = useGetOrganisationQuery(orgId);
  const {
    data: useCaseTexts,
    isError: isErrorUseCaseTexts,
    isFetching: isFetchingUseCaseTexts,
    error: errorUseCaseTexts,
  } = useGetUseCaseTextQuery();

  useEffect(() => {
    if (!isError && !isErrorUseCaseTexts) dispatch(closeModal());
    else dispatch(openModal({ type: ModalContentType.API_ERROR, data: error ?? errorUseCaseTexts }));
  }, [isError, error, isErrorUseCaseTexts, errorUseCaseTexts, dispatch]);

  return isFetching || !organisationDetails || isFetchingUseCaseTexts ? (
    <div className="text-center p-5">
      <GBG.Spinner data-testid="spinner" />
    </div>
  ) : (
    <>
      <Breadcrumb params={{ [orgId]: organisationDetails.gbgName }} />
      <CreateUseCaseForm orgId={orgId} useCaseTexts={useCaseTexts} />
    </>
  );
};

export default memo(NewUseCasePage);
