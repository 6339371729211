import GBG from '@gbg/gbgcomponentlibrary_react';

export const Unauthorised = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1>Sorry!</h1>
      <p className="heading-small m-m-t-2">But you don’t have permission to access this area.</p>
      <p className="paragraph-medium m-m-t-2 m-m-b-2">Error code: 403</p>
      <GBG.Button
        onClick={() => {
          document.location.href = 'https://www.gbgplc.com';
        }}
      >
        Return home
      </GBG.Button>
      <br />
      <a
        style={{
          display: 'block',
        }}
        className="paragraph-large m-m-t-2"
        href="https://www.gbgplc.com/en/contact/customer-support-enquiry/"
      >
        Get help
      </a>
    </div>
  );
};
