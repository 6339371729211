import { memo, useCallback } from 'react';

import { usePatchDeleteFunctionMutation } from '../../../api/functions';
import RemoveModalContent from './RemoveModalContent';

interface IRemoveFunction {
  appId: string;
  funcId: string;
  name: string;
}

const RemoveFunction: React.FC<IRemoveFunction> = ({ appId, funcId, name }) => {
  const [deleteFunction, { isLoading: isDeleting }] = usePatchDeleteFunctionMutation();

  const handleRemove = useCallback(() => deleteFunction({ appId, id: funcId }).unwrap(), [appId, funcId]);

  return <RemoveModalContent {...{ name, handleRemove, isDeleting }} />;
};

export default memo(RemoveFunction);
