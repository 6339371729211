import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { APPLICATION_FORM_FIELDS, TOAST_SUCCESS } from '../../constants';
import { ModalContentType } from '../../features/modal/config';
import { useAppDispatch } from '../../app/hooks';
import { openModal } from '../../features/modal/modalSlice';
import { useAddApplicationMutation, useUpdateApplicationMutation } from '../../api/applications';
import Form from '../../components/forms/Form';
import { SCOPES, userRoleTypes } from '../../auth/permissions';

interface IApplicationForm {
  appData?: IApplication;
  isNew?: boolean;
}

const ApplicationForm: React.FC<IApplicationForm> = ({ appData, isNew = true }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [addApplication, { isLoading: isAdding }] = useAddApplicationMutation();
  const [updateApplication, { isLoading: isUpdating }] = useUpdateApplicationMutation();
  const permissions = { [userRoleTypes.Application_Admin]: SCOPES.FullAccess };

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = useCallback(
    handleSubmit(async (data: Partial<IApplication>) => {
      try {
        const editApplication = isNew ? addApplication : updateApplication;
        const res = await editApplication({
          ...data,
          gbgApplicationId: appData?.gbgApplicationId,
        }).unwrap();
        GBG.toast(TOAST_SUCCESS);
        history.push(`/applications/${res.gbgApplicationId}`);
      } catch (err) {
        dispatch(openModal({ type: ModalContentType.API_ERROR, data: err }));
      }
    }),
    [handleSubmit],
  );

  return (
    <>
      {isNew && <FormattedMessage id="application.new-title" tagName="h1" />}

      <Form
        fields={APPLICATION_FORM_FIELDS}
        {...{ onSubmit, data: appData, register, errors, setValue, isBusy: isAdding || isUpdating, isNew, permissions }}
      ></Form>
    </>
  );
};

export default memo(ApplicationForm);
