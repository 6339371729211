import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserQuery } from '../../../api/users';
import { useGetOrganisationQuery } from '../../../api/organisations';
import { ModalContentType } from '../../../features/modal/config';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import Breadcrumb from '../../../components/layout/Breadcrumb';
import { useAppDispatch } from '../../../app/hooks';
import UserRoleForm from '../Roles/UserRoleForm';
import { useGetClientQuery } from '../../../api/client';
import { UserTabs } from '../../../constants/tabs';

const AddUserRolePage = () => {
  const dispatch = useAppDispatch();
  const { orgId, userId, clientId } = useParams<{ orgId: string; userId: string; clientId?: string }>();
  const { data: userDetails, isFetching, isError, error } = useGetUserQuery({ userId, orgId });
  const {
    data: client,
    isError: isErrorClient,
    error: errorClient,
  } = useGetClientQuery(clientId ?? '', { skip: !clientId });

  const baseCreateEditPageURL = `/organisations/${orgId}${clientId ? `/clients/${clientId}` : ''}/users/${userId}?tab=${
    UserTabs.ROLES
  }`;
  const {
    data: organisation,
    isError: isErrorOrg,
    error: errorOrg,
    isFetching: isFetchingOrg,
  } = useGetOrganisationQuery(orgId);

  useEffect(() => {
    if (!isErrorOrg && !isError && !isErrorClient) dispatch(closeModal());
    else dispatch(openModal({ type: ModalContentType.API_ERROR, data: errorOrg || error || errorClient }));
  }, [isErrorOrg, errorOrg, isError, error, isErrorClient, errorClient]);

  return isFetchingOrg || isFetching || !organisation || !userDetails ? (
    <div className="text-center p-5">
      <GBG.Spinner data-testid="spinner" />
    </div>
  ) : (
    <>
      <Breadcrumb
        params={{
          [orgId]: organisation.gbgName,
          [clientId ?? '']: client?.gbgName ?? '',
          [userId]: userDetails.username,
        }}
      />

      <UserRoleForm orgId={orgId} userDetails={userDetails} baseCreateEditPageURL={baseCreateEditPageURL} />
    </>
  );
};

export default memo(AddUserRolePage);
