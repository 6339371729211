export const gbgDeptList = [{ gbgName: 'Product' }, { gbgName: 'Technology' }];

export const STATUS_MOCKED = [
  { gbgName: 'Active', gbgDataSourceStatus: 'Active' },
  { gbgName: 'Suspended', gbgDataSourceStatus: 'Suspended' },
];

export const FUNCTION_STATUS = [
  { gbgName: 'Active', gbgFunctionStatus: 'Active' },
  { gbgName: 'Suspended', gbgFunctionStatus: 'Suspended' },
];

export const LICENCE_STATUS: ISelectOption[] = [
  { label: 'Trial', value: 'Trial' },
  { label: 'Internal', value: 'Internal' },
  { label: 'Live', value: 'Live' },
  { label: 'Suspended', value: 'Suspended' },
  { label: 'Expired', value: 'Expired' },
];
