import { memo, useMemo } from 'react';

import { useGetDepartmentQuery } from '../../../api/departments';
import UserForm from './UserForm';

interface IUserDetails {
  user: IUser;
  clientId?: string;
}

const UserDetails: React.FC<IUserDetails> = ({ user, clientId }) => {
  const { data: department } = useGetDepartmentQuery(
    { orgId: user.gbgCompanyId, departId: user.gbgDepartmentId },
    { skip: !user.gbgDepartmentId },
  );

  const departmentOptions = useMemo<ISelectOption[]>(
    () => [{ label: department?.gbgName ?? '', value: user.gbgDepartmentId }],
    [department, user],
  );

  return (
    <>
      <UserForm
        orgId={user.gbgCompanyId}
        isNew={false}
        userData={user}
        departmentOptions={departmentOptions}
        clientId={clientId}
      />
    </>
  );
};

export default memo(UserDetails);
