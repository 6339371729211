import GBG from '@gbg/gbgcomponentlibrary_react';
import React, { useEffect } from 'react';
import { FieldValues, MultipleFieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

interface ITextInput {
  id: string;
  name: string;
  register: UseFormRegister<FieldValues>;
  defaultValue?: string;
  setValue: UseFormSetValue<FieldValues>;
  errors: { [fieldName: string]: { type: string; message?: string; types: MultipleFieldErrors } };
  required?: boolean;
  disabled?: boolean;
  optional?: boolean;
  assistiveText?: boolean;
}

const TextInput: React.FC<ITextInput> = ({
  name,
  id,
  register,
  defaultValue = '',
  setValue,
  errors,
  required = false,
  disabled = false,
  optional = false,
  assistiveText = false,
}) => {
  useEffect(() => {
    setValue(`${name}` as const, defaultValue);
  }, [defaultValue]);

  const inputRegister = register(`${name}` as const, {
    required,
    onBlur: event => {
      const value = event.target.value;
      if (value) setValue(`${name}` as const, value.trim());
    },
  });

  return (
    <GBG.FormGroup>
      <GBG.Label>
        <div className="space__between">
          <FormattedMessage id={id} />
          {optional && (
            <span className="label--assistive">
              <FormattedMessage id="label.optional" />
            </span>
          )}
        </div>
      </GBG.Label>

      {assistiveText && (
        <GBG.Assistive>
          <FormattedMessage id={`${id}.details`} />
        </GBG.Assistive>
      )}

      <GBG.Text {...inputRegister} disabled={disabled} error={errors[name]} data-testid={name} />
      {errors[name] && (
        <GBG.ErrorText>
          {errors[name].message?.length ? errors[name].message : <FormattedMessage id="errors.required" />}
        </GBG.ErrorText>
      )}
    </GBG.FormGroup>
  );
};

export default TextInput;
