import GBG from '@gbg/gbgcomponentlibrary_react';
import React, { FC, memo, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import TriSelectSwitch from '../../../components/common/TriSelectSwitch';
import { LicenceItemSelectionStatus } from '../../../enums/LicenceSelectStatus';

interface IMultiSelectFunctionList {
  functions: ISelectableLicenceFunctionItem[];
  setFunctionList: React.Dispatch<React.SetStateAction<ISelectableLicenceFunctionItem[]>>;
  readonly: boolean;
  hasMultiSelect?: boolean;
}

const MultiSelectFunctionList: FC<IMultiSelectFunctionList> = ({
  functions,
  readonly,
  hasMultiSelect,
  setFunctionList,
}) => {
  // to reload the TriSelectSwitch component when selecting/unselecting all
  const [switchKey, setSwitchKey] = useState(0);
  const handleCheckboxChange = useCallback(
    (e: any, funcId: string) => {
      let updatedStatus = LicenceItemSelectionStatus.Off;
      // this is a checkbox
      if (e.target) {
        updatedStatus = e.target.checked ? LicenceItemSelectionStatus.Selected : LicenceItemSelectionStatus.Off;
      } else {
        // this is a tri select switch
        updatedStatus = e;
      }

      setFunctionList(prevFunctions => {
        const updatedFunctions = prevFunctions.map(item => {
          if (item.id === funcId) {
            return { ...item, status: updatedStatus };
          }
          return item;
        });

        return updatedFunctions;
      });
    },
    [setFunctionList],
  );

  const handleSelectAll = useCallback(
    (e: any) => {
      const updatedStatus = e.target.checked ? LicenceItemSelectionStatus.Selected : LicenceItemSelectionStatus.Off;

      setFunctionList(prevFunctions => {
        const updatedFunctions = prevFunctions.map(item => {
          return { ...item, status: updatedStatus };
        });

        return updatedFunctions;
      });
      setSwitchKey(prevKey => prevKey + 1);
    },
    [setFunctionList],
  );

  const handleDepartmentSelectAll = useCallback(
    (e: any) => {
      const updatedStatus = e.target.checked ? LicenceItemSelectionStatus.Selected : LicenceItemSelectionStatus.Off;

      setFunctionList(prevFunctions => {
        const updatedFunctions = prevFunctions.map(item => {
          if (item.status !== LicenceItemSelectionStatus.Mandatory) {
            return { ...item, status: updatedStatus };
          }
          return item;
        });

        return updatedFunctions;
      });
      setSwitchKey(prevKey => prevKey + 1);
    },
    [setFunctionList],
  );

  return functions.length <= 0 ? (
    <div className="label m-m-t-5">
      <FormattedMessage id={'licence.noData'} />
    </div>
  ) : (
    <div className="m-m-t-3">
      <div className="licence-function-list">
        <GBG.Table className={readonly ? 'readonly' : ''}>
          <GBG.TableHead>
            <GBG.TableRow>
              {!hasMultiSelect && (
                <GBG.TableHeaderCell className="mini-width">
                  <GBG.Checkbox
                    data-testid="select-all-checkbox"
                    onChange={(e: any) => handleDepartmentSelectAll(e)}
                    checked={functions.every(f => f.status !== LicenceItemSelectionStatus.Off)}
                  ></GBG.Checkbox>
                </GBG.TableHeaderCell>
              )}
              <GBG.TableHeaderCell>Name</GBG.TableHeaderCell>
              {hasMultiSelect && (
                <GBG.TableHeaderCell>
                  <div className="row">
                    Select All &nbsp;
                    <GBG.Checkbox
                      data-testid="select-all-checkbox"
                      onChange={(e: any) => handleSelectAll(e)}
                      checked={functions.every(f => f.status === LicenceItemSelectionStatus.Selected)}
                      disabled={functions.some(f => f.status === LicenceItemSelectionStatus.Mandatory)}
                    ></GBG.Checkbox>
                  </div>
                </GBG.TableHeaderCell>
              )}
            </GBG.TableRow>
          </GBG.TableHead>
          <GBG.TableBody>
            {functions.map(func => (
              <GBG.TableRow key={func.name}>
                {!hasMultiSelect && (
                  <GBG.TableCell>
                    <GBG.Checkbox
                      key={`${func.id}-${switchKey}`}
                      onChange={(e: any) => handleCheckboxChange(e, func.id)}
                      defaultChecked={
                        func.status == LicenceItemSelectionStatus.Selected ||
                        func.status == LicenceItemSelectionStatus.Mandatory
                      }
                      // Mandatory functions cannot be unchecked for departments
                      disabled={func.status == LicenceItemSelectionStatus.Mandatory}
                    />
                  </GBG.TableCell>
                )}
                <GBG.TableCell>{func.name}</GBG.TableCell>
                {hasMultiSelect && (
                  <GBG.TableCell>
                    <TriSelectSwitch
                      key={`${func.id}-${switchKey}`}
                      labels={{
                        left: { label: 'Selected', value: 'selected' },
                        center: { label: 'Off', value: 'off' },
                        right: { label: 'Mandatory', value: 'mandatory' },
                      }}
                      name={func.id}
                      defaultValue={func.status}
                      onChange={e => handleCheckboxChange(e, func.id)}
                      disabled={readonly}
                    />
                  </GBG.TableCell>
                )}
              </GBG.TableRow>
            ))}
          </GBG.TableBody>
        </GBG.Table>
      </div>
    </div>
  );
};

export default memo(MultiSelectFunctionList);
