import GBG from '@gbg/gbgcomponentlibrary_react';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useTabBar = (tabs: string[]) => {
  const { search } = useLocation();
  const [tabIndex, setTabIndex] = useState<number>(0);

  // active selected tab from the query param
  useEffect(() => {
    if (!search.includes('tab=')) return;

    let tab: string | undefined = search.split('tab=').pop()?.split('&')[0];
    if (!tab) return;

    tab = decodeURIComponent(tab);
    const index: number = +tab;
    if (isNaN(index) || index < 0 || index >= tabs.length) return;

    const parentElement: HTMLElement | null = document.querySelector('.tab-bar.w-100');
    if (!parentElement) return;
    const selectedTabElement: HTMLElement | null = parentElement.querySelector(`:scope > .tab:nth-child(${index + 1})`);
    if (!selectedTabElement) return;

    const tabElements: NodeListOf<Element> = parentElement.querySelectorAll(':scope > .tab');
    tabElements.forEach(el => el.classList.remove('tab--active'));
    selectedTabElement.classList.add('tab--active');

    setTabIndex(index);
  }, [search, tabs.length]);

  // render tab bar
  const render = useCallback(() => {
    return <GBG.TabBar className="w-100" tabs={tabs} onTabSelected={(index: number) => setTabIndex(index)} />;
  }, [tabs]);

  return { tabIndex, render };
};

export default useTabBar;
