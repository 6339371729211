export enum OrganisationTabs {
  DETAILS,
  LICENCES,
  USECASES,
  DEPARTMENTS,
  USERS,
  CLIENTS,
}

export enum DepartmentTabs {
  DETAILS,
  LICENCES,
  USECASES,
  ROLES,
  USERS,
}

export enum ClientUserTabs {
  DETAILS,
  USERS,
}

export enum UserTabs {
  DETAILS,
  ROLES,
}
