import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { TOAST_SUCCESS } from '../../constants';
import { ModalContentType } from '../../features/modal/config';
import { useAppDispatch } from '../../app/hooks';
import { openModal } from '../../features/modal/modalSlice';
import Form from '../../components/forms/Form';
import { useAddDataSourceMutation, useUpdateDataSourceMutation } from '../../api/dataSource';
import { DATASOURCES_FORM_FIELDS } from '../../constants/formData';
import { IDatasource } from '../../interfaces/models/IDatasource';
import _ from 'lodash';
import { userRoleTypes, SCOPES } from '../../auth/permissions';

interface IDataSourceForm {
  dataSource?: IDatasource;
  isCreateMode: boolean;
}
const DataSourceForm: React.FC<IDataSourceForm> = ({ dataSource, isCreateMode }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [addDataSource, { isLoading: isAddFormSubmitting }] = useAddDataSourceMutation();
  const [updateDatasource, { isLoading: isUpdateFormSubmitting }] = useUpdateDataSourceMutation();
  const permissions = { [userRoleTypes.DataSource_Admin]: SCOPES.FullAccess };

  const fields = useMemo<IFormField[]>(() => {
    if (isCreateMode) return DATASOURCES_FORM_FIELDS;
    const formFields = _.cloneDeep(DATASOURCES_FORM_FIELDS);
    formFields.filter(x => x.inputName === 'gbgDataSourceID')[0].disabled = true;
    return formFields;
  }, [isCreateMode]);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = useCallback(
    handleSubmit((data: IDatasource) => {
      const formData = {
        ...dataSource,
        ...data,
        ...(Array.isArray(data.gbgDataSourceID) ? {} : { gbgDataSourceID: [data.gbgDataSourceID] }),
      } as IDatasource;

      (isCreateMode ? addDataSource : updateDatasource)(formData)
        .unwrap()
        .then(() => {
          GBG.toast(TOAST_SUCCESS);
          history.push('/data-sources');
        })
        .catch(error => dispatch(openModal({ type: ModalContentType.API_ERROR, data: error })));
    }),
    [handleSubmit],
  );

  return (
    <>
      {isCreateMode && <FormattedMessage id="dataSources.new" tagName="h1" />}

      <Form
        fields={fields}
        {...{
          onSubmit,
          data: dataSource,
          register,
          errors,
          setValue,
          isBusy: isAddFormSubmitting || isUpdateFormSubmitting,
          isNew: isCreateMode,
          permissions,
        }}
      ></Form>
    </>
  );
};

export default memo(DataSourceForm);
