import { memo, useCallback } from 'react';
import { useDeleteDataSourceMutation } from '../../../api/dataSource';
import { IDatasource } from '../../../interfaces/models/IDatasource';

import RemoveModalContent from './RemoveModalContent';

const RemoveDataSource = ({ dataSource }: { dataSource: IDatasource }) => {
  const [deleteDataSource, { isLoading: isDeleting }] = useDeleteDataSourceMutation();

  const handleRemove = useCallback(
    () => deleteDataSource(dataSource.gbgDataSourceID[0]).unwrap(),
    [dataSource?.gbgDataSourceID[0]],
  );

  return <RemoveModalContent name={dataSource.gbgName} handleRemove={handleRemove} isDeleting={isDeleting} />;
};

export default memo(RemoveDataSource);
