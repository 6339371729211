import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ModalContentType } from '../../../../features/modal/config';
import { useAppDispatch } from '../../../../app/hooks';
import { useGetOrganisationQuery } from '../../../../api/organisations';
import { useGetDepartmentQuery } from '../../../../api/departments';
import { closeModal, openModal } from '../../../../features/modal/modalSlice';
import Breadcrumb from '../../../../components/layout/Breadcrumb';
import UserForm from '../../Users/UserForm';

const NewOrganisationPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { orgId, departId } = useParams<{ orgId: string; departId: string }>();
  const { data: orgData, isFetching: isOrgFetching } = useGetOrganisationQuery(orgId);
  const { data: departData, isFetching, isError, error } = useGetDepartmentQuery({ orgId, departId });

  const departmentOptions = useMemo<ISelectOption[]>(
    () => (!departData ? [] : [{ value: departData.gbgDeptId, label: departData.gbgName }]),
    [departData],
  );

  useEffect(() => {
    if (!isError) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
    }
  }, [isError, error]);

  return (
    <>
      {!isOrgFetching && orgData && !isFetching && departData && (
        <Breadcrumb params={{ [orgId]: orgData.gbgName, [departId]: departData.gbgName }} />
      )}

      {isFetching ? (
        <div className="text-center p-5">
          <GBG.Spinner data-testid="spinner" />
        </div>
      ) : (
        <UserForm {...{ orgId, departId, departmentOptions }} />
      )}
    </>
  );
};

export default memo(NewOrganisationPage);
