import { memo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAppDispatch } from '../../../app/hooks';
import { ModalContentType } from '../../../features/modal/config';
import { useGetDepartmentsQuery } from '../../../api/departments';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import Table from '../../../components/table/Table';

interface IDepartmentsList {
  orgId: string;
}

export const DepartmentsList: React.FC<IDepartmentsList> = ({ orgId }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const headers = ['gbgName'];

  const { data: entries, isFetching, isError, error } = useGetDepartmentsQuery(orgId);

  const onItemClicked = useCallback(
    (item: IDepartment) => history.push(`/organisations/${orgId}/departments/${item.gbgDeptId}`),
    [orgId],
  );

  useEffect(() => {
    if (!isError) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
    }
  }, [isError, error]);

  const actions = useCallback(
    (item: IDepartment) => [
      {
        title: 'Remove',
        danger: true,
        action: (dataItem: IDepartment) => {
          dispatch(
            openModal({
              type: ModalContentType.REMOVE_DEPARTMENT,
              data: item || dataItem,
            }),
          );
        },
      },
    ],
    [],
  );

  return (
    <Table
      addUrl={`/organisations/${orgId}/departments/new-department`}
      isPaginated={false}
      searchFields={headers}
      data={{ embedded: { entries: entries ?? [] }, size: entries?.length ?? 0 }}
      {...{ headers, isFetching, onItemClicked, actions }}
    />
  );
};

export default memo(DepartmentsList);
