import React, { FC } from 'react';
import { memo } from 'react';
import { DepartmentTabs } from '../constants/tabs';
interface IFlowDownModalBody {
  flowDownItems: IAffectedDetailDto;
  departId: string | undefined;
  selectedLicencesForPromote: string[];
  setSelectedLicencesForPromote: React.Dispatch<React.SetStateAction<string[]>>;
  gotoDepartment: (id: string, tabIndex: number) => void;
}
const FlowDownModalBody: FC<IFlowDownModalBody> = ({
  flowDownItems,
  departId,
  selectedLicencesForPromote,
  setSelectedLicencesForPromote,
  gotoDepartment,
}) => {
  const affectedItemsOnAdd =
    flowDownItems.affectedItems?.filter(x => x.flowDownType == 'Add' || x.flowDownType == 'Both') ?? [];
  const affectedDepartmentsOnRemove = flowDownItems.departments || flowDownItems.affectedItemsDescriptions;
  const affectedUsersOnRemove = flowDownItems.usersCount
    ? `${flowDownItems.usersCount} user(s)`
    : flowDownItems.affectedItemsDescriptions && flowDownItems.affectedItemsDescriptions.length > 0
    ? flowDownItems.affectedItemsDescriptions[0].message
    : undefined;
  const getSectionTitle = (items: IFlowDownResource[]) => {
    return items?.map((x, index) => (
      <span key={x.changeType + x.id}>
        {x.name}
        {index + 1 != items.length ? ', ' : ''}
      </span>
    ));
  };

  const buildAffectedRolesOnAdd = (affectedRoles: IAffectedItems[]) => {
    if (affectedRoles.length === 0) return null;
    return (
      <ul className="list-group affected-roles">
        {affectedRoles.map(role => {
          return (
            <li className="form-check" key={role.affectedItemId}>
              <input
                type="checkbox"
                name={role.affectedItemId}
                id={role.affectedItemId}
                className="form-check-input"
                onChange={(e: any) => {
                  if (e.target.checked) {
                    setSelectedLicencesForPromote([...selectedLicencesForPromote, role.affectedItemId]);
                  } else {
                    setSelectedLicencesForPromote(selectedLicencesForPromote.filter(x => x != role.affectedItemId));
                  }
                }}
              />
              <label className="form-check-label" htmlFor={role.affectedItemId}>
                {role.name}
              </label>
            </li>
          );
        })}
      </ul>
    );
  };

  const buildAffectedDepartmentLicencesOnAdd = () => {
    return affectedItemsOnAdd
      .filter(x => x.affectedItemType == 'Licence')
      .map((item, index) => {
        const affectedRoles = affectedItemsOnAdd.filter(
          x =>
            x.affectedItemType == 'Role' &&
            x.applicationId == item.applicationId &&
            x.departmentId == item.departmentId,
        );
        const departmentName = item.name.split('-')[1];
        if (affectedRoles.length === 0) return null;
        return (
          <ul className="list-group" key={`licence${index}`}>
            <li className="list-group-item department-licence">{departmentName}</li>
            <li className="list-group-item">{buildAffectedRolesOnAdd(affectedRoles)}</li>
          </ul>
        );
      });
  };

  return (
    <div className="flow-down-affects">
      {((affectedUsersOnRemove && affectedUsersOnRemove.length > 0) || affectedDepartmentsOnRemove.length > 0) && (
        <section className="remove-func-confirmation mb-3">
          {flowDownItems?.addedOrRemovedItems?.some(x => x.changeType === 'Remove') && (
            <h6 className="section-title">
              Removal of {getSectionTitle(flowDownItems?.addedOrRemovedItems?.filter(x => x.changeType === 'Remove'))}
            </h6>
          )}
          {!departId && affectedDepartmentsOnRemove?.length && (
            <>
              <h6 className="section-desc">This will affect users in the following departments:</h6>
              <ul className="list-group list-group-flush">
                {affectedDepartmentsOnRemove.map((detail: any) => {
                  const departmentId = detail.id;
                  return (
                    <li className="list-group-item" key={departmentId}>
                      <a
                        href="#"
                        onClick={() => gotoDepartment(departmentId, DepartmentTabs.USERS)}
                        className="d-block m-m-t-2"
                      >
                        {detail.dn ? (
                          <>
                            {detail.name} ({detail.usersCount ? detail.usersCount : 'No'} users)
                          </>
                        ) : (
                          detail.message
                        )}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </>
          )}

          {departId && (
            <p>
              This will affect &nbsp;
              <a href="#" onClick={() => gotoDepartment(departId, DepartmentTabs.USERS)}>
                {affectedUsersOnRemove}
              </a>
            </p>
          )}
        </section>
      )}

      {affectedItemsOnAdd.length > 0 && (
        <section className="added-func-promotion mb-3">
          {flowDownItems?.addedOrRemovedItems?.some(x => x.changeType === 'Add') && (
            <h6 className="section-title">
              Adding {getSectionTitle(flowDownItems?.addedOrRemovedItems?.filter(x => x.changeType === 'Add'))}
            </h6>
          )}
          <h6 className="section-desc">Select any roles you want to add the functions to:</h6>
          {!departId ? buildAffectedDepartmentLicencesOnAdd() : buildAffectedRolesOnAdd(affectedItemsOnAdd)}
        </section>
      )}
    </div>
  );
};

export default memo(FlowDownModalBody);
