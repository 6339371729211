import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo } from 'react';

const Spinner: React.FC = () => (
  <div className="text-center p-5">
    <GBG.Spinner data-testid="spinner" />
  </div>
);

export default memo(Spinner);
