import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { getCurrentOption } from './utils';

export interface LanguageState {
  current: ISelectOption;
  messages: { [x: string]: string };
}

const current: ISelectOption = getCurrentOption();

const initialState: LanguageState = {
  current,
  messages: require(`../../intl/languages/${current.value}`),
};

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    changeLanguage: (state, action: PayloadAction<ISelectOption>) => {
      state.current = action.payload;
      state.messages = require(`../../intl/languages/${action.payload.value}`);
    },
  },
});

export const { changeLanguage } = languageSlice.actions;

export const selectLanguage = (state: RootState): LanguageState => state.language;

export default languageSlice.reducer;
