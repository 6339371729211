import GBG from '@gbg/gbgcomponentlibrary_react';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useGetUseCasesForDepartmentQuery } from '../../../../api/useCase';
import { useAppDispatch } from '../../../../app/hooks';
import Table from '../../../../components/table/Table';
import { PAGE_SIZE } from '../../../../constants';
import { DepartmentTabs } from '../../../../constants/tabs';
import { ModalContentType } from '../../../../features/modal/config';
import { closeModal, openModal } from '../../../../features/modal/modalSlice';
import { IUseCase } from '../../../../interfaces/models/IUseCase';

export const DepartmentUseCaseList: FC<{ orgId: string; departId: string }> = ({ orgId, departId }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState<string>('');
  const [cursor, setCursor] = useState<string>('');
  const headers = ['gbgUseCaseText'];
  const { data, isFetching, isError, error } = useGetUseCasesForDepartmentQuery({
    gbgCompanyId: orgId,
    gbgDepartmentId: departId,
    request: { search, cursor, limit: PAGE_SIZE },
  });

  const onItemClicked = useCallback(
    (item: IUseCase) => {
      history.push(
        `/organisations/${orgId}/departments/${departId}/usecases/${item.gbgUseCaseID}?tab=${DepartmentTabs.USECASES}`,
      );
    },
    [orgId, departId],
  );

  useEffect(() => {
    if (!isError) dispatch(closeModal());
    else dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
  }, [isError, error, dispatch]);

  const actions = useCallback(
    (item: IUseCase) => [
      {
        title: 'Remove',
        danger: true,
        action: (dataItem: IUseCase) => {
          dispatch(
            openModal({
              type: ModalContentType.REMOVE_DEPARTMENTUSECASE,
              data: item || dataItem,
            }),
          );
        },
      },
    ],
    [],
  );

  const handleImportUseCases = useCallback(() => {
    history.push(`/organisations/${orgId}/departments/${departId}/usecases/add-use-case`);
  }, [orgId, departId]);

  return (
    <div className="department-usecase-list">
      <Table
        addUrl={`/organisations/${orgId}/departments/${departId}/usecases/new-use-case`}
        extraButtons={
          <GBG.Button
            type="submit"
            kind={GBG.ButtonKind.Primary}
            className="m-m-t-2 m-m-r-2"
            onClick={handleImportUseCases}
          >
            <FormattedMessage id="organisation.import" />
          </GBG.Button>
        }
        {...{ headers, data, isFetching, onItemClicked, actions, setSearch, setCursor }}
      />
    </div>
  );
};

export default memo(DepartmentUseCaseList);
