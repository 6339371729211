import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useEffect } from 'react';
import { FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

interface ITextarea {
  id: string;
  name: string;
  register?: UseFormRegister<FieldValues>;
  defaultValue?: string;
  setValue?: UseFormSetValue<FieldValues>;
  errors: any;
  required?: boolean;
  optional?: boolean;
  assistiveText?: string;
  disabled?: boolean;
}

const Textarea: React.FC<ITextarea> = ({
  id,
  name,
  register,
  defaultValue = null,
  setValue,
  errors,
  required = false,
  disabled = false,
  optional = false,
  assistiveText,
}) => {
  useEffect(() => {
    if (setValue) setValue(`${name}` as const, defaultValue);
  }, [defaultValue]);

  return (
    <GBG.FormGroup>
      <GBG.Label>
        <div className="space__between">
          <FormattedMessage id={id} tagName="p" />
          {optional && (
            <span className="label--assistive">
              <FormattedMessage id="label.optional" />
            </span>
          )}
        </div>
      </GBG.Label>

      {assistiveText && (
        <GBG.Assistive>
          <FormattedMessage id={assistiveText} />
        </GBG.Assistive>
      )}
      <GBG.Textarea
        {...(register ? register(`${name}` as const, { required }) : {})}
        error={errors[name]}
        disabled={disabled}
        data-testid={name}
      />
      {errors[name] && (
        <GBG.ErrorText>{errors[name].message || <FormattedMessage id="errors.required" />}</GBG.ErrorText>
      )}
    </GBG.FormGroup>
  );
};

export default memo(Textarea);
