import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { ModalContentType } from '../../../features/modal/config';
import { useAppDispatch } from '../../../app/hooks';
import { useGetOrganisationQuery } from '../../../api/organisations';
import { useGetDepartmentQuery } from '../../../api/departments';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import useTabBar from '../../../hooks/useTabBar';
import Breadcrumb from '../../../components/layout/Breadcrumb';
import DepartmentForm from './DepartmentForm';
import DepartmentUsersList from './Users/DepartmentUsersList';
import RolesList from './Roles/RolesList';
import LicencesList from '../Licences/LicencesList';
import DepartmentUseCaseList from './Use Case/DepartmentUseCaseList';
import { DepartmentTabs } from '../../../constants/tabs';

export const DepartmentPage = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { orgId, departId } = useParams<{ orgId: string; departId: string }>();
  const tabs = useMemo<string[]>(
    () =>
      ['label.details', 'licence.title', 'useCase.title', 'role.title', 'user.title'].map(x =>
        intl.formatMessage({ id: x }),
      ),
    [intl.locale],
  );
  const { render: renderTabBar, tabIndex } = useTabBar(tabs);
  const { data, isFetching, isError, error } = useGetDepartmentQuery({ orgId, departId });
  const {
    data: orgData,
    isFetching: isOrgFetching,
    isError: isOrgError,
    error: orgError,
  } = useGetOrganisationQuery(orgId);

  useEffect(() => {
    if (!isError && !isOrgError) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: error || orgError }));
    }
  }, [isError, error, isOrgError, orgError]);

  return (
    <>
      {!isFetching && data && !isOrgFetching && orgData && (
        <Breadcrumb params={{ [orgId]: orgData.gbgName, [departId]: data.gbgName }} />
      )}

      <h1>{data?.gbgName}</h1>

      {renderTabBar()}

      {tabIndex === DepartmentTabs.DETAILS &&
        (isFetching ? (
          <div className="text-center p-5">
            <GBG.Spinner data-testid="spinner" />
          </div>
        ) : (
          <DepartmentForm orgId={orgId} departData={data} isNew={false} />
        ))}
      {tabIndex === DepartmentTabs.LICENCES && <LicencesList {...{ orgId, departId }} />}
      {tabIndex === DepartmentTabs.USECASES && <DepartmentUseCaseList departId={departId} orgId={orgId} />}
      {tabIndex === DepartmentTabs.ROLES && <RolesList departId={departId} orgId={orgId} />}
      {tabIndex === DepartmentTabs.USERS && <DepartmentUsersList {...{ orgId, departId }} />}
    </>
  );
};

export default memo(DepartmentPage);
