import { adminApi } from '../app/api';

const endpoint = '/api/v1/role';
const orgEndpoint = '/api/v1/organisations';

const extendedApi = adminApi.injectEndpoints({
  endpoints: build => ({
    getRoles: build.query<
      IPaginatedRes<Partial<IDepartmentRole>>,
      { request: IPaginatedReq; gbgCompanyId: string; gbgDepartmentId: string }
    >({
      query: ({ request, gbgCompanyId, gbgDepartmentId }) => ({
        url: `${orgEndpoint}/${gbgCompanyId}/departments/${gbgDepartmentId}/roles?search=${
          request.search ?? ''
        }&cursor=${request.cursor ?? ''}&limit=${request.limit ?? ''}`,
        method: 'GET',
      }),
      providesTags: result =>
        result
          ? [
              ...result.embedded.entries.map(({ gbgRoleID }) => ({ type: 'Roles', id: gbgRoleID } as const)),
              { type: 'Roles', id: 'LIST' },
            ]
          : [{ type: 'Roles', id: 'LIST' }],
    }),
    getRole: build.query<IDepartmentRole, { orgId: string; roleId: string }>({
      query: ({ orgId, roleId }) => ({
        url: `${orgEndpoint}/${orgId}/roles/${roleId}`,
        method: 'GET',
      }),
      providesTags: result => [{ type: 'Roles', id: result?.gbgRoleID }],
    }),
    getUserRoles: build.query<
      IPaginatedRes<IDepartmentRole>,
      { orgId: string; departId?: string; userId: string; search?: string }
    >({
      query: ({ orgId, departId, userId, search }) => ({
        url: `${orgEndpoint}/${orgId}/departments/${departId}/user-roles?userId=${userId}&search=${search ?? ''}`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'UserRoles', id: 'LIST' }],
    }),
    getAvailableUserRoles: build.query<
      IPaginatedRes<IDepartmentRole>,
      { orgId: string; departId: string; userId: string }
    >({
      query: ({ orgId, departId, userId }) => ({
        url: `${orgEndpoint}/${orgId}/departments/${departId}/available-roles?userId=${userId}`,
        method: 'GET',
      }),
      providesTags: () => [{ type: 'UserRoles', id: 'LIST' }],
    }),
    updateRole: build.mutation<IDepartmentRole, Partial<IDepartmentRole>>({
      query: role => ({
        url: endpoint,
        method: 'PATCH',
        data: role,
      }),
      invalidatesTags: result =>
        result
          ? [
              { type: 'Roles', id: result.gbgRoleID },
              { type: 'RoleFunctions', id: 'LIST' },
            ]
          : [],
    }),
    createRole: build.mutation<IDepartmentRole, Partial<IDepartmentRole>>({
      query: license => ({
        url: endpoint,
        method: 'POST',
        data: license,
      }),
      invalidatesTags: [
        { type: 'Roles', id: 'LIST' },
        { type: 'UserRoles', id: 'LIST' },
      ],
    }),
    deleteRole: build.mutation<string, string>({
      query: id => ({ url: `${endpoint}/${id}`, method: 'DELETE' }),
      invalidatesTags: id =>
        id
          ? [
              { type: 'Roles', id: id },
              { type: 'UserRoles', id: 'LIST' },
            ]
          : [],
    }),
  }),
});

export const {
  useGetRoleQuery,
  useGetRolesQuery,
  useGetUserRolesQuery,
  useLazyGetUserRolesQuery,
  useGetAvailableUserRolesQuery,
  useCreateRoleMutation,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
} = extendedApi;
