import { useAppSelector } from '../app/hooks';
import { selectPing } from '../features/ping/pingSlice';
import { getPermissionType } from './PermissionGate';
import { SCOPES, userRoleTypes } from './permissions';

export const customerViewPermissions = {
  [userRoleTypes.Customer_Admin]: SCOPES.CanView,
  [userRoleTypes.Gbg_Admin]: SCOPES.FullAccess,
};

export const getCustomerViewPermissionScopes = () => {
  const { organisationId } = useAppSelector(selectPing);

  return { organisationId, permissionScope: getPermissionType(customerViewPermissions) };
};
