import { memo, useCallback } from 'react';

import { useDeleteOrganisationMutation } from '../../../api/organisations';
import RemoveModalContent from './RemoveModalContent';

const RemoveOrganisation = ({ organisation }: { organisation: IOrganisation }) => {
  const [deleteOrganisation, { isLoading: isDeleting }] = useDeleteOrganisationMutation();

  const handleRemove = useCallback(
    () => deleteOrganisation(organisation?.gbgCompanyId).unwrap(),
    [organisation?.gbgCompanyId],
  );

  return <RemoveModalContent name={organisation?.gbgName} handleRemove={handleRemove} isDeleting={isDeleting} />;
};

export default memo(RemoveOrganisation);
