import { AnyAction } from 'redux';
import { IGeneralReducer } from '../../interfaces/IStore';
import {
  SET_MODAL_DATA,
  SET_MODAL_VISIBILITY,
  SET_IS_FETCHING,
  SET_FUNCTION_DN,
  SET_TOKEN,
  SET_USER_ID,
  SET_MODAL_TYPE,
  SET_SELECTED_ITEM_INDEX,
} from '../actions/actionTypes';
import { initGeneralState } from '../initialState/general';

/**
 * General reducer
 *
 * @param state
 * @param action
 */
export default (state: IGeneralReducer = initGeneralState, action: AnyAction) => {
  switch (action.type) {
    case SET_MODAL_DATA:
      return {
        ...state,
        index: action.payload.index,
        type: action.payload.type,
        message: action.payload.message,
        title: action.payload.title,
        subtitle: action.payload.subtitle,
        buttons: action.payload.buttons,
      };
    case SET_MODAL_VISIBILITY:
      return {
        ...state,
        isVisible: action.payload,
      };
    case SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload,
      };
    case SET_FUNCTION_DN:
      return {
        ...state,
        functionDn: action.payload,
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    case SET_MODAL_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case SET_SELECTED_ITEM_INDEX:
      return {
        ...state,
        index: action.payload,
      };
    default:
      return state;
  }
};
