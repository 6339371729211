import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';

import { RootState } from '../../app/store';
import { removeHash, signOff } from './utils';

interface PingState {
  isAuthenticated: boolean;
  accessToken: string;
  email: string;
  givenName: string;
  familyName: string;
  roles: [];
  organisationId: string;
}

const initialState: PingState = {
  isAuthenticated: false,
  accessToken: '',
  email: '',
  givenName: '',
  familyName: '',
  roles: [],
  organisationId: '',
};

export const pingSlice = createSlice({
  name: 'ping',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<any>) => {
      state.accessToken = action.payload.access_token;
      const idt: any = jwt_decode(action.payload.access_token);
      state.email = idt.email || idt.sub;
      state.familyName = idt.family_name;
      state.givenName = idt.given_name;
      state.isAuthenticated = true;
      state.organisationId = idt.company_id;
      state.roles = idt.functions?.functionIDs;
      removeHash();
    },
    logout: state => {
      (window as any).loggedOut = true;
      state.accessToken = '';
      state.isAuthenticated = false;
      signOff();
    },
    clearAuth: state => {
      state.accessToken = '';
      state.isAuthenticated = false;
      state.roles = [];
    },
  },
});

export const { login, logout, clearAuth } = pingSlice.actions;

export const selectPing = (state: RootState): PingState => state.ping;

export default pingSlice.reducer;
