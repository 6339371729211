import { memo, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetRolesQuery } from '../../../../api/roles';
import { useAppDispatch } from '../../../../app/hooks';
import Table from '../../../../components/table/Table';
import { PAGE_SIZE } from '../../../../constants';
import { ModalContentType } from '../../../../features/modal/config';
import { closeModal, openModal } from '../../../../features/modal/modalSlice';

const RolesList: React.FC<{ orgId: string; departId: string }> = ({ orgId, departId }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const addUrl = `/organisations/${orgId}/departments/${departId}/roles/new-role`;
  const headers = ['gbgName', 'gbgApplicationName'];
  const [search, setSearch] = useState<string>('');
  const [cursor, setCursor] = useState<string>('');
  const { data, isFetching, isError, error } = useGetRolesQuery({
    request: { search, cursor, limit: PAGE_SIZE },
    gbgCompanyId: orgId,
    gbgDepartmentId: departId,
  });
  const onItemClicked = useCallback(
    (item: IDepartmentRole) => history.push(`/organisations/${orgId}/departments/${departId}/roles/${item.gbgRoleID}`),
    [orgId, departId],
  );

  useEffect(() => {
    if (!isError) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
    }
  }, [isError, error, dispatch]);

  const actions = useCallback(
    (item: IDepartmentRole) => [
      {
        title: 'Remove',
        danger: true,
        action: (dataItem: IDepartmentRole) => {
          dispatch(
            openModal({
              type: ModalContentType.REMOVE_DEPARTMENTROLE,
              data: item || dataItem,
            }),
          );
        },
      },
    ],
    [],
  );

  return <Table addUrl={addUrl} {...{ headers, data, isFetching, onItemClicked, actions, setSearch, setCursor }} />;
};

export default memo(RolesList);
