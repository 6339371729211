import GBG from '@gbg/gbgcomponentlibrary_react';
import React, { FC, memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

const FunctionsList: FC<IFunctionsList> = ({
  functions,
  setSelectedFuncIds,
  selectedFuncIds,
  showMessage = true,
  selectable = true,
}) => {
  const data: ILicenceFunction[] = useMemo(
    () => functions.map(func => ({ name: func.gbgName, id: func.gbgFunctionID })),
    [functions],
  );

  return functions.length <= 0 ? (
    <div className="label m-m-t-5">
      <FormattedMessage id={'licence.noData'} />
    </div>
  ) : (
    <div className="m-m-t-3">
      {showMessage && (
        <>
          <p className="label">
            <FormattedMessage id="form.functions" />
          </p>
          <span className="label label label--assistive">
            <FormattedMessage id="form.functions.details" values={{ value: 'the selected application ' }} />
          </span>
        </>
      )}
      <GBG.Table
        className={selectable ? 'custom__table m-m-t-2' : 'custom__table m-m-t-2 readonly'}
        dataSet={{
          initialSelection: (func: ILicenceFunction) => selectedFuncIds?.includes(func.id) as boolean,
          selectable: true,
          data,
          onSelectionChanged: (funcs: ILicenceFunction[]) => setSelectedFuncIds(funcs.map(func => func.id)),
        }}
      />
    </div>
  );
};

interface IFunctionsList {
  functions: IFunction[];
  selectedFuncIds?: string[];
  setSelectedFuncIds: React.Dispatch<React.SetStateAction<string[]>>;
  showMessage?: boolean;
  selectable?: boolean;
}

export default memo(FunctionsList);
