import GBG from '@gbg/gbgcomponentlibrary_react';
import React, { memo, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { IUseCase } from '../../../interfaces/models/IUseCase';
import { IDatasource } from '../../../interfaces/models/IDatasource';
import {
  ActiveListProps,
  ActiveListState,
} from '@gbg/gbgcomponentlibrary_react/build/Atoms/Controls/ActiveList/ActiveList.types';
import ActiveListItem from './ActiveListItem';

interface IUseCaseDatasourcesProperties {
  id: string;
  selectedItem?: IUseCase;
  items: IDatasource[];
  setSelectedDataSources: React.Dispatch<React.SetStateAction<string[]>>;
  assistiveText?: string;
  triggerRefresh?: boolean;
  isFetching: boolean;
}
const UseCaseDatasources: React.FC<IUseCaseDatasourcesProperties> = ({
  id,
  selectedItem,
  items,
  setSelectedDataSources,
  assistiveText,
  triggerRefresh,
  isFetching,
}) => {
  const [activeItems, setActiveItems] = useState<IDatasource[]>([]);
  const [inActiveItems, setInActiveItems] = useState<IDatasource[]>([]);

  useEffect(() => {
    if (!items || typeof items !== 'object') {
      setActiveItems([]);
      setInActiveItems([]);
    } else {
      setActiveItems(
        items.filter(datasource =>
          datasource.gbgDataSourceID.some(datasourceId => selectedItem?.gbgDataSourceID?.includes(datasourceId)),
        ) ?? [],
      );
      setInActiveItems(
        items.filter(
          datasource =>
            !datasource.gbgDataSourceID.some(datasourceId => selectedItem?.gbgDataSourceID?.includes(datasourceId)),
        ) ?? [],
      );
    }
  }, [isFetching, items, selectedItem, triggerRefresh, setActiveItems, setInActiveItems]);

  const activeListProps = {
    activeTitle: 'Selected data sources',
    inactiveTitle: 'Available data sources',
    searchInactive: true,
    searchValue: (item: any) => {
      return `${item.gbgName} ${item.gbgDataSourceGroup}`;
    },
    onItemMoved: (
      items: IDatasource[],
      movedTo: ActiveListState,
      finalItems: { active: IDatasource[]; inactive: IDatasource[] },
    ) => {
      setSelectedDataSources(
        movedTo == GBG.ActiveListState.Active
          ? [...finalItems.active, ...items].map(ds => ds.gbgDataSourceID).flat()
          : finalItems.active
              .filter(ds => !items.includes(ds))
              .map(ds => ds.gbgDataSourceID)
              .flat(),
      );
    },
    contentRenderer: (datasource: IDatasource) => {
      return (
        <>
          <p className="paragraph-medium m-0">
            {datasource.gbgName}
            <span className="paragraph-small dropzone__description group">({datasource.gbgDataSourceGroup})</span>
          </p>
          <p className="paragraph-small m-0 dropzone__description">{datasource.gbgDescription}</p>
        </>
      );
    },
  } as ActiveListProps;

  return (
    <GBG.FormGroup>
      <GBG.Label>
        <div className="space__between">
          <FormattedMessage id={id} tagName="p" />
        </div>
      </GBG.Label>

      {assistiveText && (
        <GBG.Assistive>
          <FormattedMessage id={assistiveText} />
        </GBG.Assistive>
      )}
      <ActiveListItem
        isRefreshing={isFetching}
        dataProps={{ ...activeListProps, ...{ items: { active: activeItems, inactive: inActiveItems } } }}
      />
    </GBG.FormGroup>
  );
};

export default memo(UseCaseDatasources);
