import { adminApi } from '../app/api';

const endpoint = '/api/v1/client';

const clientService = adminApi.injectEndpoints({
  endpoints: build => ({
    getClientList: build.query<IClient[], { gbgCompanyId: string; gbgName?: string }>({
      query: ({ gbgCompanyId, gbgName }: any) => ({
        url: `${endpoint}?gbgCompanyId=${gbgCompanyId}&gbgName=${gbgName ? gbgName : ''}`,
        method: 'GET',
      }),
      providesTags: result =>
        result
          ? [
              ...result.map(({ gbgClientId }) => ({ type: 'Clients', id: gbgClientId } as const)),
              { type: 'Clients', id: 'LIST' },
            ]
          : [{ type: 'Clients', id: 'LIST' }],
    }),
    getClient: build.query<IClient, string>({
      query: (gbgClientId: string) => ({ url: `${endpoint}/${gbgClientId}`, method: 'GET' }),
      providesTags: result => (result ? [{ type: 'Clients', id: result.gbgClientId }] : []),
    }),
    addClient: build.mutation<IClient, IClient>({
      query: data => ({ url: endpoint, method: 'POST', data }),
      invalidatesTags: result => (result ? [{ type: 'Clients', id: 'LIST' }] : []),
    }),
    updateClient: build.mutation<IClient, IClient>({
      query: data => ({ url: endpoint, method: 'PUT', data }),
      invalidatesTags: result => (result ? [{ type: 'Clients', id: result?.gbgClientId }] : []),
    }),
    regenerateSecret: build.mutation<string, Partial<IClient>>({
      query: data => ({ url: `${endpoint}/RegenerateSecret`, method: 'POST', data }),
    }),
  }),
});

export const {
  useGetClientListQuery,
  useGetClientQuery,
  useAddClientMutation,
  useUpdateClientMutation,
  useRegenerateSecretMutation,
} = clientService;
