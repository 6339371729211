export enum ModalContentType {
  EMPTY = 'EMPTY',
  REMOVE_ORGANISATION = 'REMOVE_ORGANISATION',
  REMOVE_APPLICATION = 'REMOVE_APPLICATION',
  REMOVE_LICENCE = 'REMOVE_LICENCE',
  REMOVE_FUNCTION = 'REMOVE_FUNCTION',
  REMOVE_DEPARTMENT = 'REMOVE_DEPARTMENT',
  REMOVE_USECASE = 'REMOVE_USECASE',
  REMOVE_DATASOURCE = 'REMOVE_DATASOURCE',
  REMOVE_DEPARTMENTROLE = 'REMOVE_DEPARTMENTROLE',
  REMOVE_ROLEFEATURE = 'REMOVE_ROLEFEATURE',
  REMOVE_USER = 'REMOVE_USER',
  REMOVE_ROLEUSECASE = 'REMOVE_ROLEUSECASE',
  REMOVE_DEPARTMENTUSECASE = 'REMOVE_DEPARTMENTUSECASE',
  REMOVE_USERROLE = 'REMOVE_USERROLE',
  REGENERATE_SECRET = 'REGENERATE_SECRET',
  API_ERROR = 'API_ERROR',
  PROMPT_UPDATE_LICENCE = 'PROMPT_UPDATE_LICENCE',
}

export interface ModalState {
  isOpen: boolean;
  currentContentType: ModalContentType;
  data?: any;
}
