import { memo } from 'react';

import Breadcrumb from '../../components/layout/Breadcrumb';
import OrganisationForm from './OrganisationForm';

const NewOrganisationPage: React.FC = () => {
  return (
    <>
      <Breadcrumb />
      <OrganisationForm />
    </>
  );
};

export default memo(NewOrganisationPage);
