import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../app/hooks';
import { useCreateRoleMutation } from '../../../../api/roles';
import { TOAST_SUCCESS } from '../../../../constants';
import { closeModal, openModal } from '../../../../features/modal/modalSlice';
import { ModalContentType } from '../../../../features/modal/config';
import Form from '../../../../components/forms/Form';
import { ROLE_FIELDS } from '../../../../constants/formData';
import { useGetOrganisationQuery } from '../../../../api/organisations';
import Breadcrumb from '../../../../components/layout/Breadcrumb';
import { useGetDepartmentQuery } from '../../../../api/departments';
import _ from 'lodash';
import { useGetDepartmentRoleApplicationsQuery } from '../../../../api/applications';

const NewRolePage: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [addRole, { isLoading: isAddFormSubmitting }] = useCreateRoleMutation();
  const { orgId, departId } = useParams<{ orgId: string; departId: string }>();
  const { data: applications, isFetching, isError, error } = useGetDepartmentRoleApplicationsQuery({ departId, orgId });
  const {
    data: organisation,
    isError: isErrorOrg,
    error: errorOrg,
    isFetching: isFetchingOrg,
  } = useGetOrganisationQuery(orgId);

  const {
    data: department,
    isError: isErrorDep,
    error: errorDep,
    isFetching: isFetchingDep,
  } = useGetDepartmentQuery({ orgId, departId });

  useEffect(() => {
    if (!isErrorOrg && !isErrorDep && !isError) dispatch(closeModal());
    else dispatch(openModal({ type: ModalContentType.API_ERROR, data: errorOrg || errorDep || error }));
  }, [isErrorDep, isErrorOrg, errorDep, errorOrg, isError, error]);

  const fields = useMemo<IFormField[]>(() => {
    const formFields = _.cloneDeep(ROLE_FIELDS);
    const applicationDNsField = formFields.filter(x => x.inputName === 'gbgApplicationId')[0];
    if (applications)
      applicationDNsField.options = applications.map(item => ({
        label: item.gbgName,
        value: item.gbgApplicationId,
      }));
    return formFields;
  }, [applications]);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = useCallback(
    handleSubmit((data: IDepartmentRole) => {
      const formData = {
        ...data,
        ...{ gbgDepartmentId: departId, gbgCompanyId: orgId },
      } as IDepartmentRole;

      addRole(formData)
        .unwrap()
        .then(result => {
          GBG.toast(TOAST_SUCCESS);
          history.push(`/organisations/${orgId}/departments/${departId}/roles/${result.gbgRoleID}`);
        })
        .catch(error => dispatch(openModal({ type: ModalContentType.API_ERROR, data: error })));
    }),
    [handleSubmit, orgId, departId, dispatch],
  );

  return isFetchingOrg || isFetchingDep || isFetching || !organisation || !department ? (
    <div className="text-center p-5">
      <GBG.Spinner data-testid="spinner" />
    </div>
  ) : (
    <>
      <Breadcrumb params={{ [orgId]: organisation.gbgName, [departId]: department.gbgName }} />
      <FormattedMessage id="label.create.role" tagName="h1" />
      <Form
        fields={fields}
        {...{ onSubmit, data: {}, register, errors, setValue, isBusy: isAddFormSubmitting, isNew: true }}
      ></Form>
    </>
  );
};

export default memo(NewRolePage);
