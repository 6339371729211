import GBG from '@gbg/gbgcomponentlibrary_react';
import React, { memo, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';

import { useAppDispatch } from '../../app/hooks';
import { routes } from '../../app/routes';
import { logout } from '../../features/ping/pingSlice';
import LanguageSwitcher from '../common/LanguageSwitcher';
import { SCOPES } from '../../auth/permissions';

interface INavigation {
  user: any;
  permissionScope: string;
}

const Navigation: React.FC<INavigation> = ({ user, permissionScope }) => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const handleLogout = useCallback(() => dispatch(logout()), []);

  const handleNavClose = useCallback(() => setMenuOpen(false), []);
  return (
    <>
      <GBG.TopBar
        includeNavLink={true}
        brand={GBG.TopBarBrand.GBG}
        user={{ username: user?.user_firstname + ' ' + user?.user_familyname, company: user?.company_name ?? '' }}
        menu={{
          items: [
            {
              title: intl.formatMessage({ id: 'nav.logout' }),
              onSelected: handleLogout,
            },
          ],
        }}
      >
        <LanguageSwitcher />
      </GBG.TopBar>
      {permissionScope === SCOPES.FullAccess && (
        <GBG.Nav open={menuOpen} onClose={handleNavClose}>
          <GBG.NavSection data-testid="navSection">
            {routes.map(item => {
              if (!item.isInMenu) return;

              return (
                <GBG.NavItem
                  data-testid={item.route}
                  key={item.route}
                  active={item.route.split('/')[1] === pathname.split('/')[1]}
                  onClick={() => history.push(item.route)}
                >
                  {item.text}
                </GBG.NavItem>
              );
            })}
          </GBG.NavSection>
        </GBG.Nav>
      )}
    </>
  );
};

export default memo(Navigation);
