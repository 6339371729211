import { FC, memo, useEffect } from 'react';
import GBG from '@gbg/gbgcomponentlibrary_react';
import { closeModal, openModal } from '../../../../features/modal/modalSlice';
import { ModalContentType } from '../../../../features/modal/config';
import { useAppDispatch } from '../../../../app/hooks';
import Breadcrumb from '../../../../components/layout/Breadcrumb';
import { useGetDepartmentQuery } from '../../../../api/departments';
import { useGetRoleQuery } from '../../../../api/roles';
import { useGetOrganisationQuery } from '../../../../api/organisations';
import { useGetUserQuery } from '../../../../api/users';
import { useGetClientQuery } from '../../../../api/client';
interface IRoleUseCaseBreadcrumbsProps {
  orgId: string;
  departmentId?: string;
  userId?: string;
  roleId: string;
  clientId?: string;
  gbgUseCaseText?: string;
  useCaseId?: string;
  setDepartmentId: React.Dispatch<React.SetStateAction<string>>;
}
const RoleUseCaseBreadcrumbs: FC<IRoleUseCaseBreadcrumbsProps> = ({
  orgId,
  departmentId,
  roleId,
  userId,
  clientId,
  setDepartmentId,
  gbgUseCaseText,
  useCaseId,
}) => {
  const { data: role, isError: isErrorRole, error: errorRole } = useGetRoleQuery({ orgId, roleId });
  const { data: org, isError: isErrorOrg, error: errorOrg } = useGetOrganisationQuery(orgId);
  const {
    data: user,
    isError: isErrorUser,
    error: errorUser,
  } = useGetUserQuery({ userId: userId ?? '', orgId }, { skip: !userId });
  const {
    data: department,
    isError,
    error,
  } = useGetDepartmentQuery({ orgId, departId: departmentId ?? '' }, { skip: !departmentId });
  const dispatch = useAppDispatch();
  const {
    data: client,
    isError: isErrorClient,
    error: errorClient,
  } = useGetClientQuery(clientId ?? '', { skip: !clientId });

  useEffect(() => {
    if (user) setDepartmentId(user?.gbgDepartmentId);
  }, [user?.gbgDepartmentId, setDepartmentId]);

  useEffect(() => {
    if (!isErrorOrg && !isError && !isErrorRole && !isErrorUser && !errorClient) dispatch(closeModal());
    else
      dispatch(
        openModal({
          type: ModalContentType.API_ERROR,
          data: errorOrg || error || errorRole || errorUser || errorClient,
        }),
      );
  }, [
    isErrorOrg,
    isError,
    isErrorRole,
    errorOrg,
    error,
    errorRole,
    errorUser,
    isErrorUser,
    isErrorClient,
    errorClient,
    dispatch,
  ]);

  return org && (!departmentId || department) && (!userId || user) && role && (!clientId || client) ? (
    <Breadcrumb
      params={{
        [orgId]: org.gbgName,
        [departmentId ?? '']: department?.gbgName ?? '',
        [clientId ?? '']: client?.gbgName ?? '',
        [userId ?? '']: user?.username ?? '',
        [roleId]: role.gbgName,
        [useCaseId ?? '']: gbgUseCaseText ?? '',
      }}
    />
  ) : (
    <div className="text-center p-5">
      <GBG.Spinner data-testid="spinner" />
    </div>
  );
};

export default memo(RoleUseCaseBreadcrumbs);
