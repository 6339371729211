import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import { EDIT_USER_FIELDS, TOAST_SUCCESS, USER_FIELDS } from '../../../constants';
import { OrganisationTabs } from '../../../constants/tabs';
import { ModalContentType } from '../../../features/modal/config';
import { useAppDispatch } from '../../../app/hooks';
import { openModal } from '../../../features/modal/modalSlice';
import { useAddUserMutation, useUpdateUserMutation } from '../../../api/users';
import Form from '../../../components/forms/Form';
import TempPasswordModal from './TempPasswordModal';

interface IUserForm {
  orgId: string;
  departId?: string;
  userData?: IUser;
  isNew?: boolean;
  clientId?: string;
  departmentOptions: ISelectOption[];
}

const UserForm: React.FC<IUserForm> = ({ orgId, departId, userData, isNew = true, departmentOptions, clientId }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [passwordUser, setPasswordUser] = useState<IUser>();
  const [addUser, { isLoading: isAdding }] = useAddUserMutation();
  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = useCallback(
    handleSubmit(async (data: IUser) => {
      try {
        const editUser = isNew ? addUser : updateUser;
        const response = await editUser({
          ...userData,
          ...data,
          username: userData?.email ?? data?.email ?? '',
          gbgCompanyId: orgId,
          gbgClientId: clientId,
        }).unwrap();
        GBG.toast(TOAST_SUCCESS);
        let redirectUrl = `/organisations/${orgId}`;
        if (departId) {
          redirectUrl += `/departments/${departId}`;
        } else if (clientId) {
          redirectUrl += `/clients/${clientId}`;
        }
        redirectUrl += `/users/${response.id}?tab=${OrganisationTabs.USERS}`;
        history.push(redirectUrl);
      } catch (err) {
        dispatch(openModal({ type: ModalContentType.API_ERROR, data: err }));
      }
    }),
    [handleSubmit, isNew, addUser, updateUser, userData, userData?.email, orgId, departId],
  );

  const handlePasswordModalClose = useCallback(() => {
    setPasswordUser(undefined);
  }, []);

  const handlePasswordModalOpen = useCallback(() => {
    setPasswordUser(userData);
  }, [userData]);

  const extraButtons = useMemo<IExtraButton[]>(
    () =>
      isNew
        ? []
        : [
            userData?.enabled
              ? {
                  label: 'btn.setTempPassword',
                  kind: GBG.ButtonKind.Secondary,
                  handler: handlePasswordModalOpen,
                }
              : (null as unknown as IExtraButton),
          ].filter(Boolean),
    [isNew],
  );

  const fields = useMemo<IFormField[]>(() => {
    const formFields = isNew ? _.cloneDeep(USER_FIELDS) : _.cloneDeep(EDIT_USER_FIELDS);
    const departmentField = formFields.find(item => item.inputName === 'gbgDepartmentId');
    if (departmentField) {
      departmentField.options = departmentOptions;

      if (departId) {
        departmentField.disabled = true;
        departmentField.defaultValue = departId;
      }

      if (clientId) {
        const email = formFields.find(x => x.inputName == 'email');
        if (email) email.disabled = false;
      }
    }
    return formFields;
  }, [isNew, departmentOptions, departId]);

  return (
    <div className="m-m-t-2">
      {isNew && <FormattedMessage id="users.new" tagName="h1" />}

      <Form
        {...{
          onSubmit,
          register,
          setValue,
          errors,
          fields,
          extraButtons,
          isBusy: isAdding || isUpdating,
          data: userData,
        }}
      ></Form>

      {!isNew && <TempPasswordModal user={passwordUser} onClose={handlePasswordModalClose}></TempPasswordModal>}
    </div>
  );
};

export default memo(UserForm);
