import { memo, useCallback } from 'react';
import { useUpdateRoleMutation } from '../../../api/roles';

import RemoveModalContent from './RemoveModalContent';
interface IRemoveRoleFeature {
  departmentRole: IDepartmentRole;
  functionName: string;
}
const RemoveRoleFeature = ({ departmentRole, functionName }: IRemoveRoleFeature) => {
  const [updateRole, { isLoading: isDeleting }] = useUpdateRoleMutation();
  const handleRemove = useCallback(async () => {
    const result = await updateRole(departmentRole).unwrap();
    return result.gbgRoleID;
  }, [departmentRole]);

  return <RemoveModalContent name={functionName} handleRemove={handleRemove} isDeleting={isDeleting} />;
};

export default memo(RemoveRoleFeature);
