import React, { memo, useCallback } from 'react';
import Select, { SingleValue } from 'react-select';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { LANGUAGE_OPTIONS } from '../../features/language/config';
import { changeLanguage, selectLanguage } from '../../features/language/languageSlice';

const LanguageSwitcher: React.FC = () => {
  const dispatch = useAppDispatch();
  const current = useAppSelector(selectLanguage).current;

  const handleChange = useCallback((option: SingleValue<ISelectOption>) => {
    if (option) dispatch(changeLanguage(option));
  }, []);

  return (
    <Select
      isSearchable={false}
      value={current}
      onChange={handleChange}
      options={LANGUAGE_OPTIONS}
      className="m-r-16"
      classNamePrefix="select"
    />
  );
};

export default memo(LanguageSwitcher);
