import { adminApi } from '../app/api';

const endpoint = '/api/v1/function';
const customerViewEndPoint = '/api/v1/organisations';

const extendedApi = adminApi.injectEndpoints({
  endpoints: build => ({
    getAppFunctions: build.query<IFunction[], { appId: string; isToImport?: boolean }>({
      query: ({ appId, isToImport = false }) => ({
        url: `/api/v1/applications/${appId}/functions?import=${isToImport}`,
        method: 'GET',
      }),
      providesTags: result =>
        result
          ? [
              ...result.map(({ gbgFunctionID }) => ({ type: 'Functions', gbgFunctionID: gbgFunctionID } as const)),
              { type: 'Functions', id: 'APP_FUNC_LIST' },
            ]
          : [{ type: 'Functions', id: 'APP_FUNC_LIST' }],
    }),
    getFunction: build.query<IFunction, string>({
      query: id => ({ url: `${endpoint}/${id}`, method: 'GET' }),
      providesTags: result => (result ? [{ type: 'Functions', gbgFunctionID: result.gbgFunctionID }] : []),
    }),
    addFunction: build.mutation<IFunction, IFunction>({
      query: data => ({ url: endpoint, method: 'POST', data }),
      invalidatesTags: [
        { type: 'Functions', id: 'APP_FUNC_LIST' },
        { type: 'Functions', id: 'FUNCTIONS_BY_APPLICATION' },
      ],
    }),
    patchAddFunction: build.mutation<IFunction, { appId: string; id: string }>({
      query: ({ appId, id }) => ({ url: `${endpoint}?appId=${appId}&id=${id}`, method: 'PATCH' }),
      invalidatesTags: [{ type: 'Functions', id: 'APP_FUNC_LIST' }],
    }),
    updateFunction: build.mutation<IFunction, IFunction>({
      query: data => ({ url: endpoint, method: 'PUT', data }),
      invalidatesTags: result => (result ? [{ type: 'Functions', gbgFunctionID: result.gbgFunctionID }] : []),
    }),
    patchDeleteFunction: build.mutation<string, { appId: string; id: string }>({
      query: ({ appId, id }) => ({
        url: `${endpoint}?appId=${appId}&id=${id}&action=remove`,
        method: 'PATCH',
      }),
      transformResponse: (response: any) => response?.gbgFunctionID,
      invalidatesTags: id =>
        id
          ? [
              { type: 'Functions', gbgFunctionID: id },
              { type: 'Functions', id: 'FUNCTIONS_BY_APPLICATION' },
            ]
          : [],
    }),
    getFunctionsByApplicationId: build.query<IFunction[], { orgId: string; appId: string }>({
      query: ({ orgId, appId }) => ({
        url: `${customerViewEndPoint}/${orgId}/applications/${appId}/functions`,
        method: 'GET',
      }),
      providesTags: result =>
        result
          ? [
              ...result.map(({ gbgFunctionID }) => ({ type: 'Functions', gbgFunctionID } as const)),
              { type: 'Functions', id: 'FUNCTIONS_BY_APPLICATION' },
            ]
          : [{ type: 'Functions', id: 'FUNCTIONS_BY_APPLICATION' }],
    }),
    getFunctionsByRoleId: build.query<IFunction[], { orgId: string; roleId: string }>({
      query: ({ orgId, roleId }) => ({
        url: `${customerViewEndPoint}/${orgId}/roles/${roleId}/functions`,
        method: 'GET',
      }),
      providesTags: result =>
        result
          ? [
              ...result.map(({ gbgFunctionID }) => ({ type: 'RoleFunctions' as const, id: gbgFunctionID })),
              { type: 'RoleFunctions', id: 'LIST' },
            ]
          : [{ type: 'RoleFunctions', id: 'LIST' }],
    }),
    getAvailableFunctionsByRoleId: build.query<IFunction[], { orgId: string; roleId: string }>({
      query: ({ orgId, roleId }) => ({
        url: `${customerViewEndPoint}/${orgId}/roles/${roleId}/available-function`,
        method: 'GET',
      }),
      providesTags: result =>
        result
          ? [
              ...result.map(({ gbgFunctionID }) => ({ type: 'RoleFunctions' as const, id: gbgFunctionID })),
              { type: 'RoleFunctions', id: 'LIST' },
            ]
          : [{ type: 'RoleFunctions', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetAppFunctionsQuery,
  useGetFunctionQuery,
  useAddFunctionMutation,
  usePatchAddFunctionMutation,
  useUpdateFunctionMutation,
  usePatchDeleteFunctionMutation,
  useGetFunctionsByApplicationIdQuery,
  useGetFunctionsByRoleIdQuery,
  useGetAvailableFunctionsByRoleIdQuery,
} = extendedApi;
