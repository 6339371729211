import { memo, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import { useGetDatasourceListQuery } from '../../api/dataSource';
import Table from '../../components/table/Table';
import { IDatasource } from '../../interfaces/models/IDatasource';
import { closeModal, openModal } from '../../features/modal/modalSlice';
import { ModalContentType } from '../../features/modal/config';
import { PAGE_SIZE } from '../../constants';
import { SCOPES, userRoleTypes } from '../../auth/permissions';

export const DataSourcesListPage = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const headers = ['gbgName', 'gbgDataSourceStatus', 'gbgDataSourceGroup'];
  const [search, setSearch] = useState<string>('');
  const [cursor, setCursor] = useState<string>('');
  const permissions = { [userRoleTypes.DataSource_Admin]: SCOPES.FullAccess };

  const { data, isFetching, isError, error } = useGetDatasourceListQuery({ search, cursor, limit: PAGE_SIZE });

  const onItemClicked = useCallback((item: IDatasource) => {
    history.push(`/data-sources/${item.gbgDataSourceID[0]}`);
  }, []);

  useEffect(() => {
    if (!isError) dispatch(closeModal());
    else dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
  }, [isError, error]);

  const actions = useCallback(
    (item: IDatasource) => [
      {
        title: 'Remove',
        danger: true,
        action: (dataItem: IDatasource) => {
          dispatch(
            openModal({
              type: ModalContentType.REMOVE_DATASOURCE,
              data: item || dataItem,
            }),
          );
        },
      },
    ],
    [],
  );

  return (
    <Table
      title="dataSources.title"
      addUrl="/data-sources/new-data-source"
      {...{ headers, data, isFetching, onItemClicked, actions, setSearch, setCursor, permissions }}
    />
  );
};
export default memo(DataSourcesListPage);
