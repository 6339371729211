import GBG from '@gbg/gbgcomponentlibrary_react';
import { FieldValues, MultipleFieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import PermissionGate, { getPermissionType } from '../../auth/PermissionGate';
import { SCOPES } from '../../auth/permissions';
import FormFieldItem from './FormFieldItem';

interface IForm {
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined | any) => Promise<void>;
  fields: IFormField[];
  data?: { [x: string]: any };
  register: UseFormRegister<FieldValues>;
  errors: { [fieldName: string]: { type: string; message?: string; types: MultipleFieldErrors } };
  setValue: UseFormSetValue<FieldValues>;
  isBusy?: boolean;
  isNew?: boolean;
  shouldDisplaySaveButton?: boolean;
  extraButtons?: IExtraButton[];
  permissions?: { [x: string]: string };
  permissionType?: string;
  submitButtonText?: string;
}

const Form: React.FC<IForm> = ({
  onSubmit,
  fields,
  data,
  register,
  errors,
  setValue,
  isBusy = false,
  isNew = false,
  shouldDisplaySaveButton = true,
  children,
  extraButtons = [],
  permissions,
  permissionType,
  submitButtonText,
}) => {
  permissionType = permissionType ? permissionType : permissions ? getPermissionType(permissions) : SCOPES.FullAccess;
  return (
    <form onSubmit={onSubmit} className="form__small mt-3">
      {fields?.map(field => {
        const isDisabled = permissionType === SCOPES.None || permissionType === SCOPES.CanView;
        return field.shouldNotBeRendered ? (
          ''
        ) : (
          <div className={isDisabled ? 'readonly' : ''} key={field.label}>
            <FormFieldItem {...{ field, data, register, errors, setValue, disabled: isDisabled }} />
          </div>
        );
      })}

      {children}

      <PermissionGate permissionType={permissionType}>
        {shouldDisplaySaveButton && (
          <GBG.Button
            type="submit"
            kind={GBG.ButtonKind.Primary}
            className="mt-3"
            worker={true}
            active={isBusy}
            disabled={isBusy}
          >
            <FormattedMessage id={`${submitButtonText ? submitButtonText : isNew ? 'btn.add' : 'btn.save'}`} />
          </GBG.Button>
        )}

        {extraButtons?.map(btnInfo => (
          <GBG.Button
            type="button"
            key={btnInfo.label}
            kind={btnInfo.kind}
            className="m-m-t-2 m-m-l-2"
            onClick={btnInfo.handler}
          >
            <FormattedMessage id={btnInfo.label} />
          </GBG.Button>
        ))}
      </PermissionGate>
    </form>
  );
};

export default Form;
