import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ModalContentType } from '../../../features/modal/config';
import { useAppDispatch } from '../../../app/hooks';
import { useGetOrganisationQuery } from '../../../api/organisations';
import { closeModal, openModal } from '../../../features/modal/modalSlice';
import Breadcrumb from '../../../components/layout/Breadcrumb';
import DepartmentForm from './DepartmentForm';

const NewDepartmentPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { orgId } = useParams<{ orgId: string }>();
  const { data, isFetching, isError, error } = useGetOrganisationQuery(orgId);

  useEffect(() => {
    if (!isError) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
    }
  }, [isError, error]);

  return (
    <>
      {!isFetching && data && <Breadcrumb params={{ [orgId]: data.gbgName }} />}

      <DepartmentForm orgId={orgId} />
    </>
  );
};

export default memo(NewDepartmentPage);
