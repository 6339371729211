import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetOrganisationQuery } from '../../../../api/organisations';
import { useGetUserQuery } from '../../../../api/users';
import { useAppDispatch } from '../../../../app/hooks';
import Breadcrumb from '../../../../components/layout/Breadcrumb';
import { UserTabs } from '../../../../constants/tabs';
import { ModalContentType } from '../../../../features/modal/config';
import { closeModal, openModal } from '../../../../features/modal/modalSlice';
import UserRoleForm from '../../Roles/UserRoleForm';

const AddDepartmentUserRolePage = () => {
  const dispatch = useAppDispatch();
  const { orgId, userId, departId } = useParams<{ orgId: string; userId: string; departId: string }>();
  const { data: userDetails, isFetching, isError, error } = useGetUserQuery({ userId, orgId });
  const baseCreateEditPageURL = `/organisations/${orgId}/departments/${departId}/users/${userId}?tab=${UserTabs.ROLES}`;

  const {
    data: organisation,
    isError: isErrorOrg,
    error: errorOrg,
    isFetching: isFetchingOrg,
  } = useGetOrganisationQuery(orgId);

  useEffect(() => {
    if (!isErrorOrg && !isError) dispatch(closeModal());
    else dispatch(openModal({ type: ModalContentType.API_ERROR, data: errorOrg || error }));
  }, [isErrorOrg, errorOrg, isError, error]);

  return isFetchingOrg || isFetching || !organisation || !userDetails ? (
    <div className="text-center p-5">
      <GBG.Spinner data-testid="spinner" />
    </div>
  ) : (
    <>
      <Breadcrumb
        params={{
          [orgId]: organisation.gbgName,
          [departId]: userDetails.gbgDepartmentName,
          [userId]: userDetails.username,
        }}
      />

      <UserRoleForm orgId={orgId} userDetails={userDetails} baseCreateEditPageURL={baseCreateEditPageURL} />
    </>
  );
};

export default memo(AddDepartmentUserRolePage);
