import { memo, useCallback } from 'react';

import { useDeleteDepartmentMutation } from '../../../api/departments';
import RemoveModalContent from './RemoveModalContent';

interface IRemoveDepartment {
  departId: string;
  name: string;
}

const RemoveDepartment: React.FC<IRemoveDepartment> = ({ departId, name }) => {
  const [deleteDepartment, { isLoading: isDeleting }] = useDeleteDepartmentMutation();

  const handleRemove = useCallback(() => deleteDepartment(departId).unwrap(), [departId]);

  return <RemoveModalContent {...{ name, handleRemove, isDeleting }} />;
};

export default memo(RemoveDepartment);
