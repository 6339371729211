// general
export const SET_TOKEN = 'SET_TOKEN';
export const SET_USER_ID = 'SET_USER_ID';

export const SET_IS_FETCHING = 'SET_IS_FETCHING';
export const SET_REDIRECT = 'SET_REDIRECT';
export const SET_REFRESH_NEEDED = 'SET_REFRESH_NEEDED';

// modal
export const SET_MODAL_DATA = 'SET_MODAL_DATA';
export const SET_MODAL_VISIBILITY = 'SET_MODAL_VISIBILITY';
export const SET_MODAL_TYPE = 'SET_MODAL_TYPE';

export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';
export const SET_SELECTED_CHILD = 'SET_SELECTED_CHILD';

// items
export const SET_INITIAL_ITEMS = 'SET_INITIAL_ITEMS';
export const SET_DATA_ITEMS = 'SET_DATA_ITEMS';
export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM';
export const SET_SELECTED_ITEM_INDEX = 'SET_SELECTED_ITEM_INDEX';

export const SET_FUNCTION_DN = 'SET_FUNCTION_DN';
export const SET_DROPDOWN_DATA = 'SET_DROPDOWN_DATA';
export const SET_AVAILABLE_FEATURES = 'SET_AVAILABLE_FEATURES';

// data sources
export const SET_SELECTED_DATASOURCES = 'SET_SELECTED_DATASOURCES';
export const SET_AVAILABLE_DATASOURCES = 'SET_AVAILABLE_DATASOURCES';
export const RESET_SELECTED_DATA_SOURCES = 'RESET_SELECTED_DATA_SOURCES';
export const RESET_AVAILABLE_DATA_SOURCES = 'RESET_AVAILABLE_DATA_SOURCES';
