import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { ModalContentType } from '../../features/modal/config';
import { useAppDispatch } from '../../app/hooks';
import { useGetOrganisationQuery } from '../../api/organisations';
import { closeModal, openModal } from '../../features/modal/modalSlice';
import useTabBar from '../../hooks/useTabBar';
import Breadcrumb from '../../components/layout/Breadcrumb';
import OrganisationForm from './OrganisationForm';
import LicencesList from './Licences/LicencesList';
import UsersList from './Users/UsersList';
import OrganisationUseCaseList from './UseCases/OrganisationUseCaseList';
import ClientsListPage from './Clients/ClientsListPage';
import DepartmentsList from './Departments/DepartmentsList';
import { OrganisationTabs } from '../../constants/tabs';
import { getCustomerViewPermissionScopes } from '../../auth/customerViewAccess';
import { SCOPES } from '../../auth/permissions';

export const OrganisationPage: React.FC = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { permissionScope } = getCustomerViewPermissionScopes();
  const tabs = useMemo<string[]>(() => {
    const tabsList = ['label.details', 'licence.title', 'useCase.title', 'department.title', 'user.title'];
    if (permissionScope === SCOPES.FullAccess) tabsList.push('clients.title');

    return tabsList.map(x => intl.formatMessage({ id: x }));
  }, [permissionScope, intl.locale]);
  const { render: renderTabBar, tabIndex } = useTabBar(tabs);
  const { data, isFetching, isError, error } = useGetOrganisationQuery(id);

  useEffect(() => {
    if (!isError) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
    }
  }, [isError, error]);

  return (
    <>
      {!isFetching && data && <Breadcrumb params={{ [id]: data.gbgName }} />}

      <h1>{data?.gbgName}</h1>

      {renderTabBar()}

      {tabIndex === OrganisationTabs.DETAILS &&
        (isFetching ? (
          <div className="text-center p-5">
            <GBG.Spinner data-testid="spinner" />
          </div>
        ) : (
          <OrganisationForm orgData={data} isNew={false} />
        ))}
      {tabIndex === OrganisationTabs.LICENCES && <LicencesList orgId={id} />}
      {tabIndex === OrganisationTabs.USECASES && <OrganisationUseCaseList companyId={id} />}
      {tabIndex === OrganisationTabs.DEPARTMENTS && <DepartmentsList orgId={id} />}
      {tabIndex === OrganisationTabs.USERS && <UsersList orgId={id} />}
      {tabIndex === OrganisationTabs.CLIENTS && <ClientsListPage orgId={id} />}
    </>
  );
};

export default memo(OrganisationPage);
