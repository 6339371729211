import { memo } from 'react';
import { useAppSelector } from '../app/hooks';
import { selectPing } from '../features/ping/pingSlice';
import { SCOPES } from './permissions';
interface IPermissionProps {
  children: React.ReactNode;
  viewOnlyChildren?: React.ReactNode;
  allowedPermissions?: { [x: string]: string };
  permissionType?: string;
}
const PermissionsGate: React.FC<IPermissionProps> = ({
  children,
  allowedPermissions,
  viewOnlyChildren = <></>,
  permissionType,
}) => {
  const permissionScope = allowedPermissions
    ? getPermissionType(allowedPermissions)
    : permissionType
    ? permissionType
    : SCOPES.None;

  switch (permissionScope) {
    case SCOPES.CanView:
      return <>{viewOnlyChildren}</>;
    case SCOPES.FullAccess:
      return <>{children}</>;
    default:
      return <></>;
  }
};
export default memo(PermissionsGate);

export const getPermissionType = (requiredPermissions: { [x: string]: string }) => {
  const { roles: userRoles } = useAppSelector(selectPing);
  if (!userRoles) return SCOPES.None;
  const rolesList: string[] = userRoles.length > 0 ? userRoles : Object.keys(userRoles);

  const allowedScopes: string[] = [];
  for (const key in requiredPermissions) {
    const userRole = rolesList?.find(role => role === key);
    if (userRole) allowedScopes.push(requiredPermissions[key]);
  }
  if (allowedScopes.length > 0) {
    return allowedScopes.sort()[0];
  }
  return SCOPES.None;
};
