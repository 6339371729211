import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import { DEPARTMENT_FORM_FIELDS, TOAST_SUCCESS } from '../../../constants';
import { ModalContentType } from '../../../features/modal/config';
import { useAppDispatch } from '../../../app/hooks';
import { openModal } from '../../../features/modal/modalSlice';
import { useAddDepartmentMutation, useUpdateDepartmentMutation } from '../../../api/departments';
import Form from '../../../components/forms/Form';

interface IDepartmentForm {
  orgId: string;
  departData?: IDepartment;
  isNew?: boolean;
}

const DepartmentForm: React.FC<IDepartmentForm> = ({ orgId, departData, isNew = true }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [addDepartment, { isLoading: isAdding }] = useAddDepartmentMutation();
  const [updateDepartment, { isLoading: isUpdating }] = useUpdateDepartmentMutation();
  const DEFAULT_DEPARTMENT_NAME = 'Default';

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const isDefault = useMemo<boolean>(
    () => departData?.gbgName === DEFAULT_DEPARTMENT_NAME,
    [departData, departData?.gbgName],
  );

  const fields = useMemo<IFormField[]>(() => {
    const res = _.cloneDeep(DEPARTMENT_FORM_FIELDS);

    if (isNew) return res;

    if (isDefault) {
      res[0].disabled = true;
    }

    return res;
  }, [isNew, departData?.gbgName]);

  const onSubmit = useCallback(
    handleSubmit(async (data: Partial<IDepartment>) => {
      try {
        const editDepartment = isNew ? addDepartment : updateDepartment;
        const res = await editDepartment({
          ...departData,
          ...data,
          gbgCompanyId: orgId,
        } as IDepartment).unwrap();
        GBG.toast(TOAST_SUCCESS);
        history.push(`/organisations/${orgId}/departments/${res.gbgDeptId}`);
      } catch (err) {
        dispatch(openModal({ type: ModalContentType.API_ERROR, data: err }));
      }
    }),
    [handleSubmit, orgId, departData, isNew, addDepartment, updateDepartment],
  );

  return (
    <>
      {isNew && <FormattedMessage id="department.new-title" tagName="h1" />}

      <Form
        {...{
          onSubmit,
          fields,
          data: departData,
          register,
          errors,
          setValue,
          isBusy: isAdding || isUpdating,
          isNew,
          shouldDisplaySaveButton: !isDefault,
        }}
      ></Form>
    </>
  );
};

export default memo(DepartmentForm);
