import GBG from '@gbg/gbgcomponentlibrary_react';

export const TOAST_SUCCESS = {
  title: 'Success!',
  content: 'The content has been updated.',
  duration: 3000,
  position: GBG.ToastPosition.TopRight,
  type: GBG.ToastType.Success,
  dismissOthers: false,
  dismissable: true,
};
