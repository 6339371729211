import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { createApi, BaseQueryFn } from '@reduxjs/toolkit/query/react';
import axios, { AxiosError, Method } from 'axios';

import { clearAuth } from '../features/ping/pingSlice';
import { authorize } from '../features/ping/utils';
import { RootState } from './store';

const client = axios.create({ baseURL: process.env.REACT_APP_BASE_URL ?? '' });

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' },
  ): BaseQueryFn<{ url: string; method: Method; data?: any }, BaseQueryApi, unknown> =>
  async ({ url, method, data }, { dispatch, getState }) => {
    const { accessToken } = (getState() as RootState).ping;
    try {
      if (!accessToken) {
        const result = await client({
          method,
          url: baseUrl + url,
          data,
        });
        return result.data;
      }

      const response = await client({
        method,
        url: baseUrl + url,
        data,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return { data: response.data };
    } catch (axiosError) {
      const error = axiosError as AxiosError;
      if (error.response?.status === 401) {
        dispatch(clearAuth());
        authorize();
      }
      if (error.response?.status === 403) {
        window.location.replace('/error/403');
      }
      if (error.response) {
        const { data }: { data: any } = error.response;
        return { error: { ...data, status: error.response.status } };
      }
      return {
        error: { status: undefined },
      };
    }
  };

export const adminApi = createApi({
  tagTypes: [
    'Organisations',
    'Applications',
    'Departments',
    'Functions',
    'UseCases',
    'DataSources',
    'UseCasesText',
    'Clients',
    'Licences',
    'Users',
    'Departments',
    'Roles',
    'RoleFunctions',
    'DepartmentUseCaseDataSources',
    'CompanyUseCaseDataSources',
    'RoleUseCaseDataSources',
    'UserRoles',
    'AffectedLicences',
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL ?? '',
  }),
  endpoints: () => ({}),
});
