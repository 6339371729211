import GBG from '@gbg/gbgcomponentlibrary_react';
import { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { ModalContentType } from '../../features/modal/config';
import { useAppDispatch } from '../../app/hooks';
import { useGetApplicationQuery } from '../../api/applications';
import { closeModal, openModal } from '../../features/modal/modalSlice';
import useTabBar from '../../hooks/useTabBar';
import Breadcrumb from '../../components/layout/Breadcrumb';
import ApplicationForm from './ApplicationForm';
import FunctionsList from './Functions/FunctionsList';

export const ApplicationPage = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const tabs: string[] = ['label.details', 'function.title'].map(x => intl.formatMessage({ id: x }));
  const { render: renderTabBar, tabIndex } = useTabBar(tabs);
  const { data, isFetching, isError, error } = useGetApplicationQuery(id);

  useEffect(() => {
    if (!isError) {
      dispatch(closeModal());
    } else {
      dispatch(openModal({ type: ModalContentType.API_ERROR, data: error }));
    }
  }, [isError, error]);

  return (
    <>
      {!isFetching && data && <Breadcrumb params={{ [id]: data.gbgName }} />}

      <h1>{data?.gbgName}</h1>

      {renderTabBar()}

      {tabIndex === 0 &&
        (isFetching ? (
          <div className="text-center p-5">
            <GBG.Spinner data-testid="spinner" />
          </div>
        ) : (
          <ApplicationForm appData={data} isNew={false} />
        ))}
      {tabIndex === 1 && <FunctionsList appId={id} />}
    </>
  );
};

export default memo(ApplicationPage);
