import { memo, useCallback } from 'react';
import RemoveModalContent from './RemoveModalContent';
import { useUpdateUserMutation } from '../../../api/users';

const RemoveUserRole = ({ user, roleName }: { user: IUser; roleName: string }) => {
  const [updateUser, { isLoading: isUpdating }] = useUpdateUserMutation();

  const handleRemove = useCallback(async () => {
    const result = await updateUser(user).unwrap();
    return result.id ?? '';
  }, [user]);

  return <RemoveModalContent name={roleName} handleRemove={handleRemove} isDeleting={isUpdating} />;
};

export default memo(RemoveUserRole);
