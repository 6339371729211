import React, { ChangeEvent, memo, useEffect, useState } from 'react';
import classnames from 'classnames';

import GBG from '@gbg/gbgcomponentlibrary_react';
import { ActiveListProps } from '@gbg/gbgcomponentlibrary_react/build/Atoms/Controls/ActiveList/ActiveList.types';
interface IActiveListPropsNew {
  dataProps: ActiveListProps;
  isRefreshing: boolean;
}
export const ActiveListItem: React.FC<IActiveListPropsNew> = ({ isRefreshing, dataProps }) => {
  const {
    items,
    contentRenderer,
    className,
    searchActive,
    searchInactive,
    inactiveTitle,
    activeTitle,
    searchValue,
    onItemMoved,
    sort = () => 1,
    ...props
  } = dataProps;
  const activeListClasses = classnames('active-list', className);
  const [getItems, setItems] = useState<{ active: any[]; inactive: any[] }>(items);
  const [getActiveSelectedItems, setActiveSelectedItems] = useState<any[]>([]);
  const [getInactiveSelectedItems, setInactiveSelectedItems] = useState<any[]>([]);
  const [activeSearchValue, setActiveSearchValue] = useState<string>('');
  const [inactiveSearchValue, setInactiveSearchValue] = useState<string>('');
  if ((searchActive || searchInactive) && !searchValue)
    throw "In order to allow searching a 'searchValue' prop must be provided";

  useEffect(() => {
    if (!isRefreshing) setItems(items);
  }, [items, isRefreshing]);

  return (
    <div {...props} className={activeListClasses}>
      <div className="active-list__list active-list__list--inactive" data-testid="active-list__list--inactive">
        <div className="active-list__header">
          <h3 className="active-list__title">
            {inactiveTitle} (<span className="active-list__count">{getItems.inactive.length}</span>)
          </h3>
          {searchInactive && (
            <GBG.Slug
              className="active-list__search"
              data-testid="active-list-search-input"
              slug={GBG.IconKeys.Search}
              onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                setInactiveSearchValue(evt.currentTarget.value);
              }}
            />
          )}
        </div>

        {isRefreshing ? (
          <GBG.Spinner data-testid="spinner" />
        ) : (
          getItems.inactive
            .filter(i => {
              const sv = searchValue?.(i) ?? '';
              return sv.toLowerCase().includes(inactiveSearchValue.toLowerCase());
            })
            .sort(sort)
            .map((i, index) => {
              return (
                <div className="active-list__item" key={'inactive' + index}>
                  <div className="active-list__item-control">
                    <GBG.Checkbox
                      checked={getInactiveSelectedItems.includes(i)}
                      onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        if (evt.currentTarget.checked) {
                          setInactiveSelectedItems([...getInactiveSelectedItems, i]);
                        } else {
                          setInactiveSelectedItems(getInactiveSelectedItems.filter(s => s != i));
                        }
                      }}
                    />
                  </div>
                  <div className="active-list__item-content">{contentRenderer(i)}</div>
                </div>
              );
            })
        )}
      </div>
      <div className="active-list__controls">
        <GBG.Button
          kind={GBG.ButtonKind.Secondary}
          className="active-list__controls--all-active"
          aria-label="Set all active"
          onClick={() => {
            const movingItems = [...getItems.inactive];
            setItems({
              active: [...getItems.active, ...getItems.inactive],
              inactive: [],
            });
            onItemMoved?.(movingItems, GBG.ActiveListState.Active, getItems);
            setActiveSelectedItems([]);
            setInactiveSelectedItems([]);
          }}
        >
          {'>>'}
        </GBG.Button>
        <GBG.Button
          kind={GBG.ButtonKind.Secondary}
          className="active-list__controls--active"
          aria-label="Set selected active"
          onClick={() => {
            setItems({
              active: [...getInactiveSelectedItems, ...getItems.active],
              inactive: getItems.inactive.filter(a => !getInactiveSelectedItems.includes(a)),
            });
            onItemMoved?.(getInactiveSelectedItems, GBG.ActiveListState.Active, getItems);
            setActiveSelectedItems([]);
            setInactiveSelectedItems([]);
          }}
        >
          {'>'}
        </GBG.Button>
        <GBG.Button
          kind={GBG.ButtonKind.Secondary}
          className="active-list__controls--inactive"
          aria-label="Set selected inactive"
          onClick={() => {
            setItems({
              inactive: [...getActiveSelectedItems, ...getItems.inactive],
              active: getItems.active.filter(a => !getActiveSelectedItems.includes(a)),
            });
            onItemMoved?.(getActiveSelectedItems, GBG.ActiveListState.Inactive, getItems);
            setActiveSelectedItems([]);
            setInactiveSelectedItems([]);
          }}
        >
          {'<'}
        </GBG.Button>
        <GBG.Button
          kind={GBG.ButtonKind.Secondary}
          className="active-list__controls--all-inactive"
          aria-label="Set all inactive"
          onClick={() => {
            const movingItems = [...getItems.active];
            setItems({
              inactive: [...getItems.active, ...getItems.inactive],
              active: [],
            });
            onItemMoved?.(movingItems, GBG.ActiveListState.Inactive, getItems);
            setActiveSelectedItems([]);
            setInactiveSelectedItems([]);
          }}
        >
          {'<<'}
        </GBG.Button>
      </div>
      <div className="active-list__list active-list__list--active" data-testid="active-list__list--active">
        <div className="active-list__header">
          <h3 className="active-list__title">
            {activeTitle} (<span className="active-list__count">{getItems.active.length}</span>)
          </h3>
          {searchActive && (
            <GBG.Slug
              className="active-list__search"
              slug={GBG.IconKeys.Search}
              onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                setActiveSearchValue(evt.currentTarget.value);
              }}
            />
          )}
        </div>
        {isRefreshing ? (
          <GBG.Spinner data-testid="spinner" />
        ) : (
          getItems.active
            .filter(i => {
              const sv = searchValue?.(i) ?? '';
              return sv.toLowerCase().includes(activeSearchValue.toLowerCase());
            })
            .sort(sort)
            .map((i, index) => {
              return (
                <div className="active-list__item" key={'active' + index}>
                  <div className="active-list__item-control">
                    <GBG.Checkbox
                      checked={getActiveSelectedItems.includes(i)}
                      onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                        if (evt.currentTarget.checked) {
                          setActiveSelectedItems([...getActiveSelectedItems, i]);
                        } else {
                          setActiveSelectedItems(getActiveSelectedItems.filter(s => s != i));
                        }
                      }}
                    />
                  </div>
                  <div className="active-list__item-content">{contentRenderer(i)}</div>
                </div>
              );
            })
        )}
      </div>
    </div>
  );
};

export default memo(ActiveListItem);
